import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

import styles from "./Pharmacy.module.scss";
import { routerMap } from "../../utils/router";
import { stateAbbrevs } from "../../DataFiles/states";
import { Alert } from "react-bootstrap";

const MAX_ALLOWED_RESULT = 10;
const UNKNOWN_STATE = "unknown";

const searchPharmacy = async (query) => {
  const cleanedQuery = {
    ...query,
  };
  const token = localStorage.getItem("jwt");
  if (cleanedQuery.state === UNKNOWN_STATE) {
    cleanedQuery.state = undefined;
  }
  Object.keys(cleanedQuery).forEach((k) => {
    const value = cleanedQuery[k];
    // empty string => undefined
    if (!value) {
      cleanedQuery[k] = undefined;
    }
  });
  const res = await routerMap.searchPharmacy(token, cleanedQuery);
  return res;
};

const validateFields = (searchQuery) => {
  // check at least one has values
  return Object.entries(searchQuery).some(([key, value]) => {
    if (key === "state") {
      return value && value !== UNKNOWN_STATE;
    }
    return value?.length > 0;
  });
};

const StateSelect = (props) => {
  return (
    <Form.Select
      value={props.value}
      name={props.name}
      onChange={props.onChange}
    >
      {!props.value && <option value={UNKNOWN_STATE}>Select State</option>}
      <option value={UNKNOWN_STATE}>Unknown</option>
      {stateAbbrevs.map((s) => (
        <option key={s} value={s}>
          {s}
        </option>
      ))}
    </Form.Select>
  );
};

const Pharmacy = (props) => {
  const {
    patientPharmacy,
    isLoadingPharmacy,
    updateVisitPharmacy,
    originalPharmacyId,
    currentPharmacyId,
  } = props;

  const [showEdit, setShowEdit] = useState(false);
  const [selectedPharmacy, setSelectedPharmacy] = useState("");
  const [pharmacyList, setPharmacyList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState({
    name: undefined,
    phone: undefined,
    address: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
  });
  const [isSaving, setIsSaving] = useState(false);

  const [isPharmacyLoading, setIsPharmacyLoading] = useState(false);

  const openModal = () => setShowEdit(true);
  const closeModal = () => {
    setShowEdit(false);
    setSearchQuery({
      name: undefined,
      phone: undefined,
      address: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
    });
    setErrorMessage("");
    setIsPharmacyLoading(false);
    setSelectedPharmacy("");
    setPharmacyList([]);
  };

  const handleSetPharmacy = async () => {
    setIsSaving(true);
    await updateVisitPharmacy(selectedPharmacy);
    setIsSaving(false);
    closeModal();
  };

  const handleResetPharmacy = async () => {
    setIsSaving(true);
    await updateVisitPharmacy(originalPharmacyId);
    setIsSaving(false);
    closeModal();
  };

  const handleInputChange = (e) => {
    setSearchQuery((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handlePharmSelect = (e) => {
    const foundPharm = pharmacyList.find(
      (pharm) =>
        pharm.PharmacyId.toString() === e.currentTarget.value.toString()
    );
    setSelectedPharmacy(foundPharm.PharmacyId);
  };

  const handleSearch = async () => {
    setIsPharmacyLoading(true);
    setErrorMessage("");

    const result = await searchPharmacy(searchQuery);
    if (result.error) {
      setErrorMessage(result.errorMessage);
      setIsPharmacyLoading(false);
      return;
    }

    if (result?.Items?.length) {
      if (result.Items.length > MAX_ALLOWED_RESULT) {
        setErrorMessage(
          "Too many results to display, please add more specificity to narrow down search result."
        );
        setIsPharmacyLoading(false);
        return;
      }
      setPharmacyList(result.Items);
    } else if (result.Result.ResultCode !== "OK") {
      console.error(result);
      setErrorMessage(result.Result.ResultDescription);
    } else if (result?.Items?.length === 0) {
      setErrorMessage("No Pharmacy Found");
    }

    setIsPharmacyLoading(false);
  };

  const formattedPharmacies = pharmacyList.map((pharmacy, idx) => {
    return (
      <div key={`pharmacy-${pharmacy.PharmacyId}`} className="row mb-3">
        <div className="col-1">
          <Form.Check
            type="radio"
            id={`pharmacy-${pharmacy.PharmacyId}`}
            onChange={handlePharmSelect}
            checked={
              selectedPharmacy.toString() === pharmacy.PharmacyId.toString()
            }
            value={pharmacy.PharmacyId}
          />
        </div>
        <div className="col-11">
          <div>
            {pharmacy.PharmacyId} - {pharmacy.StoreName}
          </div>
          <div>{pharmacy.PrimaryPhone}</div>
          <div>
            {pharmacy.Address1} {pharmacy.City}, {pharmacy.State}{" "}
            {pharmacy.ZipCode}
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      <div
        onClick={openModal}
        className={styles.medicineSelectionPharmacyNameHolder}
      >
        <span className={styles.pharmacyNameLabel}>Pharmacy:</span>
        <span className={styles.pharmacyNameText}>
          {isLoadingPharmacy ? "Loading..." : patientPharmacy || "None"}
        </span>
      </div>
      <Modal show={showEdit} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Search for Pharmacy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {originalPharmacyId &&
            originalPharmacyId.toString() !== currentPharmacyId.toString() && (
              <Alert variant="info">
                The pharmacy can be reset back to {originalPharmacyId}{" "}
                <Button variant="warning" onClick={handleResetPharmacy}>
                  {isSaving ? (
                    <Spinner animation="border" size="sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Reset Pharmacy"
                  )}
                </Button>{" "}
              </Alert>
            )}
          <div className="container">
            {[
              {
                label: "Pharmacy Name",
                name: "name",
                placeholder: "Enter a name",
              },
              {
                label: "Phone",
                name: "phone",
              },
              {
                label: "Address",
                name: "address",
              },
              {
                label: "City",
                name: "city",
              },
              {
                label: "State",
                name: "state",
              },
              {
                label: "Zip",
                name: "zip",
                placeholder: "Enter zipcode",
              },
            ].map((inputRow) => {
              const inputProps = {
                type: "text",
                name: inputRow.name,
                placeholder: inputRow.placeholder || `Enter ${inputRow.name}`,
                onChange: handleInputChange,
                id: inputRow.name,
                value: searchQuery[inputRow.name],
              };
              return (
                <div
                  className="d-flex flex-row justify-content-between py-1"
                  key={inputRow.name}
                >
                  <div className="">{inputRow.label}:</div>
                  <div className={styles.pharmacySearchquery}>
                    {inputRow.name === "state" ? (
                      <StateSelect {...inputProps} />
                    ) : (
                      <Form.Control {...inputProps} />
                    )}
                  </div>
                </div>
              );
            })}

            <div className="d-flex flex-row justify-content-end py-2">
              <Button
                disabled={!validateFields(searchQuery)}
                variant="beluga-primary"
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
          </div>

          <div className="container">
            {isPharmacyLoading ? (
              <div className="row p-2">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : errorMessage ? (
              <div className="pharmacyStatusText">{errorMessage}</div>
            ) : (
              <div className="pharmacyListContainer">{formattedPharmacies}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className={styles.setPharmacyButton}
            disabled={!selectedPharmacy}
            variant="primary"
            onClick={handleSetPharmacy}
          >
            {isSaving ? (
              <Spinner animation="border" size="sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Set Pharmacy"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Pharmacy;

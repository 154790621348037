import { useEffect, useState } from "react";
import { routerMap } from "../utils/router";

const { getVisitLogs } = routerMap;

/**
 *
 * @param {string} masterId
 * @returns
 */
const useVisitLogs = (masterId) => {
  const [visitLogs, setVisitLogs] = useState([]);

  useEffect(() => {
    if (masterId) {
      const token = localStorage.getItem("jwt");
      getVisitLogs(token, masterId)
        .then((data) => setVisitLogs(data))
        .catch((error) => console.error(error));
    }
  }, [masterId]);

  return { visitLogs };
};

export default useVisitLogs;

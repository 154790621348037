import React from "react";
import { Table } from "react-bootstrap";
import PropTypes from "prop-types";

const TitleCell = (props) => {
  const { cell, title, overrides } = props;

  if (overrides[cell] !== undefined) {
    if (typeof overrides[cell] === "function") {
      return <th key={cell}>{overrides[cell]()}</th>;
    } else {
      return null;
    }
  }

  return <th key={cell}>{title}</th>;
};
TitleCell.propTypes = {
  cell: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  overrides: PropTypes.object,
};

const RowCell = (props) => {
  const { cell, element, overrides } = props;

  if (overrides[cell] !== undefined) {
    if (typeof overrides[cell] === "function") {
      return <td key={cell}>{overrides[cell](element[cell], element)}</td>;
    } else {
      return null;
    }
  }

  return <td key={cell}>{element[cell]}</td>;
};
RowCell.propTypes = {
  cell: PropTypes.string.isRequired,
  element: PropTypes.object.isRequired,
  overrides: PropTypes.object,
};

const FavoriteTable = (props) => {
  const {
    list = [],
    getRowKey,
    tableKeyTitles = {},
    titleOverrides = {},
    rowOverrides = {},
  } = props;

  const tableKeys = Object.keys(tableKeyTitles);

  return (
    <Table responsive hover striped>
      <thead>
        <tr>
          {Object.entries(tableKeyTitles).map(([cell, title], i) => (
            <TitleCell
              key={i}
              cell={cell}
              title={title}
              overrides={titleOverrides}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {list.map((element) => {
          // TODO add virtualization or pagination
          return (
            <tr key={getRowKey(element)}>
              {tableKeys.map((cell, i) => (
                <RowCell
                  key={i}
                  cell={cell}
                  element={element}
                  overrides={rowOverrides}
                />
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
FavoriteTable.propTypes = {
  list: PropTypes.array.isRequired,
  getRowKey: PropTypes.func.isRequired,
  tableKeyTitles: PropTypes.object.isRequired,
  titleOverrides: PropTypes.object,
  rowOverrides: PropTypes.object,
};

export default FavoriteTable;

const countResponse = {
  status: 200,
  data: {
    notifications: {
      RefillRequestsCount: 5,
      TransactionErrorsCount: 24,
      PendingPrescriptionsCount: 38,
      PendingRxChangeCount: 13,
      Result: {
        ResultCode: "OK",
        ResultDescription: "",
      },
    },
  },
};

export default countResponse;

// export const rxTextFromDS = (
//   rxText,
//   firstName,
//   medication,
//   Quantity,
//   Refills,
//   Unit,
//   Directions,
//   pharmacy,
//   medList
// ) => {
//   const visitText = rxText
//     .replace(/\\n/g, "\n")
//     .replace(/{firstName}/g, firstName.replace(/[ ]+$/, ""))
//     .replace(/{medication}/g, medication)
//     .replace(/{Quantity}/g, Quantity)
//     .replace(/{Refills}/g, Refills)
//     .replace(/{Unit}/g, Unit)
//     .replace(/{Directions}/g, Directions)
//     .replace(/{pharmacy}/g, pharmacy)
//     .replace(/{medList}/g, medList);
//   return visitText;
// };

export const rxTextFromPDF = (rxText, firstName, email) => {
  const visitText = rxText
    .replace(/\\n/g, "\n")
    .replace(/{firstName}/g, firstName.replace(/[ ]+$/, ""))
    .replace(/{email}/g, email);
  return visitText;
};

export const referText = `Hi {firstName},
You should follow up and be examined by a doctor in-person before starting prescription treatment due to {REFERRAL_REASON}.
You may need certain types of physical exam or treatments that we can't provide you online currently.
This is not to say that you should not ever be treated for this condition;
it means to say that we are not the ones who should be treating you.

If you have any questions, please let us know.
We're sorry that we weren't able to help you today.

If you've received this medicine in the past from another provider, I would recommend going back to that provider for continued care.
Don't have a doctor? If you don't have insurance you can find a low cost Federal Qualified Health Center at findahealthcenter.hrsa.gov`;

export const fullReferText = (firstName) => {
  return referText.replace(/{firstName}/g, firstName);
};

const rejectPhotoText = `*action required - Government ID rejected*
Hi {firstName} - please retake this picture or upload a new photo by responding to this message.
We need a clear picture of a government-issued ID that includes your picture, such as a Driver’s License or passport.
We need this for verifying your identity (name, age/date of birth, gender, and picture).
Please don’t submit pictures that have filters (Snapchat, or iPhone portrait mode, etc) applied to them.
Don’t submit pictures of your ID captured with a scanning device, or upload anything in black and white.
Please use appropriate lighting as we cannot properly evaluate you if the pictures are too dark.
Thank you so much and we look forward to hearing back.`;

export const fullRejectPhotoText = (firstName) => {
  return rejectPhotoText.replace(/{firstName}/g, firstName);
};

export const pdfRxText = `Patient Name:
{firstName} {lastName}

Patient Date of Birth:
{dob}

Patient Phone Number:
{phone}

Patient Address:
{address}

Medications:
{medication}
Directions: {Directions}
`;

export const fullPdfRxText = (
  firstName,
  lastName,
  dob,
  phone,
  address,
  city,
  state,
  zip,
  medication,
  Directions
) => {
  return pdfRxText
    .replace(/{firstName}/g, firstName)
    .replace(/{lastName}/g, lastName)
    .replace(/{dob}/g, dob)
    .replace(/{phone}/g, phone)
    .replace(/{address}/g, `${address} ${city}, ${state} ${zip}`)
    .replace(/{medication}/g, medication)
    .replace(/{Directions}/g, Directions);
};

export const pdfDocText = `
{doctorName}  NPI: {npi}  Phone: {phone}  Fax: {docFax}  Address: {address}
`;

export const fullPdfDocText = (
  firstName,
  lastName,
  npi,
  phone,
  fax,
  address,
  city,
  state,
  zip
) => {
  return pdfDocText
    .replace(/{doctorName}/g, `${firstName} ${lastName}`)
    .replace(/{npi}/g, npi)
    .replace(/{phone}/g, phone)
    .replace(/{fax}/g, fax)
    .replace(/{address}/g, `${address} ${city}, ${state} ${zip}`);
};

import { rest } from "msw";
import config from "../config";
import countResponse from "./data/dosespot/getCountData";
import detailsResponse from "./data/dosespot/getDetailsData";
import * as rxActionsData from "./data/dosespot/rxActionsData";
import addPrescriptionHandlers from "./addPrescriptionHandlers";
import { getAllMedsData } from "./data/dosespot/getAllMedsData";

const stagingPath = config.apiGateway.URL;
console.log("Staging Path", stagingPath);
export const handlers = [
  ...addPrescriptionHandlers,
  rest.get(`${stagingPath}/dosespot/notifications/count`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(300), ctx.json(countResponse));
  }),

  rest.get(`${stagingPath}/dosespot/notifications/details`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(detailsResponse));
  }),

  rest.post(`${stagingPath}/dosespot/action`, async (req, res, ctx) => {
    const body = await req.json();
    console.log("received", body);
    const actionType = body.type;
    const action = body.responseAction;
    const rxChangeType = body.rxChangeType;
    if (
      actionType === "rxChange" &&
      action === "approve" &&
      rxChangeType === "Generic Substitution"
    ) {
      return res(ctx.status(200), ctx.json(rxActionsData.failActionResponse));
    }
    return res(
      ctx.status(200),
      ctx.json(rxActionsData.successfulActionResponse)
    );
  }),

  rest.post(
    `${stagingPath}/dosespot/allmedications/*`,
    async (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(getAllMedsData));
    }
  ),
];

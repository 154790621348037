import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button } from "react-bootstrap";

import "./MedicineBox.css";
import NumberInput from "../NumberInput";
import DownArrowIcon from "../Icons/IconBxsDownArrow";
import UpArrowIcon from "../Icons/IconBxsUpArrow";

const LabelValueDisplayRow = (props) => {
  const { label, value, longValue } = props;

  const longValueClass = classNames("longValue", {
    prescribedDetailValue: true,
    longValue: longValue,
  });
  return (
    <div className="prescribedDetails">
      <div className="prescribedDetailLabel">{label}</div>
      <div className={longValueClass}>{value}</div>
    </div>
  );
};

const MedicineBox = (props) => {
  const [pendingMed, setPendingMed] = useState({ ...props.medicine });
  const [isEditMode, setIsEditMode] = useState(false);
  const {
    medicine,
    uniqueId,
    label,
    hasError,
    disabled,
    // handleMedicineValueChange,
    onClick,
    selectable,
    setUpdatedMed,
    resetMed,
  } = props;

  const [expanded, setExpandedState] = useState(false);

  const changeExpandStatus = (e) => {
    e.stopPropagation();
    if (isEditMode) return;
    setExpandedState(!expanded);
  };

  const { dispense, selected } = medicine;
  const isMedType = medicine.type === "med";
  const isCompoundType = medicine.type === "compound";

  const editableMedFields = isEditMode && !isCompoundType;

  const boxClassNames = classNames("addedMedicationBox", {
    error: !disabled && hasError,
    selected: !disabled && medicine.selected,
    disabled: disabled,
    selectable: selectable && !isEditMode,
  });

  const medicineLabel = medicine?.dispense
    ? label + " (" + dispense + ") "
    : label;

  const medicationBoxHeaderClassNames = classNames("medicationBoxHeader", {
    expanded: expanded,
  });

  const handleNumberChange = (evt) => {
    const name = evt.name;
    const value = evt.value;
    setPendingMed((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChange = (evt) => {
    const name = evt.target.name;
    let value = evt.target.value;
    if (name === "allowSubstitution") {
      value = evt.target.checked;
    }

    setPendingMed((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  return (
    <div key={uniqueId} className={boxClassNames}>
      <div
        className={medicationBoxHeaderClassNames}
        onClick={(e) => {
          // select
          if (disabled) return;
          if (isEditMode) return;
          if (onClick) {
            if (medicine.original) {
              setIsEditMode(false);
              setPendingMed({ ...medicine.original });
            }
            onClick(e);
          }
        }}
      >
        <div className="medicationText">{medicineLabel}</div>
        {!isEditMode && (
          <div className="expandMedBoxButton" onClick={changeExpandStatus}>
            {/* <div className="text">View more</div> */}
            {expanded ? <UpArrowIcon /> : <DownArrowIcon />}
          </div>
        )}
      </div>

      {expanded && (
        <div className="prescribedInfo">
          <LabelValueDisplayRow label="Medication:" value={medicine.name} />
          <LabelValueDisplayRow label="Type:" value={medicine.type} />
          <LabelValueDisplayRow label="Strength:" value={medicine.strength} />
          <LabelValueDisplayRow label="Unit:" value={medicine.unit} />
          <LabelValueDisplayRow
            label="Quantity:"
            value={
              editableMedFields ? (
                <NumberInput
                  variant="noLabel"
                  containerClassName="med-box-input-holder"
                  validateConfig={{
                    type: "float",
                    min: 0,
                  }}
                  label="Quantity:"
                  name="quantity"
                  onChange={handleNumberChange}
                  placeholder="Quantity"
                  className="quantity"
                  value={pendingMed.quantity}
                />
              ) : (
                medicine.quantity
              )
            }
          />
          <LabelValueDisplayRow
            label="Refills:"
            value={
              editableMedFields ? (
                <NumberInput
                  variant="noLabel"
                  containerClassName="med-box-input-holder"
                  validateConfig={{
                    max: 12,
                    min: 0,
                  }}
                  label="Refills:"
                  name="refills"
                  onChange={handleNumberChange}
                  placeholder="Refills"
                  className="refills"
                  min="0"
                  max="12"
                  value={pendingMed?.refills?.toString()}
                />
              ) : (
                medicine.refills
              )
            }
          />
          <LabelValueDisplayRow
            label="Days Supply:"
            value={
              editableMedFields ? (
                <NumberInput
                  variant="noLabel"
                  containerClassName="med-box-input-holder"
                  validateConfig={{
                    max: 366,
                    min: 0,
                  }}
                  label="Days Supply:"
                  name="daysSupply"
                  onChange={handleNumberChange}
                  placeholder="Days Supply"
                  className="daysSuppy"
                  value={pendingMed.daysSupply}
                />
              ) : (
                medicine.daysSupply
              )
            }
          />
          <LabelValueDisplayRow
            label="Substitution:"
            value={
              editableMedFields ? (
                <div className="med-box-input-holder">
                  <input
                    type="checkbox"
                    name="allowSubstitution"
                    onChange={handleChange}
                    className="med-box-allow-sub"
                    checked={pendingMed.allowSubstitution}
                  />
                </div>
              ) : medicine.allowSubstitution ? (
                "Allowed"
              ) : (
                "Not Allowed"
              )
            }
          />
          {medicine.pharmacyDetails && (
            <LabelValueDisplayRow
              label="Pharmacy:"
              value={medicine.pharmacyDetails.StoreName}
            />
          )}
          {medicine.directions?.length !== 0 && (
            <LabelValueDisplayRow
              label="Directions:"
              value={
                editableMedFields ? (
                  <div className="med-box-input-holder">
                    <textarea
                      name="directions"
                      onChange={handleChange}
                      placeholder="Input directions for patient here"
                      className="directions"
                      type="text"
                      value={pendingMed.directions}
                    />
                  </div>
                ) : (
                  medicine.directions
                )
              }
              longValue
            />
          )}
          {isEditMode && (
            <LabelValueDisplayRow
              label="Pharmacy Notes:"
              value={
                <div className="med-box-input-holder">
                  <textarea
                    name="pharmacyNotes"
                    onChange={handleChange}
                    placeholder="Input notes for patient here"
                    className="pharmacy-note"
                    type="text"
                    value={pendingMed.pharmacyNotes}
                  />
                </div>
              }
              longValue
            />
          )}
          {!isEditMode && medicine.pharmacyNotes?.length !== 0 && (
            <LabelValueDisplayRow
              label="Pharmacy Notes:"
              value={medicine.pharmacyNotes}
              longValue
            />
          )}
          {(isMedType || isCompoundType) && selected && (
            <div className="d-flex justify-content-between">
              {isEditMode && (
                <>
                  <Button
                    className="me-1"
                    variant="primary"
                    onClick={() => {
                      setUpdatedMed(pendingMed);
                      setIsEditMode(false);
                    }}
                  >
                    Confirm
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setIsEditMode(false);
                      setPendingMed({ ...props.medicine });
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
              {!isEditMode && (
                <>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setIsEditMode(!isEditMode);
                    }}
                  >
                    Edit
                  </Button>
                  {medicine.original && (
                    <Button
                      variant="danger"
                      onClick={() => {
                        resetMed(medicine.original);
                        setPendingMed({ ...medicine.original });
                      }}
                    >
                      Reset
                    </Button>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

MedicineBox.propTypes = {
  key: PropTypes.string,
  id: PropTypes.string,
  medicine: PropTypes.object,
  label: PropTypes.string,
  hasError: PropTypes.bool,
  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
  strengthOptions: PropTypes.array,
  handleMedicineValueChange: PropTypes.func,
  onClick: PropTypes.func,
  selectable: PropTypes.bool,
};

export default MedicineBox;

import React from "react";
import PropTypes from "prop-types";

const formattedDate = (date) => {
  if (date) {
    const newFormat = new Date(date).toLocaleString("en-us", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return newFormat;
  }
  return "";
};

const PrescriptionStatusMap = {
  1: "Entered",
  2: "Printed",
  3: "Sending",
  4: "eRxSent",
  6: "Error",
  7: "Deleted",
  8: "Requested",
  9: "Edited",
  10: "EpcsError",
  11: "EpcsSigned",
  12: "Ready To Sign",
  13: "Pharmacy Verified",
};

const MedicationStatusMap = {
  1: "Active",
  3: "Discontinued",
  4: "Deleted",
  5: "Completed",
  6: "CancelRequested",
  7: "CancelPending",
  8: "Cancelled",
  9: "CancelDenied",
  10: "Changed",
  11: "FullFill",
  12: "PartialFill",
  13: "No Fill",
};

const MedicationInfoDisplay = (props) => {
  const {
    DisplayName,
    WrittenDate,
    Directions,
    DaysSupply,
    LastFillDate,
    PharmacyNotes,
    NoSubstitutions,
    DispenseUnitDescription,
    IsUrgent,
    Quantity,
    Refills,
    Strength,
    EffectiveDate,
    //Prescription Status Type
    Status,
    MedicationStatus,
  } = props.data;

  const formattedWrittenDate = formattedDate(WrittenDate);
  const formattedEffectiveDate = formattedDate(EffectiveDate);
  const formattedLastFillDate = formattedDate(LastFillDate);

  return (
    <div className="notifications-more-info-container">
      <div className="title">{props.title}</div>

      <div className="notifications-text-row">
        <div className="bolded-text">Medication:</div>
        <div className="normal-text"> {DisplayName}</div>
      </div>
      <div className="notifications-text-row">
        <div className="bolded-text">Strength:</div>
        <div className="normal-text"> {Strength}</div>
      </div>
      <div className="notifications-text-row">
        <div className="bolded-text">Days Supply:</div>
        <div className="normal-text"> {DaysSupply}</div>
      </div>
      <div className="notifications-text-row">
        <div className="bolded-text">Quantity:</div>
        <div className="normal-text"> {Quantity}</div>
      </div>
      <div className="notifications-text-row">
        <div className="bolded-text">Refills:</div>
        <div className="normal-text"> {Refills}</div>
      </div>
      <div className="notifications-text-row">
        <div className="bolded-text">Dispense Unit:</div>
        <div className="normal-text"> {DispenseUnitDescription}</div>
      </div>
      <div className="notifications-text-row">
        <div className="bolded-text">Written Date:</div>
        <div className="normal-text"> {formattedWrittenDate}</div>
      </div>
      <div className="notifications-text-row">
        <div className="bolded-text">Effective Date:</div>
        <div className="normal-text">{formattedEffectiveDate}</div>
      </div>
      <div className="notifications-text-row">
        <div className="bolded-text">Last Filled:</div>
        <div className="normal-text"> {formattedLastFillDate}</div>
      </div>
      <div className="notifications-text-row">
        <div className="bolded-text">Allow Substitution:</div>
        <div className="normal-text"> {NoSubstitutions ? "No" : "Yes"}</div>
      </div>
      <div className="notifications-text-row">
        <div className="bolded-text">Directions:</div>
        <div className="normal-text"> {Directions}</div>
      </div>
      <div className="notifications-text-row">
        <div className="bolded-text">Status:</div>
        <div className="normal-text">
          {" "}
          {PrescriptionStatusMap[Status] || "Unknown"}
        </div>
      </div>
      <div className="notifications-text-row">
        <div className="bolded-text">Is Urgent:</div>
        <div className="normal-text"> {IsUrgent ? "Yes" : "No"}</div>
      </div>
      <div className="notifications-text-row">
        <div className="bolded-text">Pharmacy Notes:</div>
        <div className="normal-text"> {PharmacyNotes}</div>
      </div>
      <div className="notifications-text-row">
        <div className="bolded-text">Medication Status:</div>
        <div className="normal-text">
          {" "}
          {MedicationStatusMap[MedicationStatus] || "Unknown"}
        </div>
      </div>
    </div>
  );
};

MedicationInfoDisplay.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
};

MedicationInfoDisplay.defaultProps = {
  title: "",
};

export default MedicationInfoDisplay;

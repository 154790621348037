const { ValidationSchema } = require("../utils/validationSchema");
const favoriteSchema = {
  favoriteName: { type: "string", required: true },
  clientName: { type: "string", required: true },
  baskName: { type: "string" },
  medication: { type: "string", required: true },
  type: { values: ["med", "compound", "supply"], required: true },
  strength: {
    type: "string",
    test: ({ strength, type }) => !(type === "med" && !strength),
    errorMessage: "Strength is required for medication type",
  },
  sig: { type: "string", required: true },
  quantity: { type: "number", required: true },
  dispenseUnit: { type: "string", required: true },
  refills: { type: "number", required: true },
  daysSupply: { type: "number", required: true },
  pharmacyNotes: { type: "string" },
  allowSubstitutions: {
    values: ["true", "false", true, false],
    required: true,
  },
  visitType: { type: "string", required: false },
  pharmacyId: { type: "string" },
  supplyId: { type: "number" },
  category: { type: "string" },
  company: {},
};

const favoriteValidator = new ValidationSchema(favoriteSchema);

module.exports = { favoriteSchema, favoriteValidator };

import * as React from "react";

function IconBxsUpArrow(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      transform="scale(-1)"
      transform-origin="center"
      {...props}
    >
      <path d="M11.178 19.569a.998.998 0 001.644 0l9-13A.999.999 0 0021 5H3a1.002 1.002 0 00-.822 1.569l9 13z" />
    </svg>
  );
}

export default IconBxsUpArrow;

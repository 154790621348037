import React, { useState } from "react";
import PropTypes from "prop-types";

import styles from "./NumberInput.module.css";

const formatsToFloat = (value) => {
  let currentValue = parseFloat(value);
  if (Number.isNaN(currentValue)) {
    currentValue = null;
  } else if (!Number.isInteger(currentValue)) {
    currentValue = currentValue.toFixed(3);
  }
  return currentValue?.toString();
};

const replaceNonDigits = (value) => {
  if (!value) {
    return value;
  }
  return value.replaceAll(/[\D]/g, "");
};

const NumberInput = (props) => {
  const { validateConfig, containerClassName, variant, ...rest } = props;
  const { name, onChange } = rest;
  const [value, setValue] = useState(props.value || "");

  const onInputChange = (evt) => {
    let currentValue = evt.target.value || "";
    try {
      // do not allow non-digits, but allow . for floats
      if (currentValue.includes(".")) {
        let [wholePart, ...fractionalPart] = currentValue.split(".");
        // incomplete number, leave it alone
        if (wholePart && fractionalPart) {
          wholePart = replaceNonDigits(wholePart);
          // more than one .
          if (fractionalPart.length > 2) {
            fractionalPart = fractionalPart.reduce((previous, current) => {
              return previous + replaceNonDigits(current);
            }, "");
          } else {
            fractionalPart = replaceNonDigits(fractionalPart[0]);
          }
          currentValue = [wholePart, fractionalPart].join(".");
        }
      } else {
        currentValue = replaceNonDigits(currentValue);
      }
    } catch (error) {
      console.error(error);
    }
    onChange({ name, value: currentValue.toString() });
    setValue(currentValue.toString());
  };

  const ensureValidNumber = (evt) => {
    let currentValue = evt.target.value || "";
    if (validateConfig?.type === "float") {
      currentValue = formatsToFloat(currentValue) || "";
    } else {
      // has only digits
      // replace all non-digits
      currentValue = currentValue.replaceAll(/[\D]/g, "");
    }

    if (validateConfig?.min) {
      currentValue = Math.max(validateConfig?.min, currentValue);
    }
    if (validateConfig?.max) {
      currentValue = Math.min(validateConfig?.max, currentValue);
    }

    onChange({ name, value: currentValue.toString() });
    setValue(currentValue.toString());
  };

  const inputProps = {
    ...rest,
    value,
    onChange: onInputChange,
    onBlur: ensureValidNumber,
  };

  return variant === "noLabel" ? (
    <div className={containerClassName}>
      <input {...inputProps} />
    </div>
  ) : (
    <div className={containerClassName}>
      <label className={styles.input_section_label}>{props.label}</label>
      <input {...inputProps} />
    </div>
  );
};

NumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default NumberInput;

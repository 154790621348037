import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { routerMap } from "../../utils/router";
import MedicationInfoDisplay from "../MedicationInfoDisplay";

const getMedicationList = async (user, patientId, visitId) => {
  const token = localStorage.getItem("jwt");
  const res = await routerMap.listPatientMedications(
    token,
    user,
    patientId,
    visitId
  );
  return res;
};

const sendRxCancel = async (patientId, dosespotPatientId, rxId) => {
  const token = localStorage.getItem("jwt");
  const res = await routerMap.sendRxCancelAction(
    token,
    patientId,
    dosespotPatientId,
    rxId
  );
  return res;
};

const PatientMedicationList = (props) => {
  const { patientId, visitId, user } = props;

  const [medicationList, setMedicationList] = useState([]);
  const [pharmacyData, setPharmacyData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [patientDosespotId, setPatientDosespotId] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    async function getRecentMedicationList() {
      try {
        setIsLoading(true);
        const res = await getMedicationList(user, patientId, visitId);
        const list = res?.replacedMedList?.filter(
          (med) => med.MedicationStatus !== 4
        );
        if (list) {
          setPatientDosespotId(res.patient.dosespotID);
          setMedicationList(list);
          setPharmacyData(res.pharmacyIdsData);
        }
        setIsLoading(false);
        if (res?.Result?.ResultCode === "ERROR") {
          setErrorMsg(res.Result.ResultDescription);
          setMedicationList([]);
          setIsLoading(false);
          return;
        }
      } catch (err) {
        setMedicationList([]);
        setErrorMsg(err);
        setIsLoading(false);
      }
    }

    getRecentMedicationList();
  }, [patientId, visitId]);

  const handleCancel = async (med) => {
    setErrorMsg("");
    try {
      const res = await sendRxCancel(
        patientId,
        patientDosespotId,
        med.PrescriptionId
      );

      if (res?.Result?.ResultCode === "ERROR") {
        setErrorMsg(res.Result.ResultDescription);
        return;
      }

      setSuccessMsg(`Cancelled ${med.PrescriptionId}: ${med.DisplayName}`);
    } catch (err) {
      setErrorMsg(err);
    }
  };

  const getPharmacyName = (pharmacyId) => {
    return pharmacyData?.[pharmacyId]?.StoreName || "Unknown";
  };

  return (
    <>
      <div className="row">
        {errorMsg && (
          <div className="alert alert-danger" role="alert">
            {errorMsg}
          </div>
        )}
      </div>
      <div className="row">
        {successMsg && (
          <div className="alert alert-success" role="alert">
            {successMsg}
          </div>
        )}
      </div>
      {isLoading && (
        <div className="row p-2">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {medicationList.map((med) => {
        return (
          <div className="row border">
            <div className="col-9">
              <MedicationInfoDisplay data={med} />
              <div className="notifications-text-row">
                <div className="bolded-text">Pharmacy:</div>
                <div className="normal-text">
                  {getPharmacyName(med.PharmacyId)}
                </div>
              </div>
            </div>
            <div className="col-3">
              <button
                type="button"
                disabled={!!successMsg}
                className="btn btn-warning"
                onClick={() => handleCancel(med)}
              >
                Cancel
              </button>
            </div>
          </div>
        );
      })}
    </>
  );
};

PatientMedicationList.propTypes = {
  patientId: PropTypes.string.isRequired,
  visitId: PropTypes.string.isRequired,
};

export default PatientMedicationList;

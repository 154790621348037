import React, { useState } from "react";
import { API } from "aws-amplify";

import { states, stateAbbrevs } from "../DataFiles/states";
import "./MyAccount.css";

const NewUserForm = (props) => {
  const { companies, closeModal } = props;
  const [formData, setFormData] = useState({
    admin: false,
    surge: false,
    youthFilter: false,
  });
  const [message, setMessage] = useState("");

  const token = localStorage.getItem("jwt");

  const handleChange = (e) => {
    const { value, name, type } = e.target;
    if (type === "checkbox") {
      if (name === "stateLicenses") {
        const ogLicenses = formData.stateLicenses;
        const formDataIndex = ogLicenses && ogLicenses.indexOf(value);
        let stateLicenses;
        if (ogLicenses && ogLicenses.includes(value)) {
          ogLicenses.splice(formDataIndex, 1);
          stateLicenses = ogLicenses;
        } else {
          stateLicenses = ogLicenses ? [...ogLicenses, value] : [value];
        }
        setFormData({
          ...formData,
          stateLicenses,
        });
      } else if (name === "acceptableVisitTypes") {
        const ogVisitTypes = formData.acceptableVisitTypes;
        const formDataIndex = ogVisitTypes && ogVisitTypes.indexOf(value);
        let acceptableVisitTypes;
        if (ogVisitTypes && ogVisitTypes.includes(value)) {
          ogVisitTypes.splice(formDataIndex, 1);
          acceptableVisitTypes = ogVisitTypes;
        } else {
          acceptableVisitTypes = ogVisitTypes
            ? [...ogVisitTypes, value]
            : [value];
        }
        setFormData({
          ...formData,
          acceptableVisitTypes,
        });
      }
    } else if (name === "companies") {
      const ogCompanies = formData.companies;
      const formDataIndex = ogCompanies && ogCompanies.indexOf(value);
      let docCompanies;
      if (ogCompanies && ogCompanies.includes(value)) {
        ogCompanies.splice(formDataIndex, 1);
        docCompanies = ogCompanies;
      } else {
        docCompanies = ogCompanies ? [...ogCompanies, value] : [value];
      }
      setFormData({
        ...formData,
        companies: docCompanies,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const closeParentModal = () => {
    closeModal();
  };

  const isReadyToSubmit =
    formData.firstName &&
    formData.lastName &&
    formData.password &&
    formData.email &&
    formData.phone &&
    formData.dob &&
    formData.stateLicenses &&
    (formData.youthFilter === true || formData.youthFilter === false) &&
    (formData.admin === true || formData.admin === false) &&
    (formData.surge === true || formData.surge === false);

  const createUser = async (e) => {
    e.preventDefault();
    try {
      if (isReadyToSubmit) {
        const response = await API.post("brains", "/doc/", {
          credentials: "include",
          body: formData,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status !== 200) {
          console.error(response);
          throw Error(response.statusText);
        }
        closeParentModal();
      } else {
        setMessage("Please make sure every field is filled in correctly");
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const statesDisplay = formData.stateLicenses
    ? formData.stateLicenses
        .map((stateAbbrev) => states[stateAbbrevs.indexOf(stateAbbrev)])
        .sort()
        .map((stateName, idx) => {
          return <span key={`${stateName}${idx}`}>| {stateName} |</span>;
        })
    : "";
  const stateList = states.map((state) => {
    return (
      <div className="checkGroup" key={state}>
        <input
          type="checkbox"
          value={stateAbbrevs[states.indexOf(state)]}
          name="stateLicenses"
          onChange={handleChange}
          className="checkBox"
        />
        <span>{state}</span>
      </div>
    );
  });

  const visitTypes = [
    ...new Set(
      companies
        .map((company) => company.visitTypes.map((visitType) => visitType.type))
        .flat()
    ),
  ].sort();

  const checkedVisitTypes = visitTypes.map((visitType) => {
    return (
      <div className="checkGroup" key={visitType}>
        <input
          type="checkbox"
          value={visitType}
          name="acceptableVisitTypes"
          onChange={handleChange}
          className="checkBox"
        />
        <span>{visitType}</span>
      </div>
    );
  });

  const checkedCompanies = companies
    .map((company) => company.name)
    .sort()
    .map((companyName) => {
      return (
        <div className="checkGroup" key={companyName}>
          <input
            type="checkbox"
            value={companyName}
            name="companies"
            onChange={handleChange}
            className="checkBox"
          />
          <span>{companyName}</span>
        </div>
      );
    });

  const handleRadioChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: JSON.parse(value),
    });
  };

  return (
    <>
      <span className="userFormError">{message}</span>
      <form onSubmit={createUser} className="formStyle">
        <div className="formRow">
          <div>
            <div className="formField">First Name</div>
            <input
              name="firstName"
              placeholder="First Name"
              onChange={handleChange}
              className="formInput"
            />
          </div>
          <div>
            <div className="formField">Last Name</div>
            <input
              name="lastName"
              placeholder="Last Name"
              onChange={handleChange}
              className="formInput"
            />
          </div>
        </div>
        <div className="formRow">
          <div>
            <div className="formField">Email</div>
            <input
              name="email"
              placeholder="Enter doctor's email"
              onChange={handleChange}
              className="formInput"
            />
          </div>
          <div>
            <div className="formField">Phone</div>
            <input
              name="phone"
              placeholder="Phone"
              onChange={handleChange}
              className="formInput"
            />
          </div>
        </div>
        <div className="formRow">
          <div>
            <div className="formField">Date of Birth</div>
            <input
              name="dob"
              placeholder="Enter doctor's date of birth"
              onChange={handleChange}
              className="formInput"
            />
          </div>
          <div>
            <div className="formField">Password</div>
            <input
              name="password"
              onChange={handleChange}
              placeholder="Password"
              className="formInput"
            />
          </div>
        </div>
        <div className="formRow">
          <div>
            <div className="formField">Dosespot ID</div>
            <input
              name="dosespotID"
              placeholder="Enter doctor's Dosespot ID"
              onChange={handleChange}
              className="formInput"
            />
          </div>
          <div>
            <div className="formField">NPI #</div>
            <input
              name="npi"
              onChange={handleChange}
              placeholder="Enter doctor's NPI #"
              className="formInput"
            />
          </div>
        </div>
        <div className="formRow">
          <div>
            <div className="formField">DEA #</div>
            <input
              name="dea"
              placeholder="Enter doctor's DEA #"
              onChange={handleChange}
              className="formInput"
            />
          </div>
        </div>
        <div className="formRowLeft">
          <div>
            <div className="formField">Companies</div>
            <div className="scrolling checkedStates">{checkedCompanies}</div>
          </div>
        </div>
        <div className="formRowLeft">
          <div>
            <div className="formField">Visit Types</div>
            <div className="scrolling checkedStates">
              <div className="checkGroup" key="None">
                <input
                  type="checkbox"
                  value="None"
                  name="acceptableVisitTypes"
                  onChange={handleChange}
                  className="checkBox"
                />
                <span>None</span>
              </div>
              {checkedVisitTypes}
            </div>
          </div>
        </div>
        <div className="formRowLeft">
          <div>
            <div className="formField">State/Province Licenses</div>
            <p>Selected: {statesDisplay}</p>
            <div className="scrolling checkedStates">
              <div className="checkGroup" key="None">
                <input
                  type="checkbox"
                  value="None"
                  name="stateLicenses"
                  onChange={handleChange}
                  className="checkBox"
                />
                <span>None</span>
              </div>
              {stateList}
            </div>
          </div>
        </div>
        <div className="formRowLeft">
          <div className="formGroup">
            <div className="formField">Admin?</div>
            <div className="radioGroup">
              <label>
                <input
                  type="radio"
                  name="admin"
                  value="true"
                  checked={formData.admin}
                  onChange={handleRadioChange}
                />
                True
              </label>
              <label>
                <input
                  type="radio"
                  name="admin"
                  value="false"
                  checked={!formData.admin}
                  onChange={handleRadioChange}
                />
                False
              </label>
            </div>
          </div>
          <div className="formGroup">
            <div className="formField">Only show patients over 18?</div>
            <div className="radioGroup">
              <label>
                <input
                  type="radio"
                  name="youthFilter"
                  value="true"
                  checked={formData.youthFilter}
                  onChange={handleRadioChange}
                />
                True
              </label>
              <label>
                <input
                  type="radio"
                  name="youthFilter"
                  value="false"
                  checked={!formData.youthFilter}
                  onChange={handleRadioChange}
                />
                False
              </label>
            </div>
          </div>
        </div>
        <div className="formRowLeft">
          <div className="formGroup">
            <div className="formField">Surge</div>
            <div className="radioGroup">
              <label>
                <input
                  type="radio"
                  name="surge"
                  value="true"
                  checked={formData.surge}
                  onChange={handleRadioChange}
                />
                True
              </label>
              <label>
                <input
                  type="radio"
                  name="surge"
                  value="false"
                  checked={!formData.surge}
                  onChange={handleRadioChange}
                />
                False
              </label>
            </div>
          </div>
        </div>
        <div className="formRowRight">
          <button
            type="submit"
            className="formButton"
            disabled={!isReadyToSubmit}
          >
            Create User
          </button>
        </div>
      </form>
    </>
  );
};

export default NewUserForm;

import React, { useMemo, useState } from "react";
import { favoriteValidator } from "../../schemas/favorite";
import FormInputs from "../../Components/FormInputs";
import usePharmacyNames from "../../hooks/usePharmacyNames";
import PropTypes from "prop-types";

import "../../MyAccount/MyAccount.css";

const FavoriteForm = (props) => {
  const {
    mode,
    defaultFormData = {},
    compareTo,
    favorites = [],
    visitTypes,
    companies,
    onSubmit,
    onReject,
    errorMessage,
  } = props;

  defaultFormData.quantity = defaultFormData.quantity ?? 0;
  defaultFormData.refills = defaultFormData.refills ?? 0;
  defaultFormData.daysSupply = defaultFormData.daysSupply ?? 0;
  defaultFormData.company = defaultFormData.company ?? [];
  const [formData, setFormData] = useState(defaultFormData);

  const { pharmacyNames, isLoading: isPharmacyLoading } = usePharmacyNames();

  const handleChange = (newFormData) => {
    setFormData(newFormData);
  };

  const modeText = {
    edit: "Update favorite",
    create: "Create favorite",
  };

  let isReadyToSubmit =
    favoriteValidator.validate(formData) &&
    (formData.baskName || formData.clientName);
  if (mode === "create") {
    const isFavoriteNameExists = favorites.find(
      ({ favoriteName }) => favoriteName === formData.favoriteName
    );
    if (isFavoriteNameExists) {
      isReadyToSubmit = false;
    }
  }

  const pharmacyIdOptions = useMemo(() => {
    return Object.keys(pharmacyNames).map((id) => ({
      value: id,
      label: pharmacyNames[id],
    }));
  }, [pharmacyNames]);

  const categoryOptions = useMemo(() => {
    return Array.from(new Set(favorites.map((item) => item.category)))
      .filter((item) => item !== undefined && item !== "")
      .map((item) => ({ value: item }));
  }, [favorites]);

  const formConfig = [
    [
      {
        name: "favoriteName",
        text: "Favorite Name",
        required: true,
      },
      {
        name: "type",
        text: "Type",
        required: true,
        type: "select",
        options: [{ value: "compound" }, { value: "med" }, { value: "supply" }],
      },
    ],
    [
      {
        name: "medication",
        text: "Medication",
        required: true,
        type: "search",
      },
      { name: "strength", text: "Strength" },
    ],
    [
      { name: "quantity", text: "Quantity", required: true, type: "number" },
      {
        name: "dispenseUnit",
        text: "Dispense Unit",
        required: true,
        type: "select",
        options: [
          { value: "capsule" },
          { value: "each" },
          { value: "gram" },
          { value: "ounce" },
          { value: "kit" },
          { value: "milligram" },
          { value: "milliliter" },
          { value: "packet" },
          { value: "tablet" },
          { value: "troche" },
          { value: "applicator" },
        ],
      },
    ],
    [
      { name: "refills", text: "Refills", required: true, type: "number" },
      {
        name: "daysSupply",
        text: "Days Supply",
        required: true,
        type: "number",
      },
    ],
    [
      {
        name: "allowSubstitutions",
        text: "Allow substitutions",
        required: true,
        type: "select",
        options: [{ value: "true" }, { value: "false" }],
      },
      { name: "sig", text: "SIG", required: true },
    ],
    [
      {
        name: "clientName",
        text: "Client Name",
        locked: mode === "edit",
      },
      { name: "baskName", text: "Bask Name" },
    ],
    [
      { name: "pharmacyNotes", text: "Pharmacy Notes", type: "textarea" },
      {
        name: "visitType",
        text: "Visit Type",
        type: "select",
        options: visitTypes.map((item) => ({ value: item })),
      },
    ],
    [
      {
        name: "pharmacyId",
        text: "Pharmacy Id",
        type: "creatableSelect",
        options: pharmacyIdOptions,
      },
      {
        name: "category",
        text: "Category",
        type: "creatableSelect",
        options: categoryOptions,
      },
    ],
    [{ name: "supplyId", text: "Supply Id", type: "number" }],
    [
      {
        name: "company",
        text: "Company List",
        type: "checkbox",
        options: companies,
      },
    ],
  ];

  const handleSubmitFavorite = async (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleReject = async (e) => {
    e.preventDefault();
    onReject(formData);
  };

  return (
    <>
      <span className="userFormError">{errorMessage}</span>
      <form
        onSubmit={handleSubmitFavorite}
        className="formStyle favorites-form"
      >
        <FormInputs
          config={formConfig}
          formData={formData}
          compareTo={compareTo}
          onChange={handleChange}
          mode={mode}
        />
        <div className="formRowRight">
          <button
            className="formButton"
            onClick={handleReject}
            // disabled={!isReadyToSubmit}
          >
            Reject
          </button>
          <button
            type="submit"
            className="formButton"
            disabled={!isReadyToSubmit}
          >
            {modeText[mode]}
          </button>
        </div>
      </form>
    </>
  );
};

FavoriteForm.propTypes = {
  mode: PropTypes.string.isRequired,
  defaultFormData: PropTypes.object,
  compareTo: PropTypes.object,
  favorites: PropTypes.array.isRequired,
  visitTypes: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

export default FavoriteForm;

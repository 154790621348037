import React, { useEffect, useState, Fragment } from "react";
import Modal from "react-bootstrap/Modal";

import xIcon from "../Assets/Icon/x@2x.png";
import { routerMap } from "../utils/router";

import "../MyAccount/MyAccount.css";

const AdminCompanies = (props) => {
  const { user, setUser, companies } = props;
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    companies: user.companies,
  });

  const { updateAdminUserCompanies } = routerMap;

  const token = localStorage.getItem("jwt");

  const handleUpdateDoc = async () => {
    await updateAdminUserCompanies(
      token,
      user,
      formData,
      setUser,
      setShow,
      setMessage
    );
  };

  const handleDocClick = () => {
    setShow(true);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const ogCompanies = formData.companies;
    const formDataIndex = ogCompanies && ogCompanies.indexOf(value);
    let docCompanies;
    if (value === "All") {
      docCompanies = [...companies.map((company) => company.name)];
    } else if (value === "None") {
      docCompanies = [];
    } else {
      if (ogCompanies && ogCompanies.includes(value)) {
        ogCompanies.splice(formDataIndex, 1);
        docCompanies = ogCompanies;
      } else {
        docCompanies = ogCompanies ? [...ogCompanies, value] : [value];
      }
    }
    setFormData({
      companies: docCompanies,
    });
  };

  const handleHide = () => {
    setShow(false);
    setFormData({
      companies: user.companies,
    });
  };

  const checkedCompanies = companies
    .map((company) => company.name)
    .sort()
    .map((companyName) => {
      const isChecked = formData.companies.includes(companyName);
      return (
        <div className="checkGroup" key={companyName}>
          <input
            type="checkbox"
            value={companyName}
            name="companies"
            onChange={handleChange}
            checked={isChecked}
            className="checkBox"
          />
          <span>{companyName}</span>
        </div>
      );
    });
  const [renderedCompanies, setRenderedCompanies] = useState(checkedCompanies);

  const renderCompanies = (data) => {
    const checkedCompanies = companies
      .map((company) => company.name)
      .sort()
      .map((companyName) => {
        const isChecked = data.includes(companyName);
        return (
          <div className="checkGroup" key={companyName}>
            <input
              type="checkbox"
              value={companyName}
              name="companies"
              onChange={handleChange}
              checked={isChecked}
              className="checkBox"
            />
            <span>{companyName}</span>
          </div>
        );
      });
    setRenderedCompanies(checkedCompanies);
  };

  useEffect(() => {
    renderCompanies(formData.companies);
  }, [formData]);

  return (
    <Fragment>
      <div onClick={handleDocClick}>Update my companies</div>

      <Modal show={show} onHide={handleHide} dialogClassName="modalDims">
        <div className="modalHeader">
          <div className="modalTitle">
            <span>Company list</span>
            <button onClick={handleHide} className="modalCloseButton">
              <img src={xIcon} className="xIcon" alt="close" />
            </button>
          </div>
        </div>
        <span className="userFormError">{message}</span>
        <div className="formStyle">
          <div className="formRowLeft">
            <div>
              <div className="checkedStatesLong">
                <div className="checkGroup">
                  <input
                    type="checkbox"
                    value="All"
                    name="companies"
                    onChange={handleChange}
                    checked={
                      formData.companies.length === renderedCompanies.length
                    }
                    className="checkBox"
                  />
                  <span>All</span>
                </div>
                <div className="checkGroup">
                  <input
                    type="checkbox"
                    value="None"
                    name="companies"
                    onChange={handleChange}
                    checked={formData.companies.length === 0}
                    className="checkBox"
                  />
                  <span>None</span>
                </div>
                {renderedCompanies}
              </div>
            </div>
          </div>
          <div className="formRowRight">
            <button onClick={handleHide} className="formButton">
              Cancel
            </button>
            <button className="formButton" onClick={handleUpdateDoc}>
              Update list
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default AdminCompanies;

import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { routerMap } from "../utils/router";

import "../MyAccount/MyAccount.css";

const AllPatients = (props) => {
  const { user, patients, currentPatient, setCurrentPatient, listType } = props;

  const navigate = useNavigate();

  const token = localStorage.getItem("jwt");

  const { getPatient, logEvent } = routerMap;

  const handlePatientClick = async (e) => {
    const patientId = e.currentTarget.id;

    let eventName;
    let isLoggable = true;
    switch (listType) {
      case "nondocAdmin":
        eventName = "adminGoToAdminPatient";
        break;
      case "docAdmin":
        eventName = "adminGoToClinicalPatient";
        break;
      case "superAdmin":
        isLoggable = false;
        break;
      default:
        eventName = "goToPatient";
    }

    if (isLoggable && eventName) {
      await logEvent(token, eventName, {
        userId: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
      });
    }
    await getPatient(token, patientId, setCurrentPatient);
  };

  const switchFilter = (visit) => {
    switch (listType) {
      case "nondocAdmin":
        return (
          visit.status === "admin" &&
          !visit.isClinicianAdmin &&
          !visit.isTechSupport
        );
      case "docAdmin":
        return (
          visit.status === "admin" &&
          !!visit.isClinicianAdmin &&
          !visit.isSuperAdmin &&
          !visit.isTechSupport
        );
      case "superAdmin":
        return (
          visit.status === "admin" &&
          !!visit.isClinicianAdmin &&
          !!visit.isSuperAdmin &&
          !visit.isTechSupport
        );
      case "techSupport":
        return visit.status === "admin" && !!visit.isTechSupport;
      default:
        return visit.status === "active";
    }
  };

  const timeAt = (time) => {
    const then = new Date(time);
    let hours = then.getHours();
    let ampm = "am";
    if (hours >= 12) {
      if (hours === 24 || hours === 12) {
        hours = 12;
      } else {
        hours = hours % 12;
      }
      ampm = "pm";
    }
    let minutes = then.getMinutes();
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    const month = then.getMonth() + 1;
    const date = then.getDate();
    const year = then.getFullYear();
    const middleDot = "\u00B7";
    const prettyThen = `${month}/${date}/${year} ${middleDot} ${hours}:${minutes}${ampm}`;
    return prettyThen;
  };

  const patientMapAdmin = (patients) => {
    const filteredAndSortedVisits = patients
      .map((patient) => {
        const sortedVisits = patient.visits
          .filter((visit) => switchFilter(visit))
          .sort(
            (a, b) => new Date(b.updateTimestamp) - new Date(a.updateTimestamp)
          );
        if (sortedVisits.length) {
          const recentVisit = sortedVisits[0];
          return {
            updateTimestamp: recentVisit.updateTimestamp,
            patient,
            company: recentVisit.company,
            visitType: recentVisit.visitType,
          };
        } else {
          return null;
        }
      })
      .filter((item) => item)
      .sort(
        (a, b) => new Date(a.updateTimestamp) - new Date(b.updateTimestamp)
      );
    const mappedToRender = filteredAndSortedVisits.map((obj) => {
      return (
        <Fragment key={obj.patient._id}>
          <div
            id={obj.patient._id}
            onClick={handlePatientClick}
            className="listCard"
          >
            <div>
              {obj.patient.firstName} {obj.patient.lastName}
            </div>
            <div>{timeAt(obj.updateTimestamp)}</div>
            <div>
              {obj.company}
              {", "}
              {obj.visitType}
            </div>
          </div>
        </Fragment>
      );
    });
    return mappedToRender;
  };

  // const patientMapAdmin = (patients) => {
  //   return patients
  //     .map((patient) => {
  //       const sortedVisits = patient.visits
  //         .filter((visit) => switchFilter(visit))
  //         .sort(
  //           (a, b) => new Date(b.updateTimestamp) - new Date(a.updateTimestamp)
  //         );
  //       if (sortedVisits.length) {
  //         const recentVisit = sortedVisits[0];
  //         return (
  //           <Fragment key={recentVisit.updateTimestamp}>
  //             <div
  //               id={patient._id}
  //               onClick={handlePatientClick}
  //               className="listCard"
  //             >
  //               <div>
  //                 {patient.firstName} {patient.lastName}
  //               </div>
  //               <div>{timeAt(recentVisit.updateTimestamp)}</div>
  //               <div>
  //                 {recentVisit.company}
  //                 {", "}
  //                 {recentVisit.visitType}
  //               </div>
  //             </div>
  //           </Fragment>
  //         );
  //       } else {
  //         return null;
  //       }
  //     })
  //     .filter((item) => item)
  //     .sort((a, b) => new Date(a.key) - new Date(b.key));
  // };

  const patientMap = (patients) => {
    return patients
      .map((patient) => {
        const sortedVisits = patient.visits
          .filter((visit) => switchFilter(visit))
          .sort(
            (a, b) => new Date(b.createTimestamp) - new Date(a.createTimestamp)
          );
        if (sortedVisits.length) {
          const recentVisit = sortedVisits[0];
          return (
            <Fragment key={recentVisit.createTimestamp}>
              <div
                id={patient._id}
                onClick={handlePatientClick}
                className="listCard"
              >
                <div>
                  {patient.firstName} {patient.lastName}
                </div>
                <div>{timeAt(recentVisit.createTimestamp)}</div>
                <div>
                  {recentVisit.company}
                  {", "}
                  {recentVisit.visitType}
                </div>
              </div>
            </Fragment>
          );
        } else {
          return null;
        }
      })
      .filter((item) => item)
      .sort((a, b) => new Date(a.key) - new Date(b.key));
  };

  // const patientsWithIvyChats = patientMapAdmin(
  //   patients.filter((patient) => {
  //     const onlyIvy = patient.chat.some(
  //       (chatObj) => chatObj.company === "baskIvyRx"
  //     );
  //     return onlyIvy;
  //   })
  // );

  const patientsWithMotivatedAdmin = patientMapAdmin(
    patients.filter((patient) => {
      const onlyMotivated = patient.chat.some(
        (chatObj) => chatObj.company === "motivated"
      );
      return onlyMotivated;
    })
  );

  const patientsWithUnresolvedAdminVisits = patientMapAdmin(
    patients.filter((patient) => {
      const unresolvedVisits = patient.visits.filter((visit) => {
        return !visit.resolvedTimestamp && visit.status === "admin";
      });
      return !!unresolvedVisits.length;
    })
  );

  const patientsWithManyChats = patientMapAdmin(
    patients.filter((patient) => {
      const senderTypes = patient.chat.map((chat) =>
        chat.messages.map((message) => message.senderType)
      );
      const manyPatientChats = senderTypes.some((typeArray) =>
        typeArray.slice(-2).every((elem) => elem === "patient")
      );
      return manyPatientChats;
    })
  ).filter(
    (item) =>
      !patientsWithUnresolvedAdminVisits.map((p) => p.key).includes(item.key)
  );

  const adminList = [
    // ...patientsWithIvyChats,
    ...patientsWithMotivatedAdmin,
    ...patientsWithUnresolvedAdminVisits,
    ...patientsWithManyChats,
    ...patientMapAdmin(patients).filter(
      (item) =>
        !patientsWithManyChats.map((p) => p.key).includes(item.key) &&
        !patientsWithUnresolvedAdminVisits.map((p) => p.key).includes(item.key)
    ),
  ];

  const patientsWithMotivated = patientMap(
    patients.filter((patient) => {
      const onlyMotivated = patient.chat.some(
        (chatObj) => chatObj.company === "motivated"
      );
      return onlyMotivated;
    })
  );

  const patientList = [
    ...patientsWithMotivated,
    ...patientMap(patients).filter(
      (item) => !patientsWithMotivated.map((p) => p.key).includes(item.key)
    ),
  ];

  useEffect(() => {
    if (patients.find((patient) => patient._id === currentPatient._id)) {
      navigate(`/patient/${currentPatient._id}`);
    }
  }, [currentPatient]);

  const displayList = () => {
    switch (listType) {
      case "docAdmin":
      case "nondocAdmin":
      case "superAdmin":
      case "techSupport":
        if (adminList.length) {
          return adminList;
        } else {
          return <h5>No Patients</h5>;
        }
      default:
        return patientList;
    }
  };

  return (
    <>
      {!!listType && (
        <div className="listLength">Queue length: {adminList.length}</div>
      )}
      {displayList()}
    </>
  );
};

AllPatients.propTypes = {
  user: PropTypes.object.isRequired,
  patients: PropTypes.array.isRequired,
  currentPatient: PropTypes.object.isRequired,
  setCurrentPatient: PropTypes.func.isRequired,
  listType: PropTypes.string,
};

export default AllPatients;

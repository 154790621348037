const dev = {
  s3: {
    BUCKET: "belugastaging714-frontend.s3.amazonaws.com",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL:
      process.env.REACT_APP_SERVER_URL ||
      "https://api-staging.belugahealth.com",
  },
};

const prod = {
  s3: {
    BUCKET: "belugaprod714-frontend.s3.amazonaws.com",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.belugahealth.com",
  },
};

const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export function isProduction() {
  return process.env.REACT_APP_STAGE === "prod";
}

export default config;

// const dev = {
//   s3: {
//     BUCKET: "belugastaging714-frontend.s3.amazonaws.com",
//   },
//   backend: {
//     REGION: "us-east-1",
//     URL:
//       process.env.REACT_APP_SERVER_URL ||
//       "https://api-staging.belugahealth.com",
//   },
// };

// const prod = {
//   s3: {
//     BUCKET: "belugaprod714-frontend.s3.amazonaws.com",
//   },
//   backend: {
//     REGION: "us-east-1",
//     URL: "https://api.belugahealth.com",
//   },
// };

// const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

// export default config;

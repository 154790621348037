import React from 'react';

const RadioButtonSelect = ({ name, value, onChange, label }) => (
  <div className="formRow">
    <div>
      <div className="formField">{label}</div>
      <div className={`${name}CheckboxHolder`}>
        <div className="checkGroup" key={`${name}-true`}>
          <input
            type="radio"
            value="true"
            name={name}
            checked={value === "true"}
            onChange={onChange}
            className="checkBox"
          />
          <span>Yes</span>
        </div>
        <div className="checkGroup" key={`${name}-false`}>
          <input
            type="radio"
            value="false"
            name={name}
            checked={value === "false"}
            onChange={onChange}
            className="checkBox"
          />
          <span>No</span>
        </div>
      </div>
    </div>
  </div>
);

export default RadioButtonSelect;

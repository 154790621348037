import React from "react";
import { Form } from "react-bootstrap";
import FavoriteTable from "../components/FavoriteTable";
import { sortFavorites, tableKeyTitles } from "./utils";
import { routerMap } from "../../utils/router";
import FavoriteModalFormButton from "../components/FavoriteModalFormButton";
import PropTypes from "prop-types";

const PendingUpdateModalFormButton = (props) => {
  const { pending, favorites, visitTypes, companies, onSuccess, handleReject } =
    props;
  const { updateFavorite } = routerMap;

  // const switchCompare = (company) => {
  //   switch (true) {
  //     // case /^bask/.test(company):
  //     //   return "baskName";
  //     default:
  //       return "clientName";
  //   }
  // };

  // const field = switchCompare(pending.requestingCompany);

  const existedFavorite = favorites.find(
    (favorite) => favorite.clientName === pending.clientName
  );
  // const existedFavorite = favorites.find(
  //   (favorite) => favorite[field] === pending[field]
  // );

  const handleFormSubmit = async (formData, setErrorMessage) => {
    const token = localStorage.getItem("jwt");
    const res = await updateFavorite(token, setErrorMessage, formData, true);
    if (res && res.favorite) {
      return res;
    }
    return false;
  };

  return (
    <FavoriteModalFormButton
      btnTitle="Edit"
      modalTitle="Update from pending"
      defaultFormData={existedFavorite}
      onFormSubmit={handleFormSubmit}
      onFormReject={handleReject}
      onSuccess={onSuccess}
      favorites={favorites}
      visitTypes={visitTypes}
      companies={companies}
      mode="edit"
      formCompareTo={pending}
    />
  );
};
PendingUpdateModalFormButton.propTypes = {
  pending: PropTypes.object.isRequired,
  favorites: PropTypes.array.isRequired,
  visitTypes: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

const PendingUpdateTableTab = (props) => {
  const {
    user,
    pendingUpdateList = [],
    favorites = [],
    visitTypes = [],
    companies = [],
    onSuccess,
    handleReject,
    adminIsLoading,
  } = props;

  const list = sortFavorites(pendingUpdateList);

  const rowOverrides = {
    createUpdate:
      user.systemAdmin &&
      ((value, element) => (
        <PendingUpdateModalFormButton
          pending={element}
          favorites={favorites}
          visitTypes={visitTypes}
          companies={companies}
          onSuccess={onSuccess}
          handleReject={handleReject}
        />
      )),
    allowSubstitutions: (value) => (
      <Form.Check disabled defaultChecked={value} />
    ),
  };

  return (
    <div className="favorites-page">
      {adminIsLoading ? (
        <div>Loading...</div>
      ) : !!pendingUpdateList.length ? (
        <FavoriteTable
          list={list}
          getRowKey={(element) => element._id}
          tableKeyTitles={tableKeyTitles}
          rowOverrides={rowOverrides}
        />
      ) : (
        <div className="favorites-page">None pending</div>
      )}
    </div>
  );
};
PendingUpdateTableTab.propTypes = {
  user: PropTypes.object.isRequired,
  pendingUpdateList: PropTypes.array,
  favorites: PropTypes.array.isRequired,
  visitTypes: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default PendingUpdateTableTab;

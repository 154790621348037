import { useEffect, useState } from "react";
import { routerMap } from "../../../utils/router";

const { getPharmacyDetails } = routerMap;

export const usePharmacyDetails = (currentVisitPharmacyId) => {
  const [patientPharmacy, setPatientPharmacy] = useState("");
  const [isLoadingPharmacy, setIsLoadingPharmacy] = useState(false);

  useEffect(() => {
    async function getPharmacyDetailsAsync() {
      try {
        setIsLoadingPharmacy(true);
        const pharmacyId = currentVisitPharmacyId;
        if (pharmacyId) {
          const token = localStorage.getItem("jwt");
          const pharmacyDetails = await getPharmacyDetails(token, pharmacyId);
          if (pharmacyDetails?.Item) {
            setPatientPharmacy(pharmacyDetails.Item.StoreName);
          } else setPatientPharmacy("");
        } else {
          // this is so that if we have a visit without a pharmacy
          // we will show as "None"
          setPatientPharmacy("");
        }
      } catch (error) {
        console.error(error);
        setPatientPharmacy("");
        setIsLoadingPharmacy(false);
      }
      setIsLoadingPharmacy(false);
    }

    getPharmacyDetailsAsync();
    // only fetch details if the pharmacy Id changes (when switching between visits)
  }, [currentVisitPharmacyId]);

  return {
    patientPharmacy,
    isLoadingPharmacy,
  };
};

import React, { useEffect, useState } from "react";
import Spinner from "../Components/Spinner";
import xIcon from "../Assets/Icon/x@2x.png";
import smallSearch from "../Assets/Icon/search.png";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

import "../MyAccount/MyAccount.css";

const CompanyCheckboxModal = (props) => {
  const {
    type,
    title,
    show,
    list,
    updateList,
    handleConfirm,
    handleClose,
    handleNoneCheckbox,
    loading,
    isReadyToConfirm,
  } = props;
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const displayCheckboxes = list
    ?.filter((item) => {
      if (!searchQuery) {
        return item;
      }
      return item.name.toLowerCase().includes(searchQuery.toLowerCase());
    })
    .map((item) => {
      return (
        <div className="checkGroup" key={`${item.id}-checkgroup`}>
          <input
            type="checkbox"
            className="checkBox"
            checked={item.checked}
            onChange={(e) => updateList(e, item, type)}
          />
          <span>{item.name}</span>
          {item.surge && <span className="surgeText">Surge</span>}
        </div>
      );
    });

  const onModalClose = () => {
    setSearchQuery("");
    handleClose();
  };

  const confirm = async () => {
    const result = await handleConfirm(type);
    if (result.success) {
      onModalClose();
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onModalClose}
      dialogClassName="modalDims"
    >
      <div className="modalHeader">
        <div className="modalTitle">
          <span>{title}</span>
          <button onClick={onModalClose} className="modalCloseButton">
            <img src={xIcon} className="xIcon" alt="close" />
          </button>
        </div>
      </div>
      {list && list.length > 0 && !loading ? (
        <div className="modalBody">
          <div className="checkboxListManageBar">
            <div
              className="deselectAllButton"
              onClick={() => handleNoneCheckbox(type)}
            >
              Deselect All
            </div>
            <div className="companies-search-bar">
              <InputGroup className="searchInput">
                <InputGroup.Text>
                  <img src={smallSearch} alt="Search Icon" />
                </InputGroup.Text>
                <FormControl
                  name="searchCompany"
                  placeholder="Search"
                  onChange={handleSearchChange}
                  value={searchQuery}
                />
              </InputGroup>
            </div>
          </div>
          <div className="modalCheckboxList">
            {list ? displayCheckboxes : <></>}
          </div>
        </div>
      ) : (
        <Spinner
          style={{
            borderColor: "#eaeaea",
            position: "relative",
            marginTop: "25px",
          }}
          size="lg"
        />
      )}
      <div className="formRowRight">
        <button
          type="button"
          className="formButton"
          disabled={!isReadyToConfirm}
          onClick={confirm}
        >
          Update
        </button>
      </div>
    </Modal>
  );
};

export default CompanyCheckboxModal;

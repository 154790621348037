export const successfulActionResponse = {
  status: 200,
  data: {
    Id: 59314503,
    Result: {
      ResultCode: "OK",
      ResultDescription: "",
    },
  },
};

export const failActionResponse = {
  status: 200,
  data: {
    Id: 59314503,
    Result: {
      ResultCode: "ERROR",
      ResultDescription:
        "Property: PrescriptionId Details: Changes are not allowed when approving this request type.",
    },
  },
};

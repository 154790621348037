import React from "react";
import PropTypes from "prop-types";
import "./Spinner.css";

const Spinner = (props) => {
  const { style, size } = props;
  const spinnerSize = size ? size : "sm";
  const className = "spinner " + spinnerSize;

  return <div data-cy="spinner" className={className} style={style} />;
};

Spinner.propTypes = {
  style: PropTypes.object,
};

export default Spinner;

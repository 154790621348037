import { useEffect, useState } from "react";
import { routerMap } from "../utils/router";

const { getCompanies } = routerMap;

const inactiveCompanies = [
  "scoreBlue",
  "sprout",
  "metuchen",
  "revyv",
  "rxCompoundStore",
  "powerplate",
  "medsForMen",
  "oneHealthLabs",
  "sunrise",
];

const useVisitTypes = (user, admin) => {
  const [companies, setCompanies] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("jwt");

  useEffect(() => {
    if (!user.admin || !admin) {
      return;
    }
    const allPromises = async () => {
      // setIsLoading(true);
      await getCompanies(token, setCompanies);
      // setIsLoading(false);
    };
    allPromises();
  }, [admin, token, user.admin, user.systemAdmin]);

  const onlyActiveCompanies = companies.filter(
    (company) => !inactiveCompanies.includes(company.name)
  );

  const visitTypes = [
    ...new Set(
      onlyActiveCompanies
        .map((company) =>
          company.visitTypes.map((visitType) => visitType.type).flat()
        )
        .flat()
    ),
  ].sort();

  return { visitTypes };
};

export default useVisitTypes;

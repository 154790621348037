import React, { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";

import { routerMap } from "../utils/router";
import { trackAdminTimeOnly } from "../utils/timeTrackingUtils";

import AdminCompanies from "./AdminCompanies";

import bigLogo from "../Assets/bigLogo.png";
import arrowDropdownIcon from "../Assets/Icon/arrowDropdown@2x.png";
import backIcon from "../Assets/Icon/back@2x.png";
import xIcon from "../Assets/Icon/x@2x.png";

import Modal from "react-bootstrap/Modal";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";

import "./Header.css";

const Header = (props) => {
  const {
    user,
    setUser,
    logout,
    admin,
    setAdmin,
    currentPatient,
    setCurrentPatient,
    companies,
  } = props;
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showGuidelinesModal, setShowGuidelinesModal] = useState(false);
  const [guidelines, setGuidelines] = useState([]);
  const [patientsLoading, setPatientsLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const token = localStorage.getItem("jwt");

  const {
    getPatient,
    getFilteredPatients,
    getPatientsAdmin,
    getGuidelines,
    changePassword,
    logEvent,
  } = routerMap;

  const handleChangePassword = async () => {
    await changePassword(
      token,
      user,
      newPassword,
      confirmPassword,
      setUser,
      setShowPasswordModal,
      setMessage
    );
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "newPassword":
        setNewPassword(e.target.value);
        break;
      case "confirmPassword":
        setConfirmPassword(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleClosePassword = () => {
    setShowPasswordModal(false);
    setMessage("");
  };

  const handleCloseGuidelines = () => {
    setShowGuidelinesModal(false);
  };

  const openPasswordModal = (e) => {
    e.preventDefault();
    setShowPasswordModal(true);
  };

  const openGuidelinesModal = async (e) => {
    e.preventDefault();
    await getGuidelines(token, user, setGuidelines);
    setShowGuidelinesModal(true);
  };

  const backToDashboard = async () => {
    navigate("/myaccount");
  };

  const handleLogout = async () => {
    await logout();
  };

  const handleAdminChange = () => {
    setAdmin(!admin);
    setCurrentPatient({});
  };

  const setPatients = async (routeFunction, user) => {
    const hyperLocalState = {
      filteredPatients: [],
    };
    const callback = (data) => {
      hyperLocalState.filteredPatients = data;
      return;
    };
    if (user) {
      await routeFunction(token, user, callback);
    } else {
      await routeFunction(token, callback);
    }
    return hyperLocalState.filteredPatients;
  };

  const patientMapAdmin = (listType, patients) => {
    return patients
      .map((patient) => {
        const sortedAdmin = patient.visits
          .filter((visit) => switchFilter(listType, visit))
          .sort(
            (a, b) => new Date(b.updateTimestamp) - new Date(a.updateTimestamp)
          );
        const recentAdmin = sortedAdmin[0];
        if (sortedAdmin.length) {
          return {
            _id: patient._id,
            update: recentAdmin.updateTimestamp,
            name: `${patient.firstName} ${patient.lastName}`,
          };
        } else {
          return null;
        }
      })
      .filter((item) => item)
      .sort((a, b) => new Date(a.update) - new Date(b.update));
  };

  const patientMap = (patients) => {
    return patients
      .map((patient) => {
        const sortedVisits = patient.visits
          .filter((visit) => visit.status === "active")
          .sort(
            (a, b) => new Date(b.createTimestamp) - new Date(a.createTimestamp)
          );
        if (sortedVisits.length) {
          const recentVisit = sortedVisits[0];
          return {
            _id: patient._id,
            create: recentVisit.createTimestamp,
            name: `${patient.firstName} ${patient.lastName}`,
          };
        } else {
          return null;
        }
      })
      .filter((item) => item)
      .sort((a, b) => new Date(a.create) - new Date(b.create));
  };

  const handleNextClick = async () => {
    setPatientsLoading(true);

    await logEvent(token, "nextPatient", {
      userId: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
    });
    const response = await setPatients(getFilteredPatients, user);

    const patientsWithMotivated = patientMap(
      response.filter((patient) => {
        const onlyMotivated = patient.chat.some(
          (chatObj) => chatObj.company === "motivated"
        );
        return onlyMotivated;
      })
    );

    const patientList = [
      ...patientsWithMotivated,
      ...patientMap(response).filter(
        (item) =>
          !patientsWithMotivated.map((p) => p.create).includes(item.create)
      ),
    ];

    if (patientList.length > 0) {
      await getPatient(token, patientList[0]._id, setCurrentPatient);
      setPatientsLoading(false);
    } else {
      navigate("/myaccount");
    }
  };

  const switchFilter = (listType, visit) => {
    switch (listType) {
      case "nondocAdmin":
        return (
          visit.status === "admin" &&
          !visit.isClinicianAdmin &&
          !visit.isSuperAdmin &&
          !visit.isTechSupport
        );
      case "docAdmin":
        return (
          visit.status === "admin" &&
          !!visit.isClinicianAdmin &&
          !visit.isSuperAdmin &&
          !visit.isTechSupport
        );
      case "superAdmin":
        return (
          visit.status === "admin" &&
          !!visit.isClinicianAdmin &&
          !!visit.isSuperAdmin &&
          !visit.isTechSupport
        );
      default:
        return null;
    }
  };

  const handleNextAdmin = async (listType) => {
    switch (listType) {
      case "docAdmin": {
        await logEvent(token, "adminNextClinical", {
          userId: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
        });
        break;
      }
      case "nondocAdmin": {
        await logEvent(token, "adminNextAdmin", {
          userId: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
        });
        break;
      }
      default:
    }

    setPatientsLoading(true);
    const response = await setPatients(getPatientsAdmin);

    // const patientsWithIvyChats = patientMapAdmin(
    //   listType,
    //   response.filter((patient) => {
    //     const onlyIvy = patient.chat.some(
    //       (chatObj) => chatObj.company === "baskIvyRx"
    //     );
    //     return onlyIvy;
    //   })
    // );

    const patientsWithMotivatedAdmin = patientMapAdmin(
      listType,
      response.filter((patient) => {
        const onlyMotivated = patient.chat.some(
          (chatObj) => chatObj.company === "motivated"
        );
        return onlyMotivated;
      })
    );

    const patientsWithUnresolvedAdminVisits = patientMapAdmin(
      listType,
      response.filter((patient) => {
        const unresolvedVisits = patient.visits.filter((visit) => {
          return !visit.resolvedTimestamp && visit.status === "admin";
        });
        return !!unresolvedVisits.length;
      })
    );

    const patientsWithManyChats = patientMapAdmin(
      listType,
      response.filter((patient) => {
        const senderTypes = patient.chat.map((chat) =>
          chat.messages.map((message) => message.senderType)
        );
        const manyPatientChats = senderTypes.some((typeArray) =>
          typeArray.slice(-2).every((elem) => elem === "patient")
        );
        return manyPatientChats;
      })
    ).filter(
      (item) =>
        !patientsWithUnresolvedAdminVisits
          .map((p) => p.update)
          .includes(item.update)
    );

    const adminList = [
      // ...patientsWithIvyChats,
      ...patientsWithMotivatedAdmin,
      ...patientsWithUnresolvedAdminVisits,
      ...patientsWithManyChats,
      ...patientMapAdmin(listType, response).filter(
        (item) =>
          !patientsWithManyChats.map((p) => p.update).includes(item.update) &&
          !patientsWithUnresolvedAdminVisits
            .map((p) => p.update)
            .includes(item.update)
      ),
    ];

    if (adminList.length > 0) {
      // get next patient in sorted list
      const thisIndex = adminList.findIndex(
        (patient) => patient._id === currentPatient._id
      );
      const nextIndex = thisIndex + 1;
      if (nextIndex >= adminList.length) {
        navigate("/myaccount");
      } else {
        await getPatient(token, adminList[nextIndex]._id, setCurrentPatient);
      }
      setPatientsLoading(false);
    } else {
      navigate("/myaccount");
    }
  };

  const formattedGuidelines = () => {
    const result = [];
    const map = new Map();
    for (const item of guidelines) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        result.push({
          name: item.name,
          guide: item.guide,
        });
      }
    }
    return result.map((guideline) => {
      if (guideline.guide) {
        return (
          <li key={guideline.name}>
            <a href={guideline.guide} rel="noopener noreferrer" target="_blank">
              {guideline.name}
            </a>
          </li>
        );
      }
      return null;
    });
  };

  return (
    <>
      <Navbar className="header">
        <div className="headerLogoAndOption">
          <img src={bigLogo} alt="Beluga Health Logo" className="headerLogo" />
          {user.admin && location.pathname === "/myaccount" && (
            <>
              <div>
                <input
                  type="checkbox"
                  onChange={handleAdminChange}
                  id="admin"
                  checked={admin}
                />
                <span className="actAdmin">Act as admin</span>
              </div>
              {user.admin && admin && (
                <Link
                  to={"/favourites"}
                  className="headerButton"
                  onClick={() => trackAdminTimeOnly(user, admin, token)}
                >
                  To favourites
                </Link>
              )}

              {user.systemAdmin && admin && (
                <Link to={"/companies"} className="headerButton">
                  View companies
                </Link>
              )}
            </>
          )}
          {location.pathname !== "/myaccount" && location.pathname !== "/" && (
            <>
              <span onClick={backToDashboard} className="backToDash">
                <img src={backIcon} alt="Back Arrow" className="backArrow" />
                <div>Back to Dashboard</div>
              </span>
            </>
          )}
          {/patient\//.test(location.pathname) && (
            <>
              {user.admin && admin ? (
                <>
                  <button
                    className="formButton nextButton"
                    onClick={() => handleNextAdmin("docAdmin")}
                  >
                    {!patientsLoading && <span>Next Clinical Admin</span>}
                    {patientsLoading && <span>Loading...</span>}
                    <img
                      src={backIcon}
                      alt="Forward Arrow"
                      className="forwardArrow"
                    />
                  </button>
                  <button
                    className="formButton nextButton"
                    onClick={() => handleNextAdmin("nondocAdmin")}
                  >
                    {!patientsLoading && <span>Next Admin</span>}
                    {patientsLoading && <span>Loading...</span>}
                    <img
                      src={backIcon}
                      alt="Forward Arrow"
                      className="forwardArrow"
                    />
                  </button>
                  <button
                    className="formButton nextButton"
                    onClick={() => handleNextAdmin("superAdmin")}
                  >
                    {!patientsLoading && <span>Next Super Admin</span>}
                    {patientsLoading && <span>Loading...</span>}
                    <img
                      src={backIcon}
                      alt="Forward Arrow"
                      className="forwardArrow"
                    />
                  </button>
                </>
              ) : (
                <button
                  className="formButton nextButton"
                  onClick={handleNextClick}
                >
                  {!patientsLoading && <span>Next Patient</span>}
                  {patientsLoading && <span>Loading...</span>}
                  <img
                    src={backIcon}
                    alt="Forward Arrow"
                    className="forwardArrow"
                  />
                </button>
              )}
            </>
          )}
        </div>

        {Object.keys(user).length > 1 && (
          <Dropdown title={`${user.firstName} ${user.lastName}`} align="end">
            <Dropdown.Toggle className="nameDrop" id="nameDrop">
              {`${user.firstName} ${user.lastName}`}
              <img src={arrowDropdownIcon} alt="arrowDown" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdownMenu">
              <Dropdown.Item onClick={openGuidelinesModal}>
                Clinical Guidelines
              </Dropdown.Item>
              <Dropdown.Item
                rel="noopener noreferrer"
                target="_blank"
                href="https://customerconsents.s3.amazonaws.com/Beluga_Health_PA_Privacy_Policy.pdf"
              >
                Privacy Policy
              </Dropdown.Item>
              <Dropdown.Item
                rel="noopener noreferrer"
                target="_blank"
                href="https://customerconsents.s3.amazonaws.com/Beluga_Health_Provider_TOU.pdf"
              >
                Terms of Use
              </Dropdown.Item>
              <Dropdown.Item onClick={openPasswordModal}>
                Change Password
              </Dropdown.Item>
              {admin && (
                <Dropdown.Item>
                  <AdminCompanies
                    user={user}
                    setUser={setUser}
                    companies={companies}
                  />
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Navbar>

      <Modal
        show={showPasswordModal}
        onHide={handleClosePassword}
        dialogClassName="passwordModal"
      >
        <div className="modalHeader">
          <div className="modalTitle">
            <span>Password Change Modal</span>
            <button onClick={handleClosePassword} className="modalCloseButton">
              <img src={xIcon} className="xIcon" alt="close" />
            </button>
          </div>
          <div className="passwordMessage">{message}</div>
        </div>
        <div className="modalBody">
          <div className="formStyle">
            <div className="formField">Change Your Password</div>
            <input
              name="newPassword"
              type="password"
              placeholder="New Password"
              onChange={handleChange}
              className="formInput"
            />
            <input
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              onChange={handleChange}
              className="formInput"
            />
            <button className="formButton" onClick={handleChangePassword}>
              Change Password
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        show={showGuidelinesModal}
        onHide={handleCloseGuidelines}
        dialogClassName="guideModal"
      >
        <div className="modalHeader">
          <div className="modalTitle">
            <span>Guidelines</span>
            <button
              onClick={handleCloseGuidelines}
              className="modalCloseButton"
            >
              <img src={xIcon} className="xIcon" alt="close" />
            </button>
          </div>
        </div>
        <div className="modalBody">
          <ul>
            {formattedGuidelines()}
            <li key={"weightloss"}>
              <a
                href={
                  "https://drive.google.com/file/d/1yNwu2io3iuqM0ngwBAesCgtCSvoA5vX5/view"
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                Weightloss
              </a>
            </li>
            <li key={"ED"}>
              <a
                href={
                  "https://docs.google.com/document/d/16P-ehw3KfCryjF3oo1yep4vselfEsnaY-qEc3ofX4V8/edit?usp=sharing"
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                ED
              </a>
            </li>
            <li key={"glutathione"}>
              <a
                href={
                  "https://docs.google.com/document/d/1JKvA0STQvgibp5wgLzdsdUG-CqIkRbzh/edit?usp=sharing&ouid=104230385490414373429&rtpof=true&sd=true"
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                Glutathione
              </a>
            </li>
            <li key={"nurtec"}>
              <a
                href={
                  "https://docs.google.com/document/d/1ZtAR57394JLjmlQNGDr34xi3Lt-j4OgZ/edit?usp=sharing&ouid=104230385490414373429&rtpof=true&sd=true"
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                Nurtec
              </a>
            </li>
            <li key={"nadplus"}>
              <a
                href={
                  "https://docs.google.com/document/d/1hhS08lNzbOlOK6YRFb2AXKqbeVC5ZP9n/edit?usp=sharing&ouid=104230385490414373429&rtpof=true&sd=true"
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                NAD+
              </a>
            </li>
            <li key={"metformin"}>
              <a
                href={
                  "https://docs.google.com/document/d/1bZvgcn8bsntjTCQVuzOoBvPUMqped5IP/edit?usp=sharing&ouid=104230385490414373429&rtpof=true&sd=true"
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                Metformin
              </a>
            </li>
            <li key={"airSupra"}>
              <a
                href={
                  "https://docs.google.com/document/d/17e7ULi4cr1y9TKQOE-RI2P9lvmzluaW_/edit?usp=sharing&ouid=104230385490414373429&rtpof=true&sd=true"
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                AirSupra
              </a>
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default Header;

// Note, key should match favorite's type as well for filtering
export const PrescriptionFilterTypesMap = {
  medicine: {
    key: "med",
    label: "Medicine",
  },
  compound: {
    key: "compound",
    label: "Compound",
  },
  supply: {
    key: "supply",
    label: "Supply",
  },
};

export const presriptionTypeOptions = [
  PrescriptionFilterTypesMap.medicine,
  PrescriptionFilterTypesMap.compound,
  PrescriptionFilterTypesMap.supply,
];

export const addPrescriptionUrlMap = {
  [PrescriptionFilterTypesMap.medicine.key]: "/dosespot/prescription/add",
  [PrescriptionFilterTypesMap.compound.key]: "/dosespot/compounds/add",
  [PrescriptionFilterTypesMap.supply.key]: "/dosespot/supplies/add",
};

const dispenseUnit = {
  applicator: "applicator",
  blister: "blister",
  caplet: "caplet",
  capsule: "capsule",
  each: "each",
  film: "film",
  gram: "gram",
  ounce: "ounce",
  gum: "gum",
  implant: "implant",
  insert: "insert",
  kit: "kit",
  lancet: "lancet",
  lozenge: "lozenge",
  milligram: "milligram",
  milliliter: "milliliter",
  packet: "packet",
  pad: "pad",
  patch: "patch",
  "pen needle": "pen needle",
  ring: "ring",
  sponge: "sponge",
  stick: "stick",
  strip: "strip",
  suppository: "suppository",
  swab: "swab",
  tablet: "tablet",
  troche: "troche",
  unspecified: "unspecified",
  wafer: "wafer",
};

export const getDispenseUnitOptions = () => {
  return Object.keys(dispenseUnit).map((k) => ({
    label: k,
    value: k,
  }));
};

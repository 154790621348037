import React, { useEffect, useState } from "react";
import Spinner from "../Components/Spinner";
import PropTypes from "prop-types";
import smallSearch from "../Assets/Icon/search.png";
import CreateCompanyModal from "./CreateCompanyModal";
import InputGroup from "react-bootstrap/InputGroup";
import companyIcon from "../Assets/Icon/company.png";
import FormControl from "react-bootstrap/FormControl";
import { routerMap } from "../utils/router";

import "./Companies.css";
import CompanyCheckboxModal from "./CompanyCheckboxModal";
import SuccessNotification from "../Components/notifications/SuccessNotification";

const Companies = (props) => {
  const { companies, setCompanies } = props;
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [curEditingCompany, setCurEditingCompany] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState(companies);

  const [clickedCompanyName, setClickedCompanyName] = useState(null);
  const [showFavoritesModal, setShowFavoritesModal] = useState(false);
  const [showDoctorsModal, setShowDoctorsModal] = useState(false);

  const [curCompanyFavorites, setCurCompanyFavorites] = useState(null);
  const [curCompanyDoctors, setCurCompanyDoctors] = useState(null);

  const [modalSpinner, setModalSpinner] = useState(false);

  const [favorites, setFavorites] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [apiFetchStatus, setApiFetchStatus] = useState({
    favoriteLoading: false,
    doctorLoading: false,
  });
  const [docsGeneratingCompanyId, setDocsGeneratingCompanyId] = useState(null);
  const [showNotificationsModal, setShowNotificationsModal] = useState({state: false, position: { top: 0, left: 0 }});
  const [message, setMessage] = useState("");
  const {
    getFavorites,
    getDoctors,
    bulkUpdateFavoriteCompany,
    bulkUpdateDoctor,
    generateCompanyDocumentation,
    getCompanies
  } = routerMap;

  const fetchFavorites = async () => {
    const token = localStorage.getItem("jwt");
    setApiFetchStatus((prevState) => ({ ...prevState, favoriteLoading: true }));
    await getFavorites(token, setFavorites);
    setApiFetchStatus((prevState) => ({
      ...prevState,
      favoriteLoading: false,
    }));
  };

  const fetchDoctors = async () => {
    const token = localStorage.getItem("jwt");
    setApiFetchStatus((prevState) => ({ ...prevState, doctorLoading: true }));
    await getDoctors(token, setDoctors, () => {});
    setApiFetchStatus((prevState) => ({ ...prevState, doctorLoading: false }));
  };

  const handleFetch = async () => {
    await Promise.all([fetchFavorites(), fetchDoctors()]);
  };

  useEffect(() => {
    handleFetch();
  }, []);

  useEffect(() => {
    // reset search query when companies are updated
    if (searchQuery) {
      filterCompanies(searchQuery);
    } else {
      setFilteredCompanies(companies);
    }
  }, [companies, searchQuery]);

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    filterCompanies(query);
  };

  const filterCompanies = (query) => {
    const filtered = companies.filter((company) =>
      company.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCompanies(filtered);
  };

  const handleCloseCompanyModal = () => {
    setShowCompanyModal(false);
    setCurEditingCompany(null);
    setEditMode(false);
  };

  const openCreateCompanyModal = (e) => {
    setShowCompanyModal(true);
  };

  const openEditCompanyModal = (e, company) => {
    setCurEditingCompany(company);
    setShowCompanyModal(true);
    setEditMode(true);
  };

  const [errorMessage, setErrorMessage] = useState(null);

  const handleDoctorsConfirm = async (type) => {
    setModalSpinner(true);

    const additionList = curCompanyDoctors
      .filter((item) => item.checked && !item.alreadyAdded)
      .map((item) => item.id);

    const subtractionList = curCompanyDoctors
      .filter((item) => item.removed && item.alreadyAdded)
      .map((item) => item.id);

    const token = localStorage.getItem("jwt");

    let addRes;
    let subRes;

    if (additionList.length > 0) {
      addRes = await bulkUpdateDoctor(token, setErrorMessage, {
        company: clickedCompanyName,
        doctorIds: additionList,
        action: "addition",
      });
    }

    if (subtractionList.length > 0) {
      subRes = await bulkUpdateDoctor(token, setErrorMessage, {
        company: clickedCompanyName,
        doctorIds: subtractionList,
        action: "subtraction",
      });
    }

    // set loader to false

    if (addRes || subRes) {
      // refetch favorites if success
      await fetchDoctors();
      setModalSpinner(false);

      return {
        success: true,
      };
    }

    return {
      success: false,
    };
  };
  const handleFavoritesConfirm = async (type) => {
    setModalSpinner(true);

    const additionList = curCompanyFavorites
      .filter((item) => item.checked && !item.alreadyAdded)
      .map((item) => item.clientName);

    const subtractionList = curCompanyFavorites
      .filter((item) => item.removed && item.alreadyAdded)
      .map((item) => item.clientName);

    const token = localStorage.getItem("jwt");
    let addRes;
    let subRes;

    if (additionList.length > 0) {
      addRes = await bulkUpdateFavoriteCompany(token, setErrorMessage, {
        company: clickedCompanyName,
        clientNames: additionList,
        action: "addition",
      });
    }

    if (subtractionList.length > 0) {
      subRes = await bulkUpdateFavoriteCompany(token, setErrorMessage, {
        company: clickedCompanyName,
        clientNames: subtractionList,
        action: "subtraction",
      });
    }

    if (addRes || subRes) {
      // refetch favorites if success
      await fetchFavorites();

      setModalSpinner(false);
      return {
        success: true,
      };
    }

    return { success: false };
  };

  const sortCheckboxList = (list) => {
    return list.sort((a, b) => {
      // First, sort by the `checked` property
      if (a.checked === b.checked) {
        // If both have the same `checked` value, sort by `name`
        return a.name.localeCompare(b.name);
      }
      // If only one is checked, place it first
      return a.checked ? -1 : 1;
    });
  };

  const deSelectAll = (type) => {
    const list = type === "favorites" ? curCompanyFavorites : curCompanyDoctors;
    const updatedList = list.map((item) => {
      const removed = item.alreadyAdded ? true : false;
      return { ...item, checked: false, removed: removed };
    });
    const sortedList = sortCheckboxList(updatedList);
    if (type === "favorites") {
      setCurCompanyFavorites(sortedList);
    } else {
      setCurCompanyDoctors(sortedList);
    }
  };

  const updateList = (e, item, type) => {
    const { checked } = e.target;
    const list = type === "favorites" ? curCompanyFavorites : curCompanyDoctors;
    const updatedList = list.map((x) => {
      const removed = x.alreadyAdded && !checked ? true : false;
      if (x.id === item.id) {
        return { ...x, checked: checked, removed: removed };
      } else {
        return x;
      }
    });
    const sortedList = sortCheckboxList(updatedList);
    if (type === "favorites") {
      setCurCompanyFavorites(sortedList);
    } else {
      setCurCompanyDoctors(sortedList);
    }
  };

  const setFavoritesList = (company) => {
    const favoriteList = favorites.map((fav) => {
      const checked = fav.company.includes(company.name);
      return {
        id: fav._id,
        clientName: fav.clientName,
        name: fav.favoriteName,
        checked: checked,
        alreadyAdded: checked,
      };
    });

    const sortedList = sortCheckboxList(favoriteList);

    setCurCompanyFavorites(sortedList);
  };

  const setDoctorsList = (company, isInRow = false) => {
    const docList = doctors.map((doc) => {
      const checked = doc.companies.includes(company.name);
      const name = doc.lastName + ", " + doc.firstName;
      return {
        id: doc._id,
        surge: doc.surge,
        name: name,
        checked: checked,
        alreadyAdded: checked,
      };
    });

    const sortedList = sortCheckboxList(docList);
    if (isInRow) {
      return docList
        .filter((doc) => doc.checked)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((doc) => {
          return <div>{doc.name}</div>;
        });
    }
    setCurCompanyDoctors(sortedList);
  };

  const handleOpenModal = (e, modalName, company) => {
    setClickedCompanyName(company.name);
    switch (modalName) {
      case "favorites":
        setFavoritesList(company);
        setShowFavoritesModal(true);
        break;
      case "doctors":
        setDoctorsList(company);
        setShowDoctorsModal(true);
        break;
      default:
        break;
    }
  };

  const handleCloseModal = (modalName) => {
    switch (modalName) {
      case "favorites":
        setShowFavoritesModal(false);
        setCurCompanyFavorites(null);
        setClickedCompanyName(null);
        break;
      case "doctors":
        setShowDoctorsModal(false);
        setCurCompanyDoctors(null);
        setClickedCompanyName(null);
        break;
      default:
        break;
    }
  };

  const checkIsReadyToConfirm = (list) => {
    return (
      list?.filter(
        (item) =>
          (item.checked && !item.alreadyAdded) ||
          (item.removed && item.alreadyAdded)
      ).length > 0
    );
  };

  const handleCopyToClipboard = async (text, event) => {
    await navigator.clipboard.writeText(text);
    const buttonRect = event.target.getBoundingClientRect();
    setShowNotificationsModal({
      state: true,
      position: {
        top: buttonRect.top,
        left: buttonRect.left,
      },
    });
  };

  const handleGenerateDocs = async (companyId) => {
    const token = localStorage.getItem("jwt");
    setDocsGeneratingCompanyId(companyId);
    try {
      await generateCompanyDocumentation(token, companyId);
      await getCompanies(token, setCompanies);
    } catch (error) {
      setMessage(error.response.data.error);
      setTimeout(() => {
        setMessage("")
      }, 1000);
    } finally {
      setDocsGeneratingCompanyId("");
    }
  };
  
  const isFetchingFavorites = apiFetchStatus.favoriteLoading;
  const isFetchingDoctors = apiFetchStatus.doctorLoading;
  const displayTableRows = filteredCompanies.map((company) => {
    return (
      <div className="company-table-row" key={company._id}>
        <div className="company-table-col manage-company">
          <button
            type="button"
            onClick={(e) => openEditCompanyModal(e, company)}
            className="companyManageButton"
          >
            Edit
          </button>

          <button
            type="button"
            onClick={(e) => handleOpenModal(e, "favorites", company)}
            className="companyManageButton"
            disabled={isFetchingFavorites}
          >
            {isFetchingFavorites ? "Loading..." : "Favorites"}
          </button>

          <button
            type="button"
            onClick={(e) => handleOpenModal(e, "doctors", company)}
            className="companyManageButton"
            disabled={isFetchingDoctors}
          >
            {isFetchingDoctors ? "Loading..." : "Doctors"}
          </button>
        </div>
        <div className="company-table-col company-name">{company.name}</div>
        <div className="company-table-col companyDocs">
          {setDoctorsList(company, true)}
        </div>
        <div className="company-table-col company-visit-types">
          <div className="visit-types-holder">
            {company.visitTypes.map((visitType, index) => {
              const separator =
                company.visitTypes.length - 1 > index ? (
                  <span>,&nbsp;</span>
                ) : (
                  <></>
                );
              return (
                <span key={visitType.type}>
                  {visitType.type}
                  {separator}
                </span>
              );
            })}
          </div>
        </div>
        <div className="company-table-col company-documentation">
          <div>
            {!company.isPlatformSubCompany && 
            <>
              {company.companyDocuments ? (
              <>
                <button onClick={(e) => handleCopyToClipboard(company.companyDocuments.apiCredentialsDoc, e)} className="companyManageButton columnLayout">
                  API Credentials Doc
                </button>
                <br />
                <button onClick={(e) => handleCopyToClipboard(company.companyDocuments.apiDocs, e)} className="companyManageButton columnLayout">
                  API Docs
                </button>
                <br />
                <button onClick={(e) => handleCopyToClipboard(company.companyDocuments.webhookDocs, e)} className="companyManageButton columnLayout">
                  Webhook Docs
                </button>
              </>
            ) : (
              <>
                <button 
                  onClick={()=>handleGenerateDocs(company._id)}
                  disabled={docsGeneratingCompanyId === company._id}
                  className="companyManageButton"
                >
                  {docsGeneratingCompanyId === company._id ? "Generating..." : "Generate Documentation"}
                </button>
              </>
            )}
          </>}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="companies-page">
      <div className="companies-header">
        <div className="companies-table-manage-bar">
          <button
            type="button"
            onClick={openCreateCompanyModal}
            className="createCompanyButton"
            style={{ margin: "10px 0" }}
          >
            <img
              src={companyIcon}
              className="createIcon"
              alt="Create Company Icon"
            />
            Create
          </button>
          <div className="companies-search-bar">
            <InputGroup className="searchInput">
              <InputGroup.Text>
                <img src={smallSearch} alt="Search Icon" />
              </InputGroup.Text>
              <FormControl
                name="searchCompany"
                placeholder="Search company"
                onChange={handleSearchChange}
                value={searchQuery}
              />
            </InputGroup>
          </div>
        </div>
        <div className="companies-table-label">
          <div className="company-table-col manage-company">
            <div className="label-text">Manage</div>
          </div>
          <div className="company-table-col company-name">
            <div className="label-text">Name</div>
          </div>
          <div className="company-table-col company-email">
            <div className="label-text">Active Doctors</div>
          </div>
          <div className="company-table-col company-visit-types">
            <div className="label-text">Visit Types</div>
          </div>
          <div className="company-table-col company-visit-types">
            <div className="label-text">Documentation</div>
          </div>
        </div>
      </div>
      <div className="companies-body">
        {companies.length > 0 && favorites.length > 0 && doctors.length > 0 ? (
          <div className="companies-table">{displayTableRows}</div>
        ) : (
          <Spinner
            style={{
              borderColor: "#eaeaea",
              position: "relative",
              marginTop: "50px",
            }}
            size="lg"
          />
        )}
      </div>
      {/* create/edit company modal*/}
      <CreateCompanyModal
        show={showCompanyModal}
        handleClose={handleCloseCompanyModal}
        curEditingCompany={curEditingCompany}
        companies={companies}
        setCompanies={props.setCompanies}
        editMode={editMode}
      />

      {/* favorites modal*/}
      <CompanyCheckboxModal
        show={showFavoritesModal}
        handleClose={() => handleCloseModal("favorites")}
        updateList={updateList}
        list={curCompanyFavorites}
        title={`${clickedCompanyName} - Favorites`}
        handleNoneCheckbox={deSelectAll}
        type="favorites"
        handleConfirm={handleFavoritesConfirm}
        loading={modalSpinner}
        errorMessage={errorMessage}
        isReadyToConfirm={checkIsReadyToConfirm(curCompanyFavorites)}
      />

      {/* doctors modal*/}
      <CompanyCheckboxModal
        show={showDoctorsModal}
        handleClose={() => handleCloseModal("doctors")}
        updateList={updateList}
        list={curCompanyDoctors}
        title={`${clickedCompanyName} - Doctors`}
        handleNoneCheckbox={deSelectAll}
        type="doctors"
        handleConfirm={handleDoctorsConfirm}
        loading={modalSpinner}
        errorMessage={errorMessage}
        isReadyToConfirm={checkIsReadyToConfirm(curCompanyDoctors)}
      />
      <SuccessNotification
        showNotificationsModal={showNotificationsModal}
        setShowNotificationsModal={setShowNotificationsModal}
      />
      {message && message.length && (
        <span className="mx-4 my-2 userFormError">Generate Documentation error: {message}</span>
      )}
    </div>
  );
};

Companies.propTypes = {
  admin: PropTypes.bool,
  user: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
};

export default Companies;

export const medsForMen = {
  viagra: {
    name: "viagra",
    dosages: [25, 50, 100],
    quantity: [5, 10, 20],
    refills: [12, 24, 36],
    directions: {
      standard:
        "Take one tablet one hour before sex as needed. Do not take more than one dose in 24 hours.",
    },
  },
  sildenafil: {
    name: "sildenafil",
    dosages: [25, 50, 100],
    quantity: [5, 10, 20],
    refills: [12, 24, 36],
    directions: {
      standard:
        "Take one tablet one hour before sex as needed. Do not take more than one dose in 24 hours.",
    },
  },
  tadalafil: {
    name: "tadalafil",
    dosages: [5, 10, 20],
    quantity: [5, 10, 20],
    refills: [12, 24, 36],
    directions: {
      low: "Take one tablet daily.",
      standard:
        "Take one tablet one hour before sex as needed. Do not take more than one dose in 24 hours.",
    },
  },
  cialis: {
    name: "cialis",
    dosages: [5, 10, 20],
    quantity: [5, 10, 20],
    refills: [12, 24, 36],
    directions: {
      low: "Take one tablet daily.",
      standard:
        "Take one tablet one hour before sex as needed. Do not take more than one dose in 24 hours.",
    },
  },
};

export const betterWeightForLife = {
  ozempic: {
    medication: "Ozempic",
    dosages: [
      ["0.25mg", "2mg/1.5ml"],
      ["0.5mg", "2mg/1.5ml"],
      ["1mg", "4mg/3ml"],
    ],
    quantity: ["1"],
    refills: ["0", "11"],
    directions: {
      standard: "{strength} subq weekly for 4 weeks",
    },
  },
};

export const hallandaleMeds = [
  {
    name: "Mitochondria Semaglutide 0.25mg",
    sku: "79UHnoNoUjVOM2HpohPsbQ3kyy3ckcYX",
  },
  {
    name: "Mitochondria Semaglutide 0.5mg",
    sku: "FPctTVG20WjhDs5Ch4ej0tY4NwrDpkvr",
  },
  {
    name: "Mitochondria Semaglutide 1mg",
    sku: "yGwu69PMlPM4sQr22W28CsEJ66BM5bzg",
  },
  {
    name: "Mitochondria Semaglutide 1.7mg",
    sku: "GCCUFhqrt7QMwWcBH05pC3FpBUItX50F",
  },
  {
    name: "Mitochondria Semaglutide 2.5mg",
    sku: "aYxGnFvlgr1gmagFdnHQhfpuLqDPSkQH",
  },
  {
    name: "Mitochondria Tirzepatide 2.5mg",
    sku: "XnQukydR14qUp4Oo3vRCH0iyr6gabOcU",
  },
  {
    name: "Mitochondria Tirzepatide 5mg",
    sku: "Hgd6lr1LRO9CoIyge0uvTpjpmtCypO53",
  },
  {
    name: "Mitochondria Tirzepatide 7.5mg",
    sku: "qkoxkrLNwJgM0yGoXiT86u8UVpKFYGlB",
  },
  {
    name: "Mitochondria Tirzepatide 10mg",
    sku: "8UgwASlV1G4TazFBNG8hyl7HD3KCoZ3y",
  },
  {
    name: "Mitochondria Tirzepatide 12.5mg",
    sku: "EBKjwP962ZZ0zHJCWZYlpJisF5zrQ7FU",
  },
  {
    name: "Mitochondria Tirzepatide 15mg",
    sku: "KThJ3GJX504f6Y9ql7wz0GevlXfsGR44",
  },
  {
    name: "Mitochondria Ozempic 0.25mg",
    sku: "6TCLeXzE2ZiQ7d2jVdBWBHzrdCOAlJsX",
  },
  {
    name: "Mitochondria Ozempic 0.5mg",
    sku: "TUvIbpvehWzmajcpljRtBOlLKOd9Ksfs",
  },
  {
    name: "Mitochondria Ozempic 1mg",
    sku: "QTjwsz8RFyGZjm0Y912v8nCRrCvmz8sl",
  },
  {
    name: "Mitochondria Ozempic 1.7mg",
    sku: "AjJEnqZZfPpFwOlXjVPjEIpfKRh2NnlK",
  },
  {
    name: "Mitochondria Ozempic 2mg",
    sku: "EZygESBXb2B4IUtZ5nZsCrQPTahmkt3b",
  },
  {
    name: "Mitochondria Wegovy 0.25mg",
    sku: "yrbqXSVX9mrMpth91OEszx7bPGi4hMXH",
  },
  {
    name: "Mitochondria Wegovy 0.5mg",
    sku: "fV5uaGx2IJ9VeQ0mxoclbYq4bgFZZSxO",
  },
  {
    name: "Mitochondria Wegovy 1mg",
    sku: "NeEPvtesfrCakHkIhTpkJcihiyZHbyoA",
  },
  {
    name: "Mitochondria Wegovy 1.7mg",
    sku: "eeSFJOtlyVrpfiKckXjnFwzEmUrtjXav",
  },
  {
    name: "Mitochondria Wegovy 2.4mg",
    sku: "DzvnUxR8LalsvMsd9YuMFkP0I58C0Aog",
  },
  {
    name: "Mitochondria Mounjaro 2.5mg",
    sku: "dDiQ5EpWuVrtInwmWMqDrYwvLMUMZpbu",
  },
  {
    name: "Mitochondria Mounjaro 5mg",
    sku: "tdU7U0kcNeTzc1Lqbnu4nyJTRckGPq5k",
  },
  {
    name: "Mitochondria Mounjaro 7.5mg",
    sku: "Yie6q5gUg2MszZLsSZ3tny3V8mqlDul3",
  },
  {
    name: "Mitochondria Mounjaro 10mg",
    sku: "TWqCUs7KxPsXwetEKdWuQfnmZ4c13XSQ",
  },
  {
    name: "Mitochondria Mounjaro 12.5mg",
    sku: "i2v09u191e6492O4aVg0dmSaiL0Ghmd9",
  },
  {
    name: "Mitochondria Mounjaro 15mg",
    sku: "OovZC6RIApK3YUojXKjfnMmun9Bhdwx1",
  },
  {
    name: "Sure Comfort Insulin Syringe Miscellaneous SINGLE USE",
    sku: "jVgR5tAzuXxhqxc5VVOYahvy80D4KfXD",
  },
];

export const baskNdcArray = [
  // Semaglutide/B12
  // // Jungle Jim's
  {
    name: "Junglejims Compounded semaglutide 0.25mg",
    sku: "00000121012",
    pharmacyId: "38580",
  },
  {
    name: "Junglejims Compounded semaglutide 0.5mg",
    sku: "00000121025",
    pharmacyId: "38580",
  },
  {
    name: "Junglejims Compounded semaglutide 1mg",
    sku: "00000251002",
    pharmacyId: "38580",
  },
  {
    name: "Junglejims Compounded semaglutide 1.75mg",
    sku: "00000251004",
    pharmacyId: "38580",
  },
  {
    name: "Junglejims Compounded semaglutide 2.5mg",
    sku: "00000151035",
    pharmacyId: "38580",
  },
  // // Precision
  {
    name: "Eden compounded Semaglutide 0.25mg",
    sku: "6df380b2-ed21-45a0-a910-11ba6afbfca2",
    pharmacyId: "265585",
  },
  {
    name: "Eden compounded Semaglutide 0.5mg",
    sku: "af4e38fb-ebc1-4eca-bf0e-26551878dc15",
    pharmacyId: "265585",
  },
  {
    name: "Eden compounded Semaglutide 1mg",
    sku: "d858e2e0-c032-4d3a-bf20-cabf01e46ade",
    pharmacyId: "265585",
  },
  {
    name: "Eden compounded Semaglutide 1.7mg",
    sku: "d37f7c41-682a-483e-8ab3-78ebbc252b63",
    pharmacyId: "265585",
  },
  {
    name: "Eden compounded Semaglutide 2.4mg",
    sku: "27efe2bf-03aa-4407-9831-fe70a60851e8",
    pharmacyId: "265585",
  },
  // Semaglutide/Methylcobalamin vials
  // // Precision
  {
    name: "Precision Semaglutide vial 0.5mg",
    sku: "5973a541-f334-4bd1-bf3c-792ee7ca200d-2",
    pharmacyId: "265585",
  },
  {
    name: "Precision Semaglutide vial 0.25mg",
    sku: "5973a541-f334-4bd1-bf3c-792ee7ca200d-1",
    pharmacyId: "265585",
  },
  {
    name: "Eden Semaglutide vial 0.5mg",
    sku: "6d235497-dcc7-41c8-8b72-dcdbeb5d00d4-2",
    pharmacyId: "265585",
  },
  {
    name: "Precision Semaglutide vial 1mg",
    sku: "6d235497-dcc7-41c8-8b72-dcdbeb5d00d4-3",
    pharmacyId: "265585",
  },
  {
    name: "Precision Semaglutide vial 1.7mg",
    sku: "e165d39c-2974-4098-a189-816836b3a5f1",
    pharmacyId: "265585",
  },
  {
    name: "Precision Semaglutide vial 2.5mg",
    sku: "f5cd42b9-910c-40de-8303-6a6daa8c2a11",
    pharmacyId: "265585",
  },
  {
    name: "UltiCare Insulin Syringe Miscellaneous 0.5ML, 30G",
    sku: "f666fb0d-0c70-41b3-855d-cb554e9190c3",
    pharmacyId: "265585",
  },
  {
    name: "Sure Comfort Insulin Syringe Miscellaneous SINGLE USE",
    sku: "ca32cf0b-c966-4e5f-8b65-3789040a3491",
    pharmacyId: "265585",
  },
  // Wegovy
  // // GGM
  {
    name: "Wegovy (0.25 mg dose) (subcutaneous - solution)",
    sku: "00169452514",
    pharmacyId: "245312",
  },
  {
    name: "Wegovy (0.5 mg dose) (subcutaneous - solution)",
    sku: "00169450514",
    pharmacyId: "245312",
  },
  {
    name: "Wegovy (1 mg dose) (subcutaneous - solution)",
    sku: "00169450114",
    pharmacyId: "245312",
  },
  {
    name: "Wegovy (1.7 mg dose) (subcutaneous - solution)",
    sku: "00169451714",
    pharmacyId: "245312",
  },
  {
    name: "Wegovy (2.4 mg dose) (subcutaneous - solution)",
    sku: "00169452414",
    pharmacyId: "245312",
  },
  // AONB
  // // Precision
  {
    name: "Meta-Trim month 1",
    sku: "489e998c-a53a-4ad9-9635-7fbb9de32891",
    pharmacyId: "265585",
  },
  {
    name: "Meta-Trim month 2",
    sku: "003ec9a8-b121-4d5f-b53b-c6fe28e48922",
    pharmacyId: "265585",
  },
  {
    name: "Meta-Trim month 3",
    sku: "e0b49f76-f275-4957-9dab-fe67589bbb9f",
    pharmacyId: "265585",
  },
  {
    name: "Inositol compound month 1",
    sku: "4c37ce2b-fad6-4135-bc71-4e8b91f771f6",
    pharmacyId: "265585",
  },
  {
    name: "Inositol compound month 2",
    sku: "8e21c965-67be-47f7-90b0-109cf39353e9",
    pharmacyId: "265585",
  },
  {
    name: "Inositol compound month 3",
    sku: "",
    pharmacyId: "265585",
  },
  // Mounjaro
  {
    name: "Mounjaro (subcutaneous - solution) 2.5 mg/0.5 mL",
    sku: "00002150680",
    pharmacyId: "",
  },
  {
    name: "Mounjaro (subcutaneous - solution) 5 mg/0.5 mL",
    sku: "00002149580",
    pharmacyId: "",
  },
  {
    name: "Mounjaro (subcutaneous - solution) 7.5 mg/0.5 mL",
    sku: "00002148480",
    pharmacyId: "",
  },
  {
    name: "Mounjaro (subcutaneous - solution) 10 mg/0.5 mL",
    sku: "00002147180",
    pharmacyId: "",
  },
  {
    name: "Mounjaro (subcutaneous - solution) 12.5 mg/0.5 mL",
    sku: "00002146080",
    pharmacyId: "",
  },
  {
    name: "Mounjaro (subcutaneous - solution) 15 mg/0.5 mL",
    sku: "00002145780",
    pharmacyId: "",
  },
  // Ozempic
  {
    name: "Ozempic 0.25mg",
    sku: "00169418113",
    pharmacyId: "",
  },
  {
    name: "Ozempic 0.5mg",
    sku: "00169418113-2",
    pharmacyId: "",
  },
  {
    name: "Ozempic 1mg",
    sku: "00169413013-3",
    pharmacyId: "",
  },
  {
    name: "Ozempic 1.7mg",
    sku: "00169477212-4",
    pharmacyId: "",
  },
  {
    name: "Ozempic 2.5mg",
    sku: "00169477212-5",
    pharmacyId: "",
  },
  // ivyRx Compounds
  // // Precision
  {
    name: "NAD Injection 200mg/mL",
    sku: "85d9ef37-1491-41c7-bc4c-da30dc526bda",
    pharmacyId: "265585",
  },
  {
    name: "Glutathione Injection 200mg/mL",
    sku: "1bec3888-2049-4c4c-8781-d422d7598045",
    pharmacyId: "265585",
  },
  {
    name: "Glutathione Nasal Spray",
    sku: "801584d0-3686-4d4d-8062-f1f2677cb69",
    pharmacyId: "265585",
  },
  {
    name: "NAD nasal spray",
    sku: "9de39c8c-4b7c-4ad9-9be2-315bf1344e59",
    pharmacyId: "265585",
  },
  {
    name: "NAD Face Cream (Monthly)",
    sku: "91d9a51e-9763-42e7-a6d6-ba2cf57c6477",
    pharmacyId: "265585",
  },
  {
    name: "NAD Face Cream (Quarterly)",
    sku: "91d9a51e-9763-42e7-a6d6-ba2cf57c6477-3",
    pharmacyId: "265585",
  },
  {
    name: "Tretinoin/Niacinamide 45g",
    sku: "54466400-bd09-4848-aed3-cf59d04afb77",
    pharmacyId: "265585",
  },
  {
    name: "Tretinoin/Niacinamide 135g",
    sku: "54466400-bd09-4848-aed3-cf59d04afb77-3",
    pharmacyId: "265585",
  },
  {
    name: "Tretinoin/Niacinamide 45g Sensitive",
    sku: "ba0ba99c-9f6a-4161-8c9e-e8edd17d1c09",
    pharmacyId: "265585",
  },
  {
    name: "Tretinoin/Niacinamide 135g Sensitive",
    sku: "ba0ba99c-9f6a-4161-8c9e-e8edd17d1c09-3",
    pharmacyId: "265585",
  },
  // // The Pharmacy Hub
  {
    name: "Ivy Tada Troche 22mg",
    sku: "21722071001",
    pharmacyId: "267201",
  },
  {
    name: "Ivy Low Tada/sild Troche",
    sku: "21722071002",
    pharmacyId: "267201",
  },
  {
    name: "Ivy High Tada/sild Troche",
    sku: "21722071003",
    pharmacyId: "267201",
  },
  {
    name: "Ivy Minoxidil/Tretinoin/Fluocinolone/Biotin/Melatonin",
    sku: "33322991001",
    pharmacyId: "267201",
  },
  // baskRemiGroup Compounds
  // // The Pharmacy Hub
  {
    name: "REMiRx topical minoxidil finasteride tretinoin for men",
    sku: "REMIRX-97609734",
    pharmacyId: "267201",
  },
  {
    name: "REMiRx topical minoxidil tretinoin for women",
    sku: "REMIRX-97609745",
    pharmacyId: "267201",
  },

  // lockLab compounds
  {
    name: "Locklab 5mg minoxidil, 1.1mg finasteride, 1mg biotin",
    sku: "006a31fa-1f49-4fce-8bc5-f16a9a584592",
    pharmacyId: "",
  },
  {
    name: "Locklab 2.5mg finasteride + 2.5mg minoxidil + 1mg biotin",
    sku: "NOVA-75346645-LL",
    pharmacyId: "",
  },
  {
    name: "Locklab 2.5mg minoxidil + 1mg biotin",
    sku: "NOVA-75346655-LL",
    pharmacyId: "",
  },

  // Nova compounds
  // // Semaglutide/B6
  {
    name: "Nova Semaglutide/B6 0.25mg",
    sku: "NOVA-1520643111-B6",
    pharmacyId: "269229",
  },
  {
    name: "Nova Semaglutide/B6 0.5mg",
    sku: "NOVA-1520643122-B6",
    pharmacyId: "269229",
  },
  {
    name: "Nova Semaglutide/B6 1mg",
    sku: "NOVA-1520643133-B6",
    pharmacyId: "269229",
  },
  {
    name: "Nova Semaglutide/B6 1.7mg",
    sku: "NOVA-1520643144-B6",
    pharmacyId: "269229",
  },
  {
    name: "Nova Semaglutide/B6 2.4mg",
    sku: "NOVA-1520643155-B6",
    pharmacyId: "269229",
  },
  {
    name: "Nova Semaglutide/B6 0.25mg, 0.5mg and 1mg (3 Month Supply)",
    sku: "NOVA-1520643144-QB6",
    pharmacyId: "269229",
  },
  // // B12
  {
    name: "Vitamin b12 injection",
    sku: "NOVA-1020198000-B12",
    pharmacyId: "269229",
  },
  {
    name: "Lipotropic/b12 injection",
    sku: "NOVA-1020198111-B12",
    pharmacyId: "269229",
  },
  // Tirzepatide
  {
    name: "Red Rock Tirzepatide 2.5mg",
    sku: "374784-50011",
    pharmacyId: "",
  },
  {
    name: "Red Rock Tirzepatide 5mg",
    sku: "374784-50012",
    pharmacyId: "",
  },
  {
    name: "Red Rock Tirzepatide 7.5mg",
    sku: "374784-50013",
    pharmacyId: "",
  },
  {
    name: "Red Rock Tirzepatide 10mg",
    sku: "374784-50014",
    pharmacyId: "",
  },
  {
    name: "Red Rock Tirzepatide 12.5mg",
    sku: "374784-50015",
    pharmacyId: "",
  },
  {
    name: "Red Rock Tirzepatide 15mg",
    sku: "374784-50016",
    pharmacyId: "",
  },
];

import { routerMap } from "./router";

const { updateDocTime } = routerMap;

/**
 * @param {object} user - the user object to decide whether this is an admin user
 * @param {boolean} isAdminMode - whether the current user is operating in admin mode (by checking
 *                                the admin checkbox)
 * @param {string} token - jwt token to call backend with
 */
export const trackAdminTimeOnly = async (user, isAdminMode, token) => {
  try {
    if (isAdminMode && user.admin) {
      await updateDocTime(token);
    }
  } catch (error) {
    console.error("Time Tracking Error for admin", error);
  }
};

const detailsResponse = {
  status: 200,
  data: {
    notifications: {
      RefillRequestsCount: 7,
      TransactionErrorsCount: 24,
      PendingPrescriptionsCount: 34,
      PendingRxChangeCount: 9,
      Result: {
        ResultCode: "OK",
        ResultDescription: "",
      },
    },
    rxChange: {
      Items: [
        {
          RxChangeRequestId: 221782,
          RxChangeType: "Therapeutic Interchange",
          RxChangeSubType: null,
          NewPatient: false,
          Patient: {
            PatientId: 21052726,
            Prefix: null,
            FirstName: "Rowena",
            MiddleName: "Baylie",
            LastName: "Acacianna",
            Suffix: null,
            DateOfBirth: "1968-03-29T00:00:00",
            Gender: 2,
            Email: null,
            Address1: "2798 Parsifal St NE",
            Address2: null,
            City: "Albuquerque",
            State: "New Mexico",
            ZipCode: "87112",
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditionalType1: 0,
            PhoneAdditionalType2: 0,
            PrimaryPhone: "5052936547",
            PrimaryPhoneType: 7,
            Weight: null,
            WeightMetric: 0,
            Height: null,
            HeightMetric: 0,
            NonDoseSpotMedicalRecordNumber: null,
            Active: true,
            Encounter: null,
            IsHospice: false,
          },
          UnmatchedChange: false,
          PrescribedMedication: {
            PrescriptionId: 59308956,
            WrittenDate: "2023-09-29T14:54:50.817",
            Directions: "Take 1 tablet by mouth once per day for 30 days",
            Quantity: "30",
            DispenseUnitId: 26,
            DispenseUnitDescription: "Tablet(s)",
            Refills: "1",
            DaysSupply: 30,
            PharmacyId: 14973,
            PharmacyNotes: null,
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: null,
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: false,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 177637,
                DiagnosisCode: "I10",
                DiagnosisDescription: "Essential (primary) hypertension",
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63467645,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "tablet",
            Route: "oral",
            Strength: "20 mg",
            GenericProductName: "lisinopril 20 mg oral tablet",
            GenericDrugName: "lisinopril",
            LexiGenProductId: 1950,
            LexiDrugSynId: 16551,
            LexiSynonymTypeId: 60,
            LexiGenDrugId: "d00732",
            RxCUI: "104378",
            OTC: false,
            NDC: "52427044190",
            Schedule: "0",
            DisplayName: "Zestril 20 mg tablet",
            MonographPath: "HTMLICONS/d00732a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: true,
            CompoundIngredients: null,
          },
          RequestedMedications: [
            {
              PrescriptionId: 59308957,
              WrittenDate: "2023-09-29T14:54:50.817",
              Directions: "Take 1 tablet by mouth once per day for 30 days",
              Quantity: "30",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63467646,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet",
              Route: "oral",
              Strength: "20 mg",
              GenericProductName: "lisinopril 20 mg oral tablet",
              GenericDrugName: "lisinopril",
              LexiGenProductId: 1950,
              LexiDrugSynId: 18389,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d00732",
              RxCUI: "314077",
              OTC: false,
              NDC: "00143971301",
              Schedule: "0",
              DisplayName: "Lisinopril 20 mg tablet",
              MonographPath: "HTMLICONS/d00732a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
            {
              PrescriptionId: 59308958,
              WrittenDate: "2019-01-01T00:00:00",
              Directions: "Take 2 tablets by mouth once per day for 30 days",
              Quantity: "60",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63467647,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet",
              Route: "oral",
              Strength: "10 mg",
              GenericProductName: "lisinopril 10 mg oral tablet",
              GenericDrugName: "lisinopril",
              LexiGenProductId: 1949,
              LexiDrugSynId: 18388,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d00732",
              RxCUI: "314076",
              OTC: false,
              NDC: "00143971401",
              Schedule: "0",
              DisplayName: "Lisinopril 10 mg tablet",
              MonographPath: "HTMLICONS/d00732a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
            {
              PrescriptionId: 59308959,
              WrittenDate: "2019-01-01T00:00:00",
              Directions: "Take 1 tablet by mouth once per day for 30 days",
              Quantity: "30",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63467648,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet",
              Route: "oral",
              Strength: "25 mg",
              GenericProductName: "captopril 25 mg oral tablet",
              GenericDrugName: "captopril",
              LexiGenProductId: 465,
              LexiDrugSynId: 17741,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d00006",
              RxCUI: "317173",
              OTC: false,
              NDC: "00143117201",
              Schedule: "0",
              DisplayName: "Captopril 25 mg tablet",
              MonographPath: "HTMLICONS/d00006a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
            {
              PrescriptionId: 59308960,
              WrittenDate: "2019-01-01T00:00:00",
              Directions: "Take 1 tablet by mouth once per day for 30 days",
              Quantity: "30",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63467649,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet",
              Route: "oral",
              Strength: "40 mg",
              GenericProductName: "benazepril 40 mg oral tablet",
              GenericDrugName: "benazepril",
              LexiGenProductId: 276,
              LexiDrugSynId: 18263,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d00730",
              RxCUI: "898719",
              OTC: false,
              NDC: "23155075201",
              Schedule: "0",
              DisplayName: "Benazepril 40 mg tablet",
              MonographPath: "HTMLICONS/d00730a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
            {
              PrescriptionId: 59308961,
              WrittenDate: "2019-01-01T00:00:00",
              Directions: "Take 1 tablet by mouth once per day for 30 days",
              Quantity: "30",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63467650,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet",
              Route: "oral",
              Strength: "20 mg",
              GenericProductName: "fosinopril 20 mg oral tablet",
              GenericDrugName: "fosinopril",
              LexiGenProductId: 1225,
              LexiDrugSynId: 18341,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d00242",
              RxCUI: "857183",
              OTC: false,
              NDC: "31722020110",
              Schedule: "0",
              DisplayName: "Fosinopril 20 mg tablet",
              MonographPath: "HTMLICONS/d00242a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
            {
              PrescriptionId: 59308962,
              WrittenDate: "2019-01-01T00:00:00",
              Directions: "Take 1 tablet by mouth once per day for 30 days",
              Quantity: "30",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63467651,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet",
              Route: "oral",
              Strength: "20 mg",
              GenericProductName: "quinapril 20 mg oral tablet",
              GenericDrugName: "quinapril",
              LexiGenProductId: 2869,
              LexiDrugSynId: 17895,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d00365",
              RxCUI: "312749",
              OTC: false,
              NDC: "31722026990",
              Schedule: "0",
              DisplayName: "Quinapril 20 mg tablet",
              MonographPath: "HTMLICONS/d00365a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
            {
              PrescriptionId: 59308963,
              WrittenDate: "2019-01-01T00:00:00",
              Directions: "Take 2 tablets by mouth once per day for 30 days",
              Quantity: "60",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 2,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63467652,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: null,
              Route: null,
              Strength: null,
              GenericProductName: "PERINDOPRIL ERBUMINE 8 MG TAB",
              GenericDrugName: "PERINDOPRIL ERBUMINE 8 MG TAB",
              LexiGenProductId: 0,
              LexiDrugSynId: 0,
              LexiSynonymTypeId: 0,
              LexiGenDrugId: null,
              RxCUI: "0",
              OTC: false,
              NDC: null,
              Schedule: null,
              DisplayName: "PERINDOPRIL ERBUMINE 8 MG TAB",
              MonographPath: null,
              DrugClassification: null,
              StateSchedules: null,
              Brand: true,
              CompoundIngredients: null,
            },
            {
              PrescriptionId: 59308964,
              WrittenDate: "2019-01-01T00:00:00",
              Directions: "Take 2 tablets by mouth once per day for 30 days",
              Quantity: "60",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63467653,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "capsule",
              Route: "oral",
              Strength: "10 mg",
              GenericProductName: "ramipril 10 mg oral capsule",
              GenericDrugName: "ramipril",
              LexiGenProductId: 2474,
              LexiDrugSynId: 31659,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d00728",
              RxCUI: "261962",
              OTC: false,
              NDC: "00054010925",
              Schedule: "0",
              DisplayName: "Ramipril 10 mg capsule",
              MonographPath: "HTMLICONS/d00728a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
            {
              PrescriptionId: 59308965,
              WrittenDate: "2019-01-01T00:00:00",
              Directions: "Take 2 tablets by mouth once per day for 30 days",
              Quantity: "60",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63467654,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet",
              Route: "oral",
              Strength: "15 mg",
              GenericProductName: "moexipril 15 mg oral tablet",
              GenericDrugName: "moexipril",
              LexiGenProductId: 162,
              LexiDrugSynId: 17849,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d03835",
              RxCUI: "1299896",
              OTC: false,
              NDC: "00093515001",
              Schedule: "0",
              DisplayName: "Moexipril 15 mg tablet",
              MonographPath: "HTMLICONS/d03835a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
          ],
          PrescribedMedicationForRxchange: null,
          RequestedMedicationsForRxchange: null,
          Clinician: {
            ClinicianId: 194644,
            Prefix: null,
            FirstName: "Test",
            MiddleName: null,
            LastName: "User1",
            Suffix: null,
            DateOfBirth: "1927-09-07T00:00:00",
            Email: null,
            Address1: "980 Washington St",
            Address2: "#330",
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "8888476814",
            PrimaryPhoneType: 7,
            PrimaryFax: "8888476814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            DEANumbers: [
              {
                DEANumber: "",
                State: "All(default)",
                ClinicId: null,
              },
            ],
            DEANumber: "",
            NADEANumbers: [],
            MedicalLicenseNumbers: [],
            NPINumber: "1839267899",
            Roles: [1],
            PDMPRoleType: null,
            SpecialtyTypeId: null,
            Confirmed: true,
            Active: true,
            AccountLocked: false,
            EpcsRequested: true,
            ClinicInfo: [
              {
                ClinicId: 25808,
                HasNewRx: true,
                HasRefills: true,
                HasRxChange: true,
                HasCancel: true,
                HasRxFill: true,
                HasEpcs: false,
              },
            ],
          },
          Clinic: {
            ClinicId: 25808,
            ClinicName: "Beluga Health",
            ClinicNameLongForm: "TravelMD",
            Address1: "980 Washington St",
            Address2: "#330",
            Active: true,
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "(888) 847-6814",
            PrimaryPhoneType: 5,
            PrimaryFax: "(888) 847-6814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
          },
          Pharmacy: {
            PharmacyId: 14973,
            StoreName: "Shollenberger Pharmacy",
            Address1: "2002 S. McDowell Blvd Ext",
            Address2: "",
            City: "Petaluma",
            State: "CA",
            ZipCode: "94954",
            PrimaryPhone: "7079845571",
            PrimaryPhoneType: 5,
            PrimaryFax: "7079884744",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            PharmacySpecialties: [
              "Long-Term Care Pharmacy",
              "Retail",
              "Specialty Pharmacy",
              "EPCS",
            ],
            ServiceLevel: 2079,
            Latitude: 38.229492,
            Longitude: -122.595968,
          },
          RequestedDate: "2023-09-29T14:56:01.477",
          OriginalPrescriptionId: 59308145,
          PayerName: "PBMX",
          DrugUseEvaluations: [],
        },
        {
          RxChangeRequestId: 221783,
          RxChangeType: "Drug Use Evaluation",
          RxChangeSubType: null,
          NewPatient: false,
          Patient: {
            PatientId: 21328537,
            Prefix: null,
            FirstName: "Zachary",
            MiddleName: null,
            LastName: "Delaplaine",
            Suffix: null,
            DateOfBirth: "2010-12-01T00:00:00",
            Gender: 1,
            Email: null,
            Address1: "901 Sauvblanc Blvd",
            Address2: null,
            City: "Petaluma",
            State: "California",
            ZipCode: "94952",
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditionalType1: 0,
            PhoneAdditionalType2: 0,
            PrimaryPhone: "9508450398",
            PrimaryPhoneType: 7,
            Weight: null,
            WeightMetric: 0,
            Height: null,
            HeightMetric: 0,
            NonDoseSpotMedicalRecordNumber: null,
            Active: true,
            Encounter: null,
            IsHospice: false,
          },
          UnmatchedChange: true,
          PrescribedMedication: {
            PrescriptionId: 59309020,
            WrittenDate: "2023-09-29T14:58:26.693",
            Directions:
              "Instill 1 drop into affected eye every 6 hours for 7 days",
            Quantity: "10",
            DispenseUnitId: 15,
            DispenseUnitDescription: "Milliliter(s)",
            Refills: "1",
            DaysSupply: null,
            PharmacyId: 14973,
            PharmacyNotes: null,
            NoSubstitutions: false,
            EffectiveDate: null,
            LastFillDate: null,
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: false,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 174957,
                DiagnosisCode: "H10502",
                DiagnosisDescription:
                  "Unspecified blepharoconjunctivitis, left eye",
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63467710,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "suspension",
            Route: "ophthalmic",
            Strength: "0.3%-0.1%",
            GenericProductName:
              "dexamethasone-tobramycin ophthalmic 0.3%-0.1% suspension",
            GenericDrugName: "dexamethasone-tobramycin ophthalmic",
            LexiGenProductId: 3428,
            LexiDrugSynId: 28242,
            LexiSynonymTypeId: 59,
            LexiGenDrugId: "d03536",
            RxCUI: "309683",
            OTC: false,
            NDC: "24208029505",
            Schedule: "0",
            DisplayName:
              "Tobramycin-Dexamethasone Ophthalmic 0.3%-0.1% suspension",
            MonographPath: "HTMLICONS/d03536a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: false,
            CompoundIngredients: null,
          },
          RequestedMedications: [
            {
              PrescriptionId: 59309021,
              WrittenDate: "2023-09-29T14:58:26.693",
              Directions:
                "Instill 1 drop into affected eye every 4 hours for 7 days",
              Quantity: "10",
              DispenseUnitId: 15,
              DispenseUnitDescription: "Milliliter(s)",
              Refills: "1",
              DaysSupply: null,
              PharmacyId: 14973,
              PharmacyNotes: null,
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63467711,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "suspension",
              Route: "ophthalmic",
              Strength: "0.3%-0.1%",
              GenericProductName:
                "dexamethasone-tobramycin ophthalmic 0.3%-0.1% suspension",
              GenericDrugName: "dexamethasone-tobramycin ophthalmic",
              LexiGenProductId: 3428,
              LexiDrugSynId: 28242,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d03536",
              RxCUI: "309683",
              OTC: false,
              NDC: "24208029505",
              Schedule: "0",
              DisplayName:
                "Tobramycin-Dexamethasone Ophthalmic 0.3%-0.1% suspension",
              MonographPath: "HTMLICONS/d03536a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
          ],
          PrescribedMedicationForRxchange: null,
          RequestedMedicationsForRxchange: null,
          Clinician: {
            ClinicianId: 194644,
            Prefix: null,
            FirstName: "Test",
            MiddleName: null,
            LastName: "User1",
            Suffix: null,
            DateOfBirth: "1927-09-07T00:00:00",
            Email: null,
            Address1: "980 Washington St",
            Address2: "#330",
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "8888476814",
            PrimaryPhoneType: 7,
            PrimaryFax: "8888476814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            DEANumbers: [
              {
                DEANumber: "",
                State: "All(default)",
                ClinicId: null,
              },
            ],
            DEANumber: "",
            NADEANumbers: [],
            MedicalLicenseNumbers: [],
            NPINumber: "1839267899",
            Roles: [1],
            PDMPRoleType: null,
            SpecialtyTypeId: null,
            Confirmed: true,
            Active: true,
            AccountLocked: false,
            EpcsRequested: true,
            ClinicInfo: [
              {
                ClinicId: 25808,
                HasNewRx: true,
                HasRefills: true,
                HasRxChange: true,
                HasCancel: true,
                HasRxFill: true,
                HasEpcs: false,
              },
            ],
          },
          Clinic: {
            ClinicId: 25808,
            ClinicName: "Beluga Health",
            ClinicNameLongForm: "TravelMD",
            Address1: "980 Washington St",
            Address2: "#330",
            Active: true,
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "(888) 847-6814",
            PrimaryPhoneType: 5,
            PrimaryFax: "(888) 847-6814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
          },
          Pharmacy: {
            PharmacyId: 14973,
            StoreName: "Shollenberger Pharmacy",
            Address1: "2002 S. McDowell Blvd Ext",
            Address2: "",
            City: "Petaluma",
            State: "CA",
            ZipCode: "94954",
            PrimaryPhone: "7079845571",
            PrimaryPhoneType: 5,
            PrimaryFax: "7079884744",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            PharmacySpecialties: [
              "Long-Term Care Pharmacy",
              "Retail",
              "Specialty Pharmacy",
              "EPCS",
            ],
            ServiceLevel: 2079,
            Latitude: 38.229492,
            Longitude: -122.595968,
          },
          RequestedDate: "2023-09-29T14:58:49.717",
          OriginalPrescriptionId: null,
          PayerName: "PBMF",
          DrugUseEvaluations: [],
        },
        {
          RxChangeRequestId: 221784,
          RxChangeType: "Out Of Stock",
          RxChangeSubType: null,
          NewPatient: true,
          Patient: {
            PatientId: 21336818,
            Prefix: null,
            FirstName: "Margaret Adelia",
            MiddleName: "Beryl",
            LastName: "Devereaux",
            Suffix: null,
            DateOfBirth: "1997-11-01T00:00:00",
            Gender: 2,
            Email: "Adelia.D@yahoo.com",
            Address1: "27-B Heald St",
            Address2: null,
            City: "Pepperell",
            State: "Massachusetts",
            ZipCode: "01463",
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditionalType1: 0,
            PhoneAdditionalType2: 0,
            PrimaryPhone: "6176884642",
            PrimaryPhoneType: 7,
            Weight: null,
            WeightMetric: 0,
            Height: null,
            HeightMetric: 0,
            NonDoseSpotMedicalRecordNumber: null,
            Active: true,
            Encounter: null,
            IsHospice: false,
          },
          UnmatchedChange: false,
          PrescribedMedication: {
            PrescriptionId: 59309023,
            WrittenDate: "2023-09-29T14:59:26.473",
            Directions: "Take 1 tablet orally once per day for 30 days",
            Quantity: "30",
            DispenseUnitId: 26,
            DispenseUnitDescription: "Tablet(s)",
            Refills: "1",
            DaysSupply: null,
            PharmacyId: 14973,
            PharmacyNotes: null,
            NoSubstitutions: false,
            EffectiveDate: null,
            LastFillDate: null,
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: false,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 177637,
                DiagnosisCode: "I10",
                DiagnosisDescription: "Essential (primary) hypertension",
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63467713,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "tablet",
            Route: "oral",
            Strength: "50 mg",
            GenericProductName: "atenolol 50 mg oral tablet",
            GenericDrugName: "atenolol",
            LexiGenProductId: 538,
            LexiDrugSynId: 17101,
            LexiSynonymTypeId: 59,
            LexiGenDrugId: "d00004",
            RxCUI: "197381",
            OTC: false,
            NDC: "00093075201",
            Schedule: "0",
            DisplayName: "Atenolol 50 mg tablet",
            MonographPath: "HTMLICONS/d00004a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: false,
            CompoundIngredients: null,
          },
          RequestedMedications: [
            {
              PrescriptionId: 59309024,
              WrittenDate: "2023-09-29T14:59:26.473",
              Directions: "Take 1 tablet orally once per day for 30 days",
              Quantity: "30",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: null,
              PharmacyId: 14973,
              PharmacyNotes: null,
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63467714,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet, extended release",
              Route: "oral",
              Strength: "100 mg",
              GenericProductName:
                "metoprolol 100 mg oral tablet, extended release",
              GenericDrugName: "metoprolol",
              LexiGenProductId: 2392,
              LexiDrugSynId: 119048,
              LexiSynonymTypeId: 60,
              LexiGenDrugId: "d00134",
              RxCUI: "866412",
              OTC: false,
              NDC: "00378459710",
              Schedule: "0",
              DisplayName:
                "Metoprolol Succinate ER 100 mg tablet, extended release",
              MonographPath: "HTMLICONS/d00134a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
          ],
          PrescribedMedicationForRxchange: null,
          RequestedMedicationsForRxchange: null,
          Clinician: {
            ClinicianId: 194644,
            Prefix: null,
            FirstName: "Test",
            MiddleName: null,
            LastName: "User1",
            Suffix: null,
            DateOfBirth: "1927-09-07T00:00:00",
            Email: null,
            Address1: "980 Washington St",
            Address2: "#330",
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "8888476814",
            PrimaryPhoneType: 7,
            PrimaryFax: "8888476814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            DEANumbers: [
              {
                DEANumber: "",
                State: "All(default)",
                ClinicId: null,
              },
            ],
            DEANumber: "",
            NADEANumbers: [],
            MedicalLicenseNumbers: [],
            NPINumber: "1839267899",
            Roles: [1],
            PDMPRoleType: null,
            SpecialtyTypeId: null,
            Confirmed: true,
            Active: true,
            AccountLocked: false,
            EpcsRequested: true,
            ClinicInfo: [
              {
                ClinicId: 25808,
                HasNewRx: true,
                HasRefills: true,
                HasRxChange: true,
                HasCancel: true,
                HasRxFill: true,
                HasEpcs: false,
              },
            ],
          },
          Clinic: {
            ClinicId: 25808,
            ClinicName: "Beluga Health",
            ClinicNameLongForm: "TravelMD",
            Address1: "980 Washington St",
            Address2: "#330",
            Active: true,
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "(888) 847-6814",
            PrimaryPhoneType: 5,
            PrimaryFax: "(888) 847-6814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
          },
          Pharmacy: {
            PharmacyId: 14973,
            StoreName: "Shollenberger Pharmacy",
            Address1: "2002 S. McDowell Blvd Ext",
            Address2: "",
            City: "Petaluma",
            State: "CA",
            ZipCode: "94954",
            PrimaryPhone: "7079845571",
            PrimaryPhoneType: 5,
            PrimaryFax: "7079884744",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            PharmacySpecialties: [
              "Long-Term Care Pharmacy",
              "Retail",
              "Specialty Pharmacy",
              "EPCS",
            ],
            ServiceLevel: 2079,
            Latitude: 38.229492,
            Longitude: -122.595968,
          },
          RequestedDate: "2023-09-29T14:59:34.08",
          OriginalPrescriptionId: 59308195,
          PayerName: "ProInforma PBM",
          DrugUseEvaluations: [],
        },
        {
          RxChangeRequestId: 221818,
          RxChangeType: "Therapeutic Interchange",
          RxChangeSubType: null,
          NewPatient: true,
          Patient: {
            PatientId: 21352182,
            Prefix: null,
            FirstName: "Angelyne",
            MiddleName: null,
            LastName: "Delaplaine",
            Suffix: null,
            DateOfBirth: "2012-09-01T00:00:00",
            Gender: 2,
            Email: null,
            Address1: "901 Sauvblanc Blvd",
            Address2: null,
            City: "Petaluma",
            State: "Virginia",
            ZipCode: "94952",
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditionalType1: 0,
            PhoneAdditionalType2: 0,
            PrimaryPhone: null,
            PrimaryPhoneType: 0,
            Weight: null,
            WeightMetric: 0,
            Height: null,
            HeightMetric: 0,
            NonDoseSpotMedicalRecordNumber: null,
            Active: true,
            Encounter: null,
            IsHospice: false,
          },
          UnmatchedChange: true,
          PrescribedMedication: {
            PrescriptionId: 59314179,
            WrittenDate: "2023-10-04T15:06:10.983",
            Directions:
              "Take 2 ml by mouth twice daily for 1 week, then take 4 ml by mouth twice daily for 1 week, then take 6 ml by mouth twice daily for 1 week, then take 8 ml by mouth twice daily for 2 weeks",
            Quantity: "400",
            DispenseUnitId: 15,
            DispenseUnitDescription: "Milliliter(s)",
            Refills: "0",
            DaysSupply: null,
            PharmacyId: 14973,
            PharmacyNotes: null,
            NoSubstitutions: false,
            EffectiveDate: null,
            LastFillDate: null,
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: false,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 242764,
                DiagnosisCode: "G4000",
                DiagnosisDescription:
                  "Localization-related (focal) (partial) idiopathic epilepsy and epileptic syndromes with seizures of localized onset, not intractable",
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63473832,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "solution",
            Route: "oral",
            Strength: "10 mg/mL",
            GenericProductName: "lacosamide 10 mg/mL oral solution",
            GenericDrugName: "lacosamide",
            LexiGenProductId: 26289,
            LexiDrugSynId: 173375,
            LexiSynonymTypeId: 60,
            LexiGenDrugId: "d07349",
            RxCUI: "995156",
            OTC: false,
            NDC: "00131541072",
            Schedule: "5",
            DisplayName: "Vimpat 10 mg/mL solution",
            MonographPath: "HTMLICONS/d07349a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: true,
            CompoundIngredients: null,
          },
          RequestedMedications: [
            {
              PrescriptionId: 59314180,
              WrittenDate: "2023-10-04T15:06:10.983",
              Directions:
                "Take 0.5 tablet by mouth twice daily for 1 week, then take 1 tablet by mouth twice daily for 1 week, then take 1.5 tablets by mouth twice daily for 1 week, then take 2 tablets by mouth twice daily for 2 weeks",
              Quantity: "100",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "0",
              DaysSupply: null,
              PharmacyId: 14973,
              PharmacyNotes:
                "Suggested change to tablet as solution is not recommended for pediatric patient.",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63473833,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet",
              Route: "oral",
              Strength: "50 mg",
              GenericProductName: "lacosamide 50 mg oral tablet",
              GenericDrugName: "lacosamide",
              LexiGenProductId: 24619,
              LexiDrugSynId: 158548,
              LexiSynonymTypeId: 60,
              LexiGenDrugId: "d07349",
              RxCUI: "810002",
              OTC: false,
              NDC: "00131247735",
              Schedule: "5",
              DisplayName: "Vimpat 50 mg tablet",
              MonographPath: "HTMLICONS/d07349a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: true,
              CompoundIngredients: null,
            },
          ],
          PrescribedMedicationForRxchange: null,
          RequestedMedicationsForRxchange: null,
          Clinician: {
            ClinicianId: 194644,
            Prefix: null,
            FirstName: "Test",
            MiddleName: null,
            LastName: "User1",
            Suffix: null,
            DateOfBirth: "1927-09-07T00:00:00",
            Email: null,
            Address1: "980 Washington St",
            Address2: "#330",
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "8888476814",
            PrimaryPhoneType: 7,
            PrimaryFax: "8888476814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            DEANumbers: [
              {
                DEANumber: "",
                State: "All(default)",
                ClinicId: null,
              },
            ],
            DEANumber: "",
            NADEANumbers: [],
            MedicalLicenseNumbers: [],
            NPINumber: "1839267899",
            Roles: [1],
            PDMPRoleType: null,
            SpecialtyTypeId: null,
            Confirmed: true,
            Active: true,
            AccountLocked: false,
            EpcsRequested: true,
            ClinicInfo: [
              {
                ClinicId: 25808,
                HasNewRx: true,
                HasRefills: true,
                HasRxChange: true,
                HasCancel: true,
                HasRxFill: true,
                HasEpcs: false,
              },
            ],
          },
          Clinic: {
            ClinicId: 25808,
            ClinicName: "Beluga Health",
            ClinicNameLongForm: "TravelMD",
            Address1: "980 Washington St",
            Address2: "#330",
            Active: true,
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "(888) 847-6814",
            PrimaryPhoneType: 5,
            PrimaryFax: "(888) 847-6814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
          },
          Pharmacy: {
            PharmacyId: 14973,
            StoreName: "Shollenberger Pharmacy",
            Address1: "2002 S. McDowell Blvd Ext",
            Address2: "",
            City: "Petaluma",
            State: "CA",
            ZipCode: "94954",
            PrimaryPhone: "7079845571",
            PrimaryPhoneType: 5,
            PrimaryFax: "7079884744",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            PharmacySpecialties: [
              "Long-Term Care Pharmacy",
              "Retail",
              "Specialty Pharmacy",
              "EPCS",
            ],
            ServiceLevel: 2079,
            Latitude: 38.229492,
            Longitude: -122.595968,
          },
          RequestedDate: "2023-10-04T15:06:32.413",
          OriginalPrescriptionId: null,
          PayerName: "PBMA",
          DrugUseEvaluations: [],
        },
        {
          RxChangeRequestId: 221825,
          RxChangeType: "Generic Substitution",
          RxChangeSubType: null,
          NewPatient: false,
          Patient: {
            PatientId: 21328714,
            Prefix: null,
            FirstName: "Ricardo",
            MiddleName: "P.",
            LastName: "Montevideo",
            Suffix: null,
            DateOfBirth: "1998-01-29T00:00:00",
            Gender: 1,
            Email: "tran+ricardo@belugahealth.com",
            Address1: "125 Blue Jay Ln",
            Address2: null,
            City: "Pittsville",
            State: "Virginia",
            ZipCode: "241390126",
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditionalType1: 0,
            PhoneAdditionalType2: 0,
            PrimaryPhone: "9162221111",
            PrimaryPhoneType: 7,
            Weight: null,
            WeightMetric: 0,
            Height: null,
            HeightMetric: 0,
            NonDoseSpotMedicalRecordNumber: null,
            Active: true,
            Encounter: null,
            IsHospice: false,
          },
          UnmatchedChange: false,
          PrescribedMedication: {
            PrescriptionId: 59314517,
            WrittenDate: "2023-10-04T19:06:57.827",
            Directions: "Take 1 capsule orally every 12 hours for 10 days",
            Quantity: "20",
            DispenseUnitId: 4,
            DispenseUnitDescription: "Capsule(s)",
            Refills: "3",
            DaysSupply: null,
            PharmacyId: 14973,
            PharmacyNotes: null,
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: null,
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: false,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 187627,
                DiagnosisCode: "N390",
                DiagnosisDescription:
                  "Urinary tract infection, site not specified",
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63474218,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "capsule",
            Route: "oral",
            Strength: "100 mg",
            GenericProductName: "doxycycline 100 mg oral capsule",
            GenericDrugName: "doxycycline",
            LexiGenProductId: 1149,
            LexiDrugSynId: 22942,
            LexiSynonymTypeId: 60,
            LexiGenDrugId: "d00037",
            RxCUI: "205604",
            OTC: false,
            NDC: "00069095050",
            Schedule: "0",
            DisplayName: "Vibramycin 100 mg capsule",
            MonographPath: "HTMLICONS/d00037a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: true,
            CompoundIngredients: null,
          },
          RequestedMedications: [
            {
              PrescriptionId: 59314518,
              WrittenDate: "2023-10-04T19:06:57.827",
              Directions: "Take 1 capsule orally every 12 hours for 10 days",
              Quantity: "20",
              DispenseUnitId: 4,
              DispenseUnitDescription: "Capsule(s)",
              Refills: "3",
              DaysSupply: null,
              PharmacyId: 14973,
              PharmacyNotes: null,
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63474219,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "capsule",
              Route: "oral",
              Strength: "100 mg",
              GenericProductName: "doxycycline 100 mg oral capsule",
              GenericDrugName: "doxycycline",
              LexiGenProductId: 1149,
              LexiDrugSynId: 23407,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d00037",
              RxCUI: "1649988",
              OTC: false,
              NDC: "00143314205",
              Schedule: "0",
              DisplayName: "Doxycycline Hyclate 100 mg capsule",
              MonographPath: "HTMLICONS/d00037a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
          ],
          PrescribedMedicationForRxchange: null,
          RequestedMedicationsForRxchange: null,
          Clinician: {
            ClinicianId: 194644,
            Prefix: null,
            FirstName: "Test",
            MiddleName: null,
            LastName: "User1",
            Suffix: null,
            DateOfBirth: "1927-09-07T00:00:00",
            Email: null,
            Address1: "980 Washington St",
            Address2: "#330",
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "8888476814",
            PrimaryPhoneType: 7,
            PrimaryFax: "8888476814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            DEANumbers: [
              {
                DEANumber: "",
                State: "All(default)",
                ClinicId: null,
              },
            ],
            DEANumber: "",
            NADEANumbers: [],
            MedicalLicenseNumbers: [],
            NPINumber: "1839267899",
            Roles: [1],
            PDMPRoleType: null,
            SpecialtyTypeId: null,
            Confirmed: true,
            Active: true,
            AccountLocked: false,
            EpcsRequested: true,
            ClinicInfo: [
              {
                ClinicId: 25808,
                HasNewRx: true,
                HasRefills: true,
                HasRxChange: true,
                HasCancel: true,
                HasRxFill: true,
                HasEpcs: false,
              },
            ],
          },
          Clinic: {
            ClinicId: 25808,
            ClinicName: "Beluga Health",
            ClinicNameLongForm: "TravelMD",
            Address1: "980 Washington St",
            Address2: "#330",
            Active: true,
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "(888) 847-6814",
            PrimaryPhoneType: 5,
            PrimaryFax: "(888) 847-6814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
          },
          Pharmacy: {
            PharmacyId: 14973,
            StoreName: "Shollenberger Pharmacy",
            Address1: "2002 S. McDowell Blvd Ext",
            Address2: "",
            City: "Petaluma",
            State: "CA",
            ZipCode: "94954",
            PrimaryPhone: "7079845571",
            PrimaryPhoneType: 5,
            PrimaryFax: "7079884744",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            PharmacySpecialties: [
              "Long-Term Care Pharmacy",
              "Retail",
              "Specialty Pharmacy",
              "EPCS",
            ],
            ServiceLevel: 2079,
            Latitude: 38.229492,
            Longitude: -122.595968,
          },
          RequestedDate: "2023-10-04T19:07:02.27",
          OriginalPrescriptionId: 59308140,
          PayerName: null,
          DrugUseEvaluations: [],
        },
        {
          RxChangeRequestId: 221826,
          RxChangeType: "Prior Authorization",
          RxChangeSubType: null,
          NewPatient: false,
          Patient: {
            PatientId: 21328728,
            Prefix: null,
            FirstName: "Rodger",
            MiddleName: null,
            LastName: "Witbrace",
            Suffix: null,
            DateOfBirth: "1982-06-29T00:00:00",
            Gender: 1,
            Email: "tran+rodger@belugahealth.com",
            Address1: "1410 Hanaford Ave",
            Address2: null,
            City: "Bismarck",
            State: "North Dakota",
            ZipCode: "58501",
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditionalType1: 0,
            PhoneAdditionalType2: 0,
            PrimaryPhone: "9162221122",
            PrimaryPhoneType: 7,
            Weight: null,
            WeightMetric: 0,
            Height: null,
            HeightMetric: 0,
            NonDoseSpotMedicalRecordNumber: null,
            Active: true,
            Encounter: null,
            IsHospice: false,
          },
          UnmatchedChange: false,
          PrescribedMedication: {
            PrescriptionId: 59314522,
            WrittenDate: "2023-10-04T19:15:29.11",
            Directions: "Take 10 ml by mouth four times daily",
            Quantity: "1200",
            DispenseUnitId: 15,
            DispenseUnitDescription: "Milliliter(s)",
            Refills: "0",
            DaysSupply: null,
            PharmacyId: 30915,
            PharmacyNotes: null,
            NoSubstitutions: false,
            EffectiveDate: null,
            LastFillDate: null,
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: false,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 173535,
                DiagnosisCode: "F330",
                DiagnosisDescription:
                  "Major depressive disorder, recurrent, mild",
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63474223,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "solution",
            Route: "oral",
            Strength: "10 mg/5 mL",
            GenericProductName: "nortriptyline 10 mg/5 mL oral solution",
            GenericDrugName: "nortriptyline",
            LexiGenProductId: 382,
            LexiDrugSynId: 23091,
            LexiSynonymTypeId: 59,
            LexiGenDrugId: "d00144",
            RxCUI: "312036",
            OTC: false,
            NDC: "00121067816",
            Schedule: "0",
            DisplayName: "Nortriptyline 10 mg/5 mL solution",
            MonographPath: "HTMLICONS/d00144a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: false,
            CompoundIngredients: null,
          },
          RequestedMedications: [],
          PrescribedMedicationForRxchange: null,
          RequestedMedicationsForRxchange: null,
          Clinician: {
            ClinicianId: 194644,
            Prefix: null,
            FirstName: "Test",
            MiddleName: null,
            LastName: "User1",
            Suffix: null,
            DateOfBirth: "1927-09-07T00:00:00",
            Email: null,
            Address1: "980 Washington St",
            Address2: "#330",
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "8888476814",
            PrimaryPhoneType: 7,
            PrimaryFax: "8888476814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            DEANumbers: [
              {
                DEANumber: "",
                State: "All(default)",
                ClinicId: null,
              },
            ],
            DEANumber: "",
            NADEANumbers: [],
            MedicalLicenseNumbers: [],
            NPINumber: "1839267899",
            Roles: [1],
            PDMPRoleType: null,
            SpecialtyTypeId: null,
            Confirmed: true,
            Active: true,
            AccountLocked: false,
            EpcsRequested: true,
            ClinicInfo: [
              {
                ClinicId: 25808,
                HasNewRx: true,
                HasRefills: true,
                HasRxChange: true,
                HasCancel: true,
                HasRxFill: true,
                HasEpcs: false,
              },
            ],
          },
          Clinic: {
            ClinicId: 25808,
            ClinicName: "Beluga Health",
            ClinicNameLongForm: "TravelMD",
            Address1: "980 Washington St",
            Address2: "#330",
            Active: true,
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "(888) 847-6814",
            PrimaryPhoneType: 5,
            PrimaryFax: "(888) 847-6814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
          },
          Pharmacy: {
            PharmacyId: 30915,
            StoreName: "Paducah Phake Pharmacy",
            Address1: "555 Washington ST",
            Address2: "",
            City: "Paducah",
            State: "KY",
            ZipCode: "42003",
            PrimaryPhone: "2704421122",
            PrimaryPhoneType: 5,
            PrimaryFax: "2704422211",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            PharmacySpecialties: ["Retail", "EPCS"],
            ServiceLevel: 2079,
            Latitude: 37.084123,
            Longitude: -88.598908,
          },
          RequestedDate: "2023-10-04T19:16:34.56",
          OriginalPrescriptionId: 59308143,
          PayerName: "Pruebas PBM",
          DrugUseEvaluations: [],
        },
        {
          RxChangeRequestId: 221827,
          RxChangeType: "Therapeutic Interchange",
          RxChangeSubType: null,
          NewPatient: false,
          Patient: {
            PatientId: 21052726,
            Prefix: null,
            FirstName: "Rowena",
            MiddleName: "Baylie",
            LastName: "Acacianna",
            Suffix: null,
            DateOfBirth: "1968-03-29T00:00:00",
            Gender: 2,
            Email: null,
            Address1: "2798 Parsifal St NE",
            Address2: null,
            City: "Albuquerque",
            State: "New Mexico",
            ZipCode: "87112",
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditionalType1: 0,
            PhoneAdditionalType2: 0,
            PrimaryPhone: "5052936547",
            PrimaryPhoneType: 7,
            Weight: null,
            WeightMetric: 0,
            Height: null,
            HeightMetric: 0,
            NonDoseSpotMedicalRecordNumber: null,
            Active: true,
            Encounter: null,
            IsHospice: false,
          },
          UnmatchedChange: false,
          PrescribedMedication: {
            PrescriptionId: 59314523,
            WrittenDate: "2023-10-04T19:17:45.4",
            Directions: "Take 1 tablet by mouth once per day for 30 days",
            Quantity: "30",
            DispenseUnitId: 26,
            DispenseUnitDescription: "Tablet(s)",
            Refills: "1",
            DaysSupply: 30,
            PharmacyId: 14973,
            PharmacyNotes: null,
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: null,
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: false,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 177637,
                DiagnosisCode: "I10",
                DiagnosisDescription: "Essential (primary) hypertension",
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63474224,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "tablet",
            Route: "oral",
            Strength: "20 mg",
            GenericProductName: "lisinopril 20 mg oral tablet",
            GenericDrugName: "lisinopril",
            LexiGenProductId: 1950,
            LexiDrugSynId: 16551,
            LexiSynonymTypeId: 60,
            LexiGenDrugId: "d00732",
            RxCUI: "104378",
            OTC: false,
            NDC: "52427044190",
            Schedule: "0",
            DisplayName: "Zestril 20 mg tablet",
            MonographPath: "HTMLICONS/d00732a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: true,
            CompoundIngredients: null,
          },
          RequestedMedications: [
            {
              PrescriptionId: 59314524,
              WrittenDate: "2023-10-04T19:17:45.4",
              Directions: "Take 1 tablet by mouth once per day for 30 days",
              Quantity: "30",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63474225,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet",
              Route: "oral",
              Strength: "20 mg",
              GenericProductName: "lisinopril 20 mg oral tablet",
              GenericDrugName: "lisinopril",
              LexiGenProductId: 1950,
              LexiDrugSynId: 18389,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d00732",
              RxCUI: "314077",
              OTC: false,
              NDC: "00143971301",
              Schedule: "0",
              DisplayName: "Lisinopril 20 mg tablet",
              MonographPath: "HTMLICONS/d00732a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
            {
              PrescriptionId: 59314525,
              WrittenDate: "2019-01-01T00:00:00",
              Directions: "Take 2 tablets by mouth once per day for 30 days",
              Quantity: "60",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63474226,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet",
              Route: "oral",
              Strength: "10 mg",
              GenericProductName: "lisinopril 10 mg oral tablet",
              GenericDrugName: "lisinopril",
              LexiGenProductId: 1949,
              LexiDrugSynId: 18388,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d00732",
              RxCUI: "314076",
              OTC: false,
              NDC: "00143971401",
              Schedule: "0",
              DisplayName: "Lisinopril 10 mg tablet",
              MonographPath: "HTMLICONS/d00732a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
            {
              PrescriptionId: 59314526,
              WrittenDate: "2019-01-01T00:00:00",
              Directions: "Take 1 tablet by mouth once per day for 30 days",
              Quantity: "30",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63474227,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet",
              Route: "oral",
              Strength: "25 mg",
              GenericProductName: "captopril 25 mg oral tablet",
              GenericDrugName: "captopril",
              LexiGenProductId: 465,
              LexiDrugSynId: 17741,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d00006",
              RxCUI: "317173",
              OTC: false,
              NDC: "00143117201",
              Schedule: "0",
              DisplayName: "Captopril 25 mg tablet",
              MonographPath: "HTMLICONS/d00006a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
            {
              PrescriptionId: 59314527,
              WrittenDate: "2019-01-01T00:00:00",
              Directions: "Take 1 tablet by mouth once per day for 30 days",
              Quantity: "30",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63474228,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet",
              Route: "oral",
              Strength: "40 mg",
              GenericProductName: "benazepril 40 mg oral tablet",
              GenericDrugName: "benazepril",
              LexiGenProductId: 276,
              LexiDrugSynId: 18263,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d00730",
              RxCUI: "898719",
              OTC: false,
              NDC: "23155075201",
              Schedule: "0",
              DisplayName: "Benazepril 40 mg tablet",
              MonographPath: "HTMLICONS/d00730a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
            {
              PrescriptionId: 59314528,
              WrittenDate: "2019-01-01T00:00:00",
              Directions: "Take 1 tablet by mouth once per day for 30 days",
              Quantity: "30",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63474229,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet",
              Route: "oral",
              Strength: "20 mg",
              GenericProductName: "fosinopril 20 mg oral tablet",
              GenericDrugName: "fosinopril",
              LexiGenProductId: 1225,
              LexiDrugSynId: 18341,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d00242",
              RxCUI: "857183",
              OTC: false,
              NDC: "31722020110",
              Schedule: "0",
              DisplayName: "Fosinopril 20 mg tablet",
              MonographPath: "HTMLICONS/d00242a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
            {
              PrescriptionId: 59314529,
              WrittenDate: "2019-01-01T00:00:00",
              Directions: "Take 1 tablet by mouth once per day for 30 days",
              Quantity: "30",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63474230,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet",
              Route: "oral",
              Strength: "20 mg",
              GenericProductName: "quinapril 20 mg oral tablet",
              GenericDrugName: "quinapril",
              LexiGenProductId: 2869,
              LexiDrugSynId: 17895,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d00365",
              RxCUI: "312749",
              OTC: false,
              NDC: "31722026990",
              Schedule: "0",
              DisplayName: "Quinapril 20 mg tablet",
              MonographPath: "HTMLICONS/d00365a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
            {
              PrescriptionId: 59314530,
              WrittenDate: "2019-01-01T00:00:00",
              Directions: "Take 2 tablets by mouth once per day for 30 days",
              Quantity: "60",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 2,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63474231,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: null,
              Route: null,
              Strength: null,
              GenericProductName: "PERINDOPRIL ERBUMINE 8 MG TAB",
              GenericDrugName: "PERINDOPRIL ERBUMINE 8 MG TAB",
              LexiGenProductId: 0,
              LexiDrugSynId: 0,
              LexiSynonymTypeId: 0,
              LexiGenDrugId: null,
              RxCUI: "0",
              OTC: false,
              NDC: null,
              Schedule: null,
              DisplayName: "PERINDOPRIL ERBUMINE 8 MG TAB",
              MonographPath: null,
              DrugClassification: null,
              StateSchedules: null,
              Brand: true,
              CompoundIngredients: null,
            },
            {
              PrescriptionId: 59314531,
              WrittenDate: "2019-01-01T00:00:00",
              Directions: "Take 2 tablets by mouth once per day for 30 days",
              Quantity: "60",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63474232,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "capsule",
              Route: "oral",
              Strength: "10 mg",
              GenericProductName: "ramipril 10 mg oral capsule",
              GenericDrugName: "ramipril",
              LexiGenProductId: 2474,
              LexiDrugSynId: 31659,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d00728",
              RxCUI: "261962",
              OTC: false,
              NDC: "00054010925",
              Schedule: "0",
              DisplayName: "Ramipril 10 mg capsule",
              MonographPath: "HTMLICONS/d00728a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
            {
              PrescriptionId: 59314532,
              WrittenDate: "2019-01-01T00:00:00",
              Directions: "Take 2 tablets by mouth once per day for 30 days",
              Quantity: "60",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: 30,
              PharmacyId: 14973,
              PharmacyNotes: "Formulary Compliance",
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63474233,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet",
              Route: "oral",
              Strength: "15 mg",
              GenericProductName: "moexipril 15 mg oral tablet",
              GenericDrugName: "moexipril",
              LexiGenProductId: 162,
              LexiDrugSynId: 17849,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d03835",
              RxCUI: "1299896",
              OTC: false,
              NDC: "00093515001",
              Schedule: "0",
              DisplayName: "Moexipril 15 mg tablet",
              MonographPath: "HTMLICONS/d03835a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
          ],
          PrescribedMedicationForRxchange: null,
          RequestedMedicationsForRxchange: null,
          Clinician: {
            ClinicianId: 194644,
            Prefix: null,
            FirstName: "Test",
            MiddleName: null,
            LastName: "User1",
            Suffix: null,
            DateOfBirth: "1927-09-07T00:00:00",
            Email: null,
            Address1: "980 Washington St",
            Address2: "#330",
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "8888476814",
            PrimaryPhoneType: 7,
            PrimaryFax: "8888476814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            DEANumbers: [
              {
                DEANumber: "",
                State: "All(default)",
                ClinicId: null,
              },
            ],
            DEANumber: "",
            NADEANumbers: [],
            MedicalLicenseNumbers: [],
            NPINumber: "1839267899",
            Roles: [1],
            PDMPRoleType: null,
            SpecialtyTypeId: null,
            Confirmed: true,
            Active: true,
            AccountLocked: false,
            EpcsRequested: true,
            ClinicInfo: [
              {
                ClinicId: 25808,
                HasNewRx: true,
                HasRefills: true,
                HasRxChange: true,
                HasCancel: true,
                HasRxFill: true,
                HasEpcs: false,
              },
            ],
          },
          Clinic: {
            ClinicId: 25808,
            ClinicName: "Beluga Health",
            ClinicNameLongForm: "TravelMD",
            Address1: "980 Washington St",
            Address2: "#330",
            Active: true,
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "(888) 847-6814",
            PrimaryPhoneType: 5,
            PrimaryFax: "(888) 847-6814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
          },
          Pharmacy: {
            PharmacyId: 14973,
            StoreName: "Shollenberger Pharmacy",
            Address1: "2002 S. McDowell Blvd Ext",
            Address2: "",
            City: "Petaluma",
            State: "CA",
            ZipCode: "94954",
            PrimaryPhone: "7079845571",
            PrimaryPhoneType: 5,
            PrimaryFax: "7079884744",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            PharmacySpecialties: [
              "Long-Term Care Pharmacy",
              "Retail",
              "Specialty Pharmacy",
              "EPCS",
            ],
            ServiceLevel: 2079,
            Latitude: 38.229492,
            Longitude: -122.595968,
          },
          RequestedDate: "2023-10-04T19:17:50.403",
          OriginalPrescriptionId: 59308145,
          PayerName: "PBMX",
          DrugUseEvaluations: [],
        },
        {
          RxChangeRequestId: 221828,
          RxChangeType: "Drug Use Evaluation",
          RxChangeSubType: null,
          NewPatient: false,
          Patient: {
            PatientId: 21328537,
            Prefix: null,
            FirstName: "Zachary",
            MiddleName: null,
            LastName: "Delaplaine",
            Suffix: null,
            DateOfBirth: "2010-12-01T00:00:00",
            Gender: 1,
            Email: null,
            Address1: "901 Sauvblanc Blvd",
            Address2: null,
            City: "Petaluma",
            State: "California",
            ZipCode: "94952",
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditionalType1: 0,
            PhoneAdditionalType2: 0,
            PrimaryPhone: "9508450398",
            PrimaryPhoneType: 7,
            Weight: null,
            WeightMetric: 0,
            Height: null,
            HeightMetric: 0,
            NonDoseSpotMedicalRecordNumber: null,
            Active: true,
            Encounter: null,
            IsHospice: false,
          },
          UnmatchedChange: true,
          PrescribedMedication: {
            PrescriptionId: 59314533,
            WrittenDate: "2023-10-04T19:19:12.27",
            Directions:
              "Instill 1 drop into affected eye every 6 hours for 7 days",
            Quantity: "10",
            DispenseUnitId: 15,
            DispenseUnitDescription: "Milliliter(s)",
            Refills: "1",
            DaysSupply: null,
            PharmacyId: 14973,
            PharmacyNotes: null,
            NoSubstitutions: false,
            EffectiveDate: null,
            LastFillDate: null,
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: false,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 174957,
                DiagnosisCode: "H10502",
                DiagnosisDescription:
                  "Unspecified blepharoconjunctivitis, left eye",
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63474235,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "suspension",
            Route: "ophthalmic",
            Strength: "0.3%-0.1%",
            GenericProductName:
              "dexamethasone-tobramycin ophthalmic 0.3%-0.1% suspension",
            GenericDrugName: "dexamethasone-tobramycin ophthalmic",
            LexiGenProductId: 3428,
            LexiDrugSynId: 28242,
            LexiSynonymTypeId: 59,
            LexiGenDrugId: "d03536",
            RxCUI: "309683",
            OTC: false,
            NDC: "24208029505",
            Schedule: "0",
            DisplayName:
              "Tobramycin-Dexamethasone Ophthalmic 0.3%-0.1% suspension",
            MonographPath: "HTMLICONS/d03536a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: false,
            CompoundIngredients: null,
          },
          RequestedMedications: [
            {
              PrescriptionId: 59314534,
              WrittenDate: "2023-10-04T19:19:12.27",
              Directions:
                "Instill 1 drop into affected eye every 4 hours for 7 days",
              Quantity: "10",
              DispenseUnitId: 15,
              DispenseUnitDescription: "Milliliter(s)",
              Refills: "1",
              DaysSupply: null,
              PharmacyId: 14973,
              PharmacyNotes: null,
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63474236,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "suspension",
              Route: "ophthalmic",
              Strength: "0.3%-0.1%",
              GenericProductName:
                "dexamethasone-tobramycin ophthalmic 0.3%-0.1% suspension",
              GenericDrugName: "dexamethasone-tobramycin ophthalmic",
              LexiGenProductId: 3428,
              LexiDrugSynId: 28242,
              LexiSynonymTypeId: 59,
              LexiGenDrugId: "d03536",
              RxCUI: "309683",
              OTC: false,
              NDC: "24208029505",
              Schedule: "0",
              DisplayName:
                "Tobramycin-Dexamethasone Ophthalmic 0.3%-0.1% suspension",
              MonographPath: "HTMLICONS/d03536a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
          ],
          PrescribedMedicationForRxchange: null,
          RequestedMedicationsForRxchange: null,
          Clinician: {
            ClinicianId: 194644,
            Prefix: null,
            FirstName: "Test",
            MiddleName: null,
            LastName: "User1",
            Suffix: null,
            DateOfBirth: "1927-09-07T00:00:00",
            Email: null,
            Address1: "980 Washington St",
            Address2: "#330",
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "8888476814",
            PrimaryPhoneType: 7,
            PrimaryFax: "8888476814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            DEANumbers: [
              {
                DEANumber: "",
                State: "All(default)",
                ClinicId: null,
              },
            ],
            DEANumber: "",
            NADEANumbers: [],
            MedicalLicenseNumbers: [],
            NPINumber: "1839267899",
            Roles: [1],
            PDMPRoleType: null,
            SpecialtyTypeId: null,
            Confirmed: true,
            Active: true,
            AccountLocked: false,
            EpcsRequested: true,
            ClinicInfo: [
              {
                ClinicId: 25808,
                HasNewRx: true,
                HasRefills: true,
                HasRxChange: true,
                HasCancel: true,
                HasRxFill: true,
                HasEpcs: false,
              },
            ],
          },
          Clinic: {
            ClinicId: 25808,
            ClinicName: "Beluga Health",
            ClinicNameLongForm: "TravelMD",
            Address1: "980 Washington St",
            Address2: "#330",
            Active: true,
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "(888) 847-6814",
            PrimaryPhoneType: 5,
            PrimaryFax: "(888) 847-6814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
          },
          Pharmacy: {
            PharmacyId: 14973,
            StoreName: "Shollenberger Pharmacy",
            Address1: "2002 S. McDowell Blvd Ext",
            Address2: "",
            City: "Petaluma",
            State: "CA",
            ZipCode: "94954",
            PrimaryPhone: "7079845571",
            PrimaryPhoneType: 5,
            PrimaryFax: "7079884744",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            PharmacySpecialties: [
              "Long-Term Care Pharmacy",
              "Retail",
              "Specialty Pharmacy",
              "EPCS",
            ],
            ServiceLevel: 2079,
            Latitude: 38.229492,
            Longitude: -122.595968,
          },
          RequestedDate: "2023-10-04T19:19:30.723",
          OriginalPrescriptionId: null,
          PayerName: "PBMF",
          DrugUseEvaluations: [],
        },
        {
          RxChangeRequestId: 221829,
          RxChangeType: "Out Of Stock",
          RxChangeSubType: null,
          NewPatient: true,
          Patient: {
            PatientId: 21336818,
            Prefix: null,
            FirstName: "Margaret Adelia",
            MiddleName: "Beryl",
            LastName: "Devereaux",
            Suffix: null,
            DateOfBirth: "1997-11-01T00:00:00",
            Gender: 2,
            Email: "Adelia.D@yahoo.com",
            Address1: "27-B Heald St",
            Address2: null,
            City: "Pepperell",
            State: "Massachusetts",
            ZipCode: "01463",
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditionalType1: 0,
            PhoneAdditionalType2: 0,
            PrimaryPhone: "6176884642",
            PrimaryPhoneType: 7,
            Weight: null,
            WeightMetric: 0,
            Height: null,
            HeightMetric: 0,
            NonDoseSpotMedicalRecordNumber: null,
            Active: true,
            Encounter: null,
            IsHospice: false,
          },
          UnmatchedChange: false,
          PrescribedMedication: {
            PrescriptionId: 59314535,
            WrittenDate: "2023-10-04T19:20:02.067",
            Directions: "Take 1 tablet orally once per day for 30 days",
            Quantity: "30",
            DispenseUnitId: 26,
            DispenseUnitDescription: "Tablet(s)",
            Refills: "1",
            DaysSupply: null,
            PharmacyId: 14973,
            PharmacyNotes: null,
            NoSubstitutions: false,
            EffectiveDate: null,
            LastFillDate: null,
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: false,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 177637,
                DiagnosisCode: "I10",
                DiagnosisDescription: "Essential (primary) hypertension",
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63474237,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "tablet",
            Route: "oral",
            Strength: "50 mg",
            GenericProductName: "atenolol 50 mg oral tablet",
            GenericDrugName: "atenolol",
            LexiGenProductId: 538,
            LexiDrugSynId: 17101,
            LexiSynonymTypeId: 59,
            LexiGenDrugId: "d00004",
            RxCUI: "197381",
            OTC: false,
            NDC: "00093075201",
            Schedule: "0",
            DisplayName: "Atenolol 50 mg tablet",
            MonographPath: "HTMLICONS/d00004a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: false,
            CompoundIngredients: null,
          },
          RequestedMedications: [
            {
              PrescriptionId: 59314536,
              WrittenDate: "2023-10-04T19:20:02.067",
              Directions: "Take 1 tablet orally once per day for 30 days",
              Quantity: "30",
              DispenseUnitId: 26,
              DispenseUnitDescription: "Tablet(s)",
              Refills: "1",
              DaysSupply: null,
              PharmacyId: 14973,
              PharmacyNotes: null,
              NoSubstitutions: false,
              EffectiveDate: null,
              LastFillDate: null,
              PrescriberId: 194644,
              PrescriberAgentId: null,
              RxReferenceNumber: null,
              Status: 8,
              Formulary: false,
              EligibilityId: 0,
              Type: 1,
              NonDoseSpotPrescriptionId: null,
              ErrorIgnored: false,
              SupplyId: null,
              CompoundId: null,
              FreeTextType: null,
              ClinicId: 25808,
              SupervisorId: null,
              IsUrgent: false,
              IsRxRenewal: false,
              RxRenewalNote: null,
              FirstPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              SecondPrescriptionDiagnosis: {
                PrimaryDiagnosis: {
                  DiagnosisId: 0,
                  DiagnosisCode: null,
                  DiagnosisDescription: null,
                },
                SecondaryDiagnosis: null,
              },
              PatientMedicationId: 63474238,
              MedicationStatus: 1,
              Comment: null,
              DateInactive: null,
              Encounter: null,
              DoseForm: "tablet, extended release",
              Route: "oral",
              Strength: "100 mg",
              GenericProductName:
                "metoprolol 100 mg oral tablet, extended release",
              GenericDrugName: "metoprolol",
              LexiGenProductId: 2392,
              LexiDrugSynId: 119048,
              LexiSynonymTypeId: 60,
              LexiGenDrugId: "d00134",
              RxCUI: "866412",
              OTC: false,
              NDC: "00378459710",
              Schedule: "0",
              DisplayName:
                "Metoprolol Succinate ER 100 mg tablet, extended release",
              MonographPath: "HTMLICONS/d00134a1.htm",
              DrugClassification: null,
              StateSchedules: [],
              Brand: false,
              CompoundIngredients: null,
            },
          ],
          PrescribedMedicationForRxchange: null,
          RequestedMedicationsForRxchange: null,
          Clinician: {
            ClinicianId: 194644,
            Prefix: null,
            FirstName: "Test",
            MiddleName: null,
            LastName: "User1",
            Suffix: null,
            DateOfBirth: "1927-09-07T00:00:00",
            Email: null,
            Address1: "980 Washington St",
            Address2: "#330",
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "8888476814",
            PrimaryPhoneType: 7,
            PrimaryFax: "8888476814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            DEANumbers: [
              {
                DEANumber: "",
                State: "All(default)",
                ClinicId: null,
              },
            ],
            DEANumber: "",
            NADEANumbers: [],
            MedicalLicenseNumbers: [],
            NPINumber: "1839267899",
            Roles: [1],
            PDMPRoleType: null,
            SpecialtyTypeId: null,
            Confirmed: true,
            Active: true,
            AccountLocked: false,
            EpcsRequested: true,
            ClinicInfo: [
              {
                ClinicId: 25808,
                HasNewRx: true,
                HasRefills: true,
                HasRxChange: true,
                HasCancel: true,
                HasRxFill: true,
                HasEpcs: false,
              },
            ],
          },
          Clinic: {
            ClinicId: 25808,
            ClinicName: "Beluga Health",
            ClinicNameLongForm: "TravelMD",
            Address1: "980 Washington St",
            Address2: "#330",
            Active: true,
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "(888) 847-6814",
            PrimaryPhoneType: 5,
            PrimaryFax: "(888) 847-6814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
          },
          Pharmacy: {
            PharmacyId: 14973,
            StoreName: "Shollenberger Pharmacy",
            Address1: "2002 S. McDowell Blvd Ext",
            Address2: "",
            City: "Petaluma",
            State: "CA",
            ZipCode: "94954",
            PrimaryPhone: "7079845571",
            PrimaryPhoneType: 5,
            PrimaryFax: "7079884744",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            PharmacySpecialties: [
              "Long-Term Care Pharmacy",
              "Retail",
              "Specialty Pharmacy",
              "EPCS",
            ],
            ServiceLevel: 2079,
            Latitude: 38.229492,
            Longitude: -122.595968,
          },
          RequestedDate: "2023-10-04T19:20:06.95",
          OriginalPrescriptionId: 59308195,
          PayerName: "ProInforma PBM",
          DrugUseEvaluations: [],
        },
      ],
      Result: {
        ResultCode: "OK",
        ResultDescription: "",
      },
    },
    refills: {
      Items: [
        {
          RefillId: 221690,
          OriginalPrescriptionId: 59301564,
          NewPatient: false,
          Patient: {
            PatientId: 21013738,
            Prefix: null,
            FirstName: "Jacob",
            MiddleName: null,
            LastName: "Mink",
            Suffix: null,
            DateOfBirth: "1988-09-03T00:00:00",
            Gender: 1,
            Email: "jacobmink@gmail.com",
            Address1: "123 fake st.",
            Address2: null,
            City: "Faketown",
            State: "New York",
            ZipCode: "11111",
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditionalType1: 0,
            PhoneAdditionalType2: 0,
            PrimaryPhone: "7162615679",
            PrimaryPhoneType: 7,
            Weight: null,
            WeightMetric: 0,
            Height: null,
            HeightMetric: 0,
            NonDoseSpotMedicalRecordNumber: null,
            Active: true,
            Encounter: null,
            IsHospice: false,
          },
          RequestedPrescription: {
            PrescriptionId: 59301790,
            WrittenDate: "2023-09-20T01:00:12",
            Directions: "directions",
            Quantity: "10",
            DispenseUnitId: 26,
            DispenseUnitDescription: "Tablet(s)",
            Refills: "5",
            DaysSupply: 5,
            PharmacyId: 14973,
            PharmacyNotes:
              "Pharm Msg - Apply Patient Savings Program BIN - 610280, GRP - DOSERX, MBR ID - DS2345",
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: "2023-09-20T00:00:00",
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: true,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63458136,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "tablet",
            Route: "oral",
            Strength: "325 mg",
            GenericProductName: "acetaminophen 325 mg oral tablet",
            GenericDrugName: "acetaminophen",
            LexiGenProductId: 3819,
            LexiDrugSynId: 24649,
            LexiSynonymTypeId: 60,
            LexiGenDrugId: "d00049",
            RxCUI: "209387",
            OTC: true,
            NDC: "50580049660",
            Schedule: "0",
            DisplayName: "Tylenol Regular Strength 325 mg tablet",
            MonographPath: "HTMLICONS/d00049a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: true,
            CompoundIngredients: null,
          },
          DispensedPrescription: {
            PrescriptionId: 59301789,
            WrittenDate: "2023-09-20T01:00:12",
            Directions: "directions",
            Quantity: "10",
            DispenseUnitId: 26,
            DispenseUnitDescription: "Tablet(s)",
            Refills: "5",
            DaysSupply: 5,
            PharmacyId: 14973,
            PharmacyNotes:
              "Pharm Msg - Apply Patient Savings Program BIN - 610280, GRP - DOSERX, MBR ID - DS2345",
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: "2023-09-20T00:00:00",
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: true,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63458135,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "tablet",
            Route: "oral",
            Strength: "325 mg",
            GenericProductName: "acetaminophen 325 mg oral tablet",
            GenericDrugName: "acetaminophen",
            LexiGenProductId: 3819,
            LexiDrugSynId: 24649,
            LexiSynonymTypeId: 60,
            LexiGenDrugId: "d00049",
            RxCUI: "209387",
            OTC: true,
            NDC: "50580049660",
            Schedule: "0",
            DisplayName: "Tylenol 325 mg tablet",
            MonographPath: "HTMLICONS/d00049a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: true,
            CompoundIngredients: null,
          },
          Clinician: {
            ClinicianId: 194644,
            Prefix: null,
            FirstName: "Test",
            MiddleName: null,
            LastName: "User1",
            Suffix: null,
            DateOfBirth: "1927-09-07T00:00:00",
            Email: null,
            Address1: "980 Washington St",
            Address2: "#330",
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "8888476814",
            PrimaryPhoneType: 7,
            PrimaryFax: "8888476814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            DEANumbers: [
              {
                DEANumber: "",
                State: "All(default)",
                ClinicId: null,
              },
            ],
            DEANumber: "",
            NADEANumbers: [],
            MedicalLicenseNumbers: [],
            NPINumber: "1839267899",
            Roles: [1],
            PDMPRoleType: null,
            SpecialtyTypeId: null,
            Confirmed: true,
            Active: true,
            AccountLocked: false,
            EpcsRequested: true,
            ClinicInfo: [
              {
                ClinicId: 25808,
                HasNewRx: true,
                HasRefills: true,
                HasRxChange: true,
                HasCancel: true,
                HasRxFill: true,
                HasEpcs: false,
              },
            ],
          },
          Clinic: {
            ClinicId: 25808,
            ClinicName: "Beluga Health",
            ClinicNameLongForm: "TravelMD",
            Address1: "980 Washington St",
            Address2: "#330",
            Active: true,
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "(888) 847-6814",
            PrimaryPhoneType: 5,
            PrimaryFax: "(888) 847-6814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
          },
          Pharmacy: {
            PharmacyId: 14973,
            StoreName: "Shollenberger Pharmacy",
            Address1: "2002 S. McDowell Blvd Ext",
            Address2: "",
            City: "Petaluma",
            State: "CA",
            ZipCode: "94954",
            PrimaryPhone: "7079845571",
            PrimaryPhoneType: 5,
            PrimaryFax: "7079884744",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            PharmacySpecialties: [
              "Long-Term Care Pharmacy",
              "Retail",
              "Specialty Pharmacy",
              "EPCS",
            ],
            ServiceLevel: 2079,
            Latitude: 38.229492,
            Longitude: -122.595968,
          },
          RequestedDate: "2023-09-20T13:44:53.927",
        },
        {
          RefillId: 221785,
          OriginalPrescriptionId: 59308108,
          NewPatient: false,
          Patient: {
            PatientId: 21328537,
            Prefix: null,
            FirstName: "Zachary",
            MiddleName: null,
            LastName: "Delaplaine",
            Suffix: null,
            DateOfBirth: "2010-12-01T00:00:00",
            Gender: 1,
            Email: null,
            Address1: "901 Sauvblanc Blvd",
            Address2: null,
            City: "Petaluma",
            State: "California",
            ZipCode: "94952",
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditionalType1: 0,
            PhoneAdditionalType2: 0,
            PrimaryPhone: "9168883344",
            PrimaryPhoneType: 7,
            Weight: null,
            WeightMetric: 0,
            Height: null,
            HeightMetric: 0,
            NonDoseSpotMedicalRecordNumber: null,
            Active: true,
            Encounter: null,
            IsHospice: false,
          },
          RequestedPrescription: {
            PrescriptionId: 59309028,
            WrittenDate: "2023-09-29T00:58:30",
            Directions:
              "Take 2.5 mL by mouth three times a day for 7 days. Discard remainder.",
            Quantity: "52.555",
            DispenseUnitId: 15,
            DispenseUnitDescription: "Milliliter(s)",
            Refills: "0",
            DaysSupply: 0,
            PharmacyId: 14973,
            PharmacyNotes:
              "Pharm Msg - Apply Patient Savings Program BIN - 610280, GRP - DOSERX, MBR ID - DS2345",
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: "2023-09-29T00:00:00",
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: true,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63467719,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "powder for reconstitution",
            Route: "oral",
            Strength: "200 mg/5 mL",
            GenericProductName:
              "amoxicillin 200 mg/5 mL oral powder for reconstitution",
            GenericDrugName: "amoxicillin",
            LexiGenProductId: 7000,
            LexiDrugSynId: 30247,
            LexiSynonymTypeId: 59,
            LexiGenDrugId: "d00088",
            RxCUI: "313850",
            OTC: false,
            NDC: "00093416073",
            Schedule: "0",
            DisplayName: "Amoxicillin 200 mg/5 mL powder for reconstitution",
            MonographPath: "HTMLICONS/d00088a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: false,
            CompoundIngredients: null,
          },
          DispensedPrescription: {
            PrescriptionId: 59309027,
            WrittenDate: "2023-09-29T00:58:30",
            Directions:
              "Take 2.5 mL by mouth three times a day for 7 days. Discard remainder.",
            Quantity: "52.555",
            DispenseUnitId: 15,
            DispenseUnitDescription: "Milliliter(s)",
            Refills: "1",
            DaysSupply: 0,
            PharmacyId: 14973,
            PharmacyNotes:
              "Pharm Msg - Apply Patient Savings Program BIN - 610280, GRP - DOSERX, MBR ID - DS2345",
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: "2023-09-29T00:00:00",
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: true,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63467718,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "powder for reconstitution",
            Route: "oral",
            Strength: "200 mg/5 mL",
            GenericProductName:
              "amoxicillin 200 mg/5 mL oral powder for reconstitution",
            GenericDrugName: "amoxicillin",
            LexiGenProductId: 7000,
            LexiDrugSynId: 30247,
            LexiSynonymTypeId: 59,
            LexiGenDrugId: "d00088",
            RxCUI: "313850",
            OTC: false,
            NDC: "00093416073",
            Schedule: "0",
            DisplayName: "Amoxicillin 200 mg/5 mL powder for reconstitution",
            MonographPath: "HTMLICONS/d00088a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: false,
            CompoundIngredients: null,
          },
          Clinician: {
            ClinicianId: 194644,
            Prefix: null,
            FirstName: "Test",
            MiddleName: null,
            LastName: "User1",
            Suffix: null,
            DateOfBirth: "1927-09-07T00:00:00",
            Email: null,
            Address1: "980 Washington St",
            Address2: "#330",
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "8888476814",
            PrimaryPhoneType: 7,
            PrimaryFax: "8888476814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            DEANumbers: [
              {
                DEANumber: "",
                State: "All(default)",
                ClinicId: null,
              },
            ],
            DEANumber: "",
            NADEANumbers: [],
            MedicalLicenseNumbers: [],
            NPINumber: "1839267899",
            Roles: [1],
            PDMPRoleType: null,
            SpecialtyTypeId: null,
            Confirmed: true,
            Active: true,
            AccountLocked: false,
            EpcsRequested: true,
            ClinicInfo: [
              {
                ClinicId: 25808,
                HasNewRx: true,
                HasRefills: true,
                HasRxChange: true,
                HasCancel: true,
                HasRxFill: true,
                HasEpcs: false,
              },
            ],
          },
          Clinic: {
            ClinicId: 25808,
            ClinicName: "Beluga Health",
            ClinicNameLongForm: "TravelMD",
            Address1: "980 Washington St",
            Address2: "#330",
            Active: true,
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "(888) 847-6814",
            PrimaryPhoneType: 5,
            PrimaryFax: "(888) 847-6814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
          },
          Pharmacy: {
            PharmacyId: 14973,
            StoreName: "Shollenberger Pharmacy",
            Address1: "2002 S. McDowell Blvd Ext",
            Address2: "",
            City: "Petaluma",
            State: "CA",
            ZipCode: "94954",
            PrimaryPhone: "7079845571",
            PrimaryPhoneType: 5,
            PrimaryFax: "7079884744",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            PharmacySpecialties: [
              "Long-Term Care Pharmacy",
              "Retail",
              "Specialty Pharmacy",
              "EPCS",
            ],
            ServiceLevel: 2079,
            Latitude: 38.229492,
            Longitude: -122.595968,
          },
          RequestedDate: "2023-09-29T15:07:16.647",
        },
        {
          RefillId: 221786,
          OriginalPrescriptionId: 59308113,
          NewPatient: false,
          Patient: {
            PatientId: 21328615,
            Prefix: null,
            FirstName: "Juancarlosguadalupepaploapan",
            MiddleName: null,
            LastName: "Usumacintacoatzacoalcosniltepecvera",
            Suffix: null,
            DateOfBirth: "2004-06-21T00:00:00",
            Gender: 1,
            Email: null,
            Address1: "27732 West Alameda Potholeladen St",
            Address2: "reet",
            City: "Rancho Cucamonga",
            State: "California",
            ZipCode: "91701",
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditionalType1: 0,
            PhoneAdditionalType2: 0,
            PrimaryPhone: "7075214577",
            PrimaryPhoneType: 7,
            Weight: null,
            WeightMetric: 0,
            Height: null,
            HeightMetric: 0,
            NonDoseSpotMedicalRecordNumber: null,
            Active: true,
            Encounter: null,
            IsHospice: false,
          },
          RequestedPrescription: {
            PrescriptionId: 59309032,
            WrittenDate: "2023-09-29T01:22:43",
            Directions:
              "Swish and spit 15 mL orally for 1 minute every 12 hours",
            Quantity: "900",
            DispenseUnitId: 15,
            DispenseUnitDescription: "Milliliter(s)",
            Refills: "1",
            DaysSupply: 30,
            PharmacyId: 14973,
            PharmacyNotes:
              "Pharm Msg - Apply Patient Savings Program BIN - 610280, GRP - DOSERX, MBR ID - DS2345",
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: "2023-09-29T00:00:00",
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: true,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63467723,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "solution",
            Route: "oral",
            Strength: "20 mg/5 mL",
            GenericProductName: "FLUoxetine 20 mg/5 mL oral solution",
            GenericDrugName: "FLUoxetine",
            LexiGenProductId: 1085,
            LexiDrugSynId: 21742,
            LexiSynonymTypeId: 59,
            LexiGenDrugId: "d00236",
            RxCUI: "310386",
            OTC: false,
            NDC: "00121072104",
            Schedule: "0",
            DisplayName: "FLUoxetine 20 mg/5 mL solution",
            MonographPath: "HTMLICONS/d00236a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: false,
            CompoundIngredients: null,
          },
          DispensedPrescription: {
            PrescriptionId: 59309031,
            WrittenDate: "2023-09-29T01:22:43",
            Directions:
              "Swish and spit 15 mL orally for 1 minute every 12 hours",
            Quantity: "900",
            DispenseUnitId: 15,
            DispenseUnitDescription: "Milliliter(s)",
            Refills: "1",
            DaysSupply: 30,
            PharmacyId: 14973,
            PharmacyNotes:
              "Pharm Msg - Apply Patient Savings Program BIN - 610280, GRP - DOSERX, MBR ID - DS2345",
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: "2023-09-29T00:00:00",
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: true,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63467722,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "solution",
            Route: "oral",
            Strength: "20 mg/5 mL",
            GenericProductName: "FLUoxetine 20 mg/5 mL oral solution",
            GenericDrugName: "FLUoxetine",
            LexiGenProductId: 1085,
            LexiDrugSynId: 21742,
            LexiSynonymTypeId: 59,
            LexiGenDrugId: "d00236",
            RxCUI: "310386",
            OTC: false,
            NDC: "00121072104",
            Schedule: "0",
            DisplayName: "FLUoxetine 20 mg/5 mL solution",
            MonographPath: "HTMLICONS/d00236a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: false,
            CompoundIngredients: null,
          },
          Clinician: {
            ClinicianId: 194644,
            Prefix: null,
            FirstName: "Test",
            MiddleName: null,
            LastName: "User1",
            Suffix: null,
            DateOfBirth: "1927-09-07T00:00:00",
            Email: null,
            Address1: "980 Washington St",
            Address2: "#330",
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "8888476814",
            PrimaryPhoneType: 7,
            PrimaryFax: "8888476814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            DEANumbers: [
              {
                DEANumber: "",
                State: "All(default)",
                ClinicId: null,
              },
            ],
            DEANumber: "",
            NADEANumbers: [],
            MedicalLicenseNumbers: [],
            NPINumber: "1839267899",
            Roles: [1],
            PDMPRoleType: null,
            SpecialtyTypeId: null,
            Confirmed: true,
            Active: true,
            AccountLocked: false,
            EpcsRequested: true,
            ClinicInfo: [
              {
                ClinicId: 25808,
                HasNewRx: true,
                HasRefills: true,
                HasRxChange: true,
                HasCancel: true,
                HasRxFill: true,
                HasEpcs: false,
              },
            ],
          },
          Clinic: {
            ClinicId: 25808,
            ClinicName: "Beluga Health",
            ClinicNameLongForm: "TravelMD",
            Address1: "980 Washington St",
            Address2: "#330",
            Active: true,
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "(888) 847-6814",
            PrimaryPhoneType: 5,
            PrimaryFax: "(888) 847-6814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
          },
          Pharmacy: {
            PharmacyId: 14973,
            StoreName: "Shollenberger Pharmacy",
            Address1: "2002 S. McDowell Blvd Ext",
            Address2: "",
            City: "Petaluma",
            State: "CA",
            ZipCode: "94954",
            PrimaryPhone: "7079845571",
            PrimaryPhoneType: 5,
            PrimaryFax: "7079884744",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            PharmacySpecialties: [
              "Long-Term Care Pharmacy",
              "Retail",
              "Specialty Pharmacy",
              "EPCS",
            ],
            ServiceLevel: 2079,
            Latitude: 38.229492,
            Longitude: -122.595968,
          },
          RequestedDate: "2023-09-29T15:08:47.033",
        },
        {
          RefillId: 221822,
          OriginalPrescriptionId: 59314478,
          NewPatient: false,
          Patient: {
            PatientId: 21013738,
            Prefix: null,
            FirstName: "Jacob",
            MiddleName: null,
            LastName: "Mink",
            Suffix: null,
            DateOfBirth: "1988-09-03T00:00:00",
            Gender: 1,
            Email: "jacobmink@gmail.com",
            Address1: "123 fake st.",
            Address2: null,
            City: "Faketown",
            State: "New York",
            ZipCode: "11111",
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditionalType1: 0,
            PhoneAdditionalType2: 0,
            PrimaryPhone: "7162615679",
            PrimaryPhoneType: 7,
            Weight: null,
            WeightMetric: 0,
            Height: null,
            HeightMetric: 0,
            NonDoseSpotMedicalRecordNumber: null,
            Active: true,
            Encounter: null,
            IsHospice: false,
          },
          RequestedPrescription: {
            PrescriptionId: 59314499,
            WrittenDate: "2023-10-04T18:34:43",
            Directions: "drink",
            Quantity: "5",
            DispenseUnitId: 26,
            DispenseUnitDescription: "Tablet(s)",
            Refills: "1",
            DaysSupply: 5,
            PharmacyId: 14973,
            PharmacyNotes:
              "Pharm Msg - Apply Patient Savings Program BIN - 610280, GRP - DOSERX, MBR ID - DS2345",
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: "2023-10-04T00:00:00",
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: true,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63474197,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "tablet",
            Route: "oral",
            Strength: "20 mg",
            GenericProductName: "doxycycline 20 mg oral tablet",
            GenericDrugName: "doxycycline",
            LexiGenProductId: 8390,
            LexiDrugSynId: 41734,
            LexiSynonymTypeId: 59,
            LexiGenDrugId: "d00037",
            RxCUI: "283535",
            OTC: false,
            NDC: "00527133601",
            Schedule: "0",
            DisplayName: "Doxycycline 20 mg tablet",
            MonographPath: "HTMLICONS/d00037a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: false,
            CompoundIngredients: null,
          },
          DispensedPrescription: {
            PrescriptionId: 59314498,
            WrittenDate: "2023-10-04T18:34:43",
            Directions: "drink",
            Quantity: "5",
            DispenseUnitId: 26,
            DispenseUnitDescription: "Tablet(s)",
            Refills: "1",
            DaysSupply: 5,
            PharmacyId: 14973,
            PharmacyNotes:
              "Pharm Msg - Apply Patient Savings Program BIN - 610280, GRP - DOSERX, MBR ID - DS2345",
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: "2023-10-04T00:00:00",
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: true,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63474196,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "tablet",
            Route: "oral",
            Strength: "20 mg",
            GenericProductName: "doxycycline 20 mg oral tablet",
            GenericDrugName: "doxycycline",
            LexiGenProductId: 8390,
            LexiDrugSynId: 41734,
            LexiSynonymTypeId: 59,
            LexiGenDrugId: "d00037",
            RxCUI: "283535",
            OTC: false,
            NDC: "00527133601",
            Schedule: "0",
            DisplayName: "Doxycycline 20 mg tablet",
            MonographPath: "HTMLICONS/d00037a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: false,
            CompoundIngredients: null,
          },
          Clinician: {
            ClinicianId: 194644,
            Prefix: null,
            FirstName: "Test",
            MiddleName: null,
            LastName: "User1",
            Suffix: null,
            DateOfBirth: "1927-09-07T00:00:00",
            Email: null,
            Address1: "980 Washington St",
            Address2: "#330",
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "8888476814",
            PrimaryPhoneType: 7,
            PrimaryFax: "8888476814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            DEANumbers: [
              {
                DEANumber: "",
                State: "All(default)",
                ClinicId: null,
              },
            ],
            DEANumber: "",
            NADEANumbers: [],
            MedicalLicenseNumbers: [],
            NPINumber: "1839267899",
            Roles: [1],
            PDMPRoleType: null,
            SpecialtyTypeId: null,
            Confirmed: true,
            Active: true,
            AccountLocked: false,
            EpcsRequested: true,
            ClinicInfo: [
              {
                ClinicId: 25808,
                HasNewRx: true,
                HasRefills: true,
                HasRxChange: true,
                HasCancel: true,
                HasRxFill: true,
                HasEpcs: false,
              },
            ],
          },
          Clinic: {
            ClinicId: 25808,
            ClinicName: "Beluga Health",
            ClinicNameLongForm: "TravelMD",
            Address1: "980 Washington St",
            Address2: "#330",
            Active: true,
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "(888) 847-6814",
            PrimaryPhoneType: 5,
            PrimaryFax: "(888) 847-6814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
          },
          Pharmacy: {
            PharmacyId: 14973,
            StoreName: "Shollenberger Pharmacy",
            Address1: "2002 S. McDowell Blvd Ext",
            Address2: "",
            City: "Petaluma",
            State: "CA",
            ZipCode: "94954",
            PrimaryPhone: "7079845571",
            PrimaryPhoneType: 5,
            PrimaryFax: "7079884744",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            PharmacySpecialties: [
              "Long-Term Care Pharmacy",
              "Retail",
              "Specialty Pharmacy",
              "EPCS",
            ],
            ServiceLevel: 2079,
            Latitude: 38.229492,
            Longitude: -122.595968,
          },
          RequestedDate: "2023-10-04T18:46:46.583",
        },
        {
          RefillId: 221823,
          OriginalPrescriptionId: 59314479,
          NewPatient: false,
          Patient: {
            PatientId: 21013738,
            Prefix: null,
            FirstName: "Jacob",
            MiddleName: null,
            LastName: "Mink",
            Suffix: null,
            DateOfBirth: "1988-09-03T00:00:00",
            Gender: 1,
            Email: "jacobmink@gmail.com",
            Address1: "123 fake st.",
            Address2: null,
            City: "Faketown",
            State: "New York",
            ZipCode: "11111",
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditionalType1: 0,
            PhoneAdditionalType2: 0,
            PrimaryPhone: "7162615679",
            PrimaryPhoneType: 7,
            Weight: null,
            WeightMetric: 0,
            Height: null,
            HeightMetric: 0,
            NonDoseSpotMedicalRecordNumber: null,
            Active: true,
            Encounter: null,
            IsHospice: false,
          },
          RequestedPrescription: {
            PrescriptionId: 59314502,
            WrittenDate: "2023-10-04T18:34:43",
            Directions: "test",
            Quantity: "5",
            DispenseUnitId: 15,
            DispenseUnitDescription: "Milliliter(s)",
            Refills: "2",
            DaysSupply: 5,
            PharmacyId: 14973,
            PharmacyNotes:
              "Pharm Msg - Apply Patient Savings Program BIN - 610280, GRP - DOSERX, MBR ID - DS2345",
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: "2023-10-04T00:00:00",
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: true,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63474200,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "syrup",
            Route: "oral",
            Strength: "1.25 mg-30 mg/5 mL",
            GenericProductName:
              "pseudoephedrine-triprolidine 1.25 mg-30 mg/5 mL oral syrup",
            GenericDrugName: "pseudoephedrine-triprolidine",
            LexiGenProductId: 3887,
            LexiDrugSynId: 22698,
            LexiSynonymTypeId: 60,
            LexiGenDrugId: "d03316",
            RxCUI: "1099308",
            OTC: true,
            NDC: "00113038326",
            Schedule: "0",
            DisplayName: "Aphedrid 1.25 mg-30 mg/5 mL syrup",
            MonographPath: "HTMLICONS/d03316a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: false,
            CompoundIngredients: null,
          },
          DispensedPrescription: {
            PrescriptionId: 59314501,
            WrittenDate: "2023-10-04T18:34:43",
            Directions: "test",
            Quantity: "5",
            DispenseUnitId: 15,
            DispenseUnitDescription: "Milliliter(s)",
            Refills: "2",
            DaysSupply: 5,
            PharmacyId: 14973,
            PharmacyNotes:
              "Pharm Msg - Apply Patient Savings Program BIN - 610280, GRP - DOSERX, MBR ID - DS2345",
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: "2023-10-04T00:00:00",
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: true,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63474199,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "syrup",
            Route: "oral",
            Strength: "1.25 mg-30 mg/5 mL",
            GenericProductName:
              "pseudoephedrine-triprolidine 1.25 mg-30 mg/5 mL oral syrup",
            GenericDrugName: "pseudoephedrine-triprolidine",
            LexiGenProductId: 3887,
            LexiDrugSynId: 22698,
            LexiSynonymTypeId: 60,
            LexiGenDrugId: "d03316",
            RxCUI: "1099308",
            OTC: true,
            NDC: "00113038326",
            Schedule: "0",
            DisplayName: "Aphedrid 1.25 mg-30 mg/5 mL syrup",
            MonographPath: "HTMLICONS/d03316a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: false,
            CompoundIngredients: null,
          },
          Clinician: {
            ClinicianId: 194644,
            Prefix: null,
            FirstName: "Test",
            MiddleName: null,
            LastName: "User1",
            Suffix: null,
            DateOfBirth: "1927-09-07T00:00:00",
            Email: null,
            Address1: "980 Washington St",
            Address2: "#330",
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "8888476814",
            PrimaryPhoneType: 7,
            PrimaryFax: "8888476814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            DEANumbers: [
              {
                DEANumber: "",
                State: "All(default)",
                ClinicId: null,
              },
            ],
            DEANumber: "",
            NADEANumbers: [],
            MedicalLicenseNumbers: [],
            NPINumber: "1839267899",
            Roles: [1],
            PDMPRoleType: null,
            SpecialtyTypeId: null,
            Confirmed: true,
            Active: true,
            AccountLocked: false,
            EpcsRequested: true,
            ClinicInfo: [
              {
                ClinicId: 25808,
                HasNewRx: true,
                HasRefills: true,
                HasRxChange: true,
                HasCancel: true,
                HasRxFill: true,
                HasEpcs: false,
              },
            ],
          },
          Clinic: {
            ClinicId: 25808,
            ClinicName: "Beluga Health",
            ClinicNameLongForm: "TravelMD",
            Address1: "980 Washington St",
            Address2: "#330",
            Active: true,
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "(888) 847-6814",
            PrimaryPhoneType: 5,
            PrimaryFax: "(888) 847-6814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
          },
          Pharmacy: {
            PharmacyId: 14973,
            StoreName: "Shollenberger Pharmacy",
            Address1: "2002 S. McDowell Blvd Ext",
            Address2: "",
            City: "Petaluma",
            State: "CA",
            ZipCode: "94954",
            PrimaryPhone: "7079845571",
            PrimaryPhoneType: 5,
            PrimaryFax: "7079884744",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            PharmacySpecialties: [
              "Long-Term Care Pharmacy",
              "Retail",
              "Specialty Pharmacy",
              "EPCS",
            ],
            ServiceLevel: 2079,
            Latitude: 38.229492,
            Longitude: -122.595968,
          },
          RequestedDate: "2023-10-04T18:47:08.587",
        },
        {
          RefillId: 221824,
          OriginalPrescriptionId: 59314480,
          NewPatient: false,
          Patient: {
            PatientId: 21013738,
            Prefix: null,
            FirstName: "Jacob",
            MiddleName: null,
            LastName: "Mink",
            Suffix: null,
            DateOfBirth: "1988-09-03T00:00:00",
            Gender: 1,
            Email: "jacobmink@gmail.com",
            Address1: "123 fake st.",
            Address2: null,
            City: "Faketown",
            State: "New York",
            ZipCode: "11111",
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditionalType1: 0,
            PhoneAdditionalType2: 0,
            PrimaryPhone: "7162615679",
            PrimaryPhoneType: 7,
            Weight: null,
            WeightMetric: 0,
            Height: null,
            HeightMetric: 0,
            NonDoseSpotMedicalRecordNumber: null,
            Active: true,
            Encounter: null,
            IsHospice: false,
          },
          RequestedPrescription: {
            PrescriptionId: 59314504,
            WrittenDate: "2023-10-04T18:34:43",
            Directions: "direction",
            Quantity: "4",
            DispenseUnitId: 26,
            DispenseUnitDescription: "Tablet(s)",
            Refills: "2",
            DaysSupply: 4,
            PharmacyId: 14973,
            PharmacyNotes:
              "Pharm Msg - Apply Patient Savings Program BIN - 610280, GRP - DOSERX, MBR ID - DS2345",
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: "2023-10-04T00:00:00",
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: true,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63474202,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "tablet",
            Route: "oral",
            Strength: "200 mg",
            GenericProductName: "ibuprofen 200 mg oral tablet",
            GenericDrugName: "ibuprofen",
            LexiGenProductId: 2065,
            LexiDrugSynId: 15821,
            LexiSynonymTypeId: 60,
            LexiGenDrugId: "d00015",
            RxCUI: "153008",
            OTC: true,
            NDC: "00573013320",
            Schedule: "0",
            DisplayName: "Advil 200 mg tablet",
            MonographPath: "HTMLICONS/d00015a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: true,
            CompoundIngredients: null,
          },
          DispensedPrescription: {
            PrescriptionId: 59314503,
            WrittenDate: "2023-10-04T18:34:43",
            Directions: "direction",
            Quantity: "4",
            DispenseUnitId: 26,
            DispenseUnitDescription: "Tablet(s)",
            Refills: "2",
            DaysSupply: 4,
            PharmacyId: 14973,
            PharmacyNotes:
              "Pharm Msg - Apply Patient Savings Program BIN - 610280, GRP - DOSERX, MBR ID - DS2345",
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: "2023-10-04T00:00:00",
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: true,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63474201,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "tablet",
            Route: "oral",
            Strength: "200 mg",
            GenericProductName: "ibuprofen 200 mg oral tablet",
            GenericDrugName: "ibuprofen",
            LexiGenProductId: 2065,
            LexiDrugSynId: 15821,
            LexiSynonymTypeId: 60,
            LexiGenDrugId: "d00015",
            RxCUI: "153008",
            OTC: true,
            NDC: "00573013320",
            Schedule: "0",
            DisplayName: "Advil 200 mg tablet",
            MonographPath: "HTMLICONS/d00015a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: true,
            CompoundIngredients: null,
          },
          Clinician: {
            ClinicianId: 194644,
            Prefix: null,
            FirstName: "Test",
            MiddleName: null,
            LastName: "User1",
            Suffix: null,
            DateOfBirth: "1927-09-07T00:00:00",
            Email: null,
            Address1: "980 Washington St",
            Address2: "#330",
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "8888476814",
            PrimaryPhoneType: 7,
            PrimaryFax: "8888476814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            DEANumbers: [
              {
                DEANumber: "",
                State: "All(default)",
                ClinicId: null,
              },
            ],
            DEANumber: "",
            NADEANumbers: [],
            MedicalLicenseNumbers: [],
            NPINumber: "1839267899",
            Roles: [1],
            PDMPRoleType: null,
            SpecialtyTypeId: null,
            Confirmed: true,
            Active: true,
            AccountLocked: false,
            EpcsRequested: true,
            ClinicInfo: [
              {
                ClinicId: 25808,
                HasNewRx: true,
                HasRefills: true,
                HasRxChange: true,
                HasCancel: true,
                HasRxFill: true,
                HasEpcs: false,
              },
            ],
          },
          Clinic: {
            ClinicId: 25808,
            ClinicName: "Beluga Health",
            ClinicNameLongForm: "TravelMD",
            Address1: "980 Washington St",
            Address2: "#330",
            Active: true,
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "(888) 847-6814",
            PrimaryPhoneType: 5,
            PrimaryFax: "(888) 847-6814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
          },
          Pharmacy: {
            PharmacyId: 14973,
            StoreName: "Shollenberger Pharmacy",
            Address1: "2002 S. McDowell Blvd Ext",
            Address2: "",
            City: "Petaluma",
            State: "CA",
            ZipCode: "94954",
            PrimaryPhone: "7079845571",
            PrimaryPhoneType: 5,
            PrimaryFax: "7079884744",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            PharmacySpecialties: [
              "Long-Term Care Pharmacy",
              "Retail",
              "Specialty Pharmacy",
              "EPCS",
            ],
            ServiceLevel: 2079,
            Latitude: 38.229492,
            Longitude: -122.595968,
          },
          RequestedDate: "2023-10-04T18:47:26.587",
        },
        {
          RefillId: 221787,
          OriginalPrescriptionId: 59308137,
          NewPatient: false,
          Patient: {
            PatientId: 21328669,
            Prefix: null,
            FirstName: "Perseus",
            MiddleName: null,
            LastName: "Abingdon",
            Suffix: null,
            DateOfBirth: "2012-09-01T00:00:00",
            Gender: 1,
            Email: "tran+perseus@belugahealth.com",
            Address1: "98 Bayview Ave",
            Address2: null,
            City: "New Rochelle",
            State: "New York",
            ZipCode: "10805",
            PhoneAdditional1: null,
            PhoneAdditional2: null,
            PhoneAdditionalType1: 0,
            PhoneAdditionalType2: 0,
            PrimaryPhone: "9168883366",
            PrimaryPhoneType: 7,
            Weight: null,
            WeightMetric: 0,
            Height: null,
            HeightMetric: 0,
            NonDoseSpotMedicalRecordNumber: null,
            Active: true,
            Encounter: null,
            IsHospice: false,
          },
          RequestedPrescription: {
            PrescriptionId: 59309037,
            WrittenDate: "2023-09-29T01:46:42",
            Directions: "Take 1 tablet orally once per day",
            Quantity: "30",
            DispenseUnitId: 26,
            DispenseUnitDescription: "Tablet(s)",
            Refills: "1",
            DaysSupply: 30,
            PharmacyId: 14973,
            PharmacyNotes:
              "Pharm Msg - Apply Patient Savings Program BIN - 610280, GRP - DOSERX, MBR ID - DS2345",
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: "2023-09-29T00:00:00",
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: true,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63467728,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "tablet",
            Route: "oral",
            Strength: "45 mg-200 mg",
            GenericProductName:
              "ledipasvir-sofosbuvir 45 mg-200 mg oral tablet",
            GenericDrugName: "ledipasvir-sofosbuvir",
            LexiGenProductId: 35946,
            LexiDrugSynId: 325473,
            LexiSynonymTypeId: 60,
            LexiGenDrugId: "d08296",
            RxCUI: "2203897",
            OTC: false,
            NDC: "61958180301",
            Schedule: "0",
            DisplayName: "Harvoni 45 mg-200 mg tablet",
            MonographPath: "HTMLICONS/d08296a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: true,
            CompoundIngredients: null,
          },
          DispensedPrescription: {
            PrescriptionId: 59309036,
            WrittenDate: "2023-09-29T01:46:42",
            Directions: "Take 1 tablet orally once per day",
            Quantity: "30",
            DispenseUnitId: 26,
            DispenseUnitDescription: "Tablet(s)",
            Refills: "1",
            DaysSupply: 30,
            PharmacyId: 14973,
            PharmacyNotes:
              "Pharm Msg - Apply Patient Savings Program BIN - 610280, GRP - DOSERX, MBR ID - DS2345",
            NoSubstitutions: true,
            EffectiveDate: null,
            LastFillDate: "2023-09-29T00:00:00",
            PrescriberId: 194644,
            PrescriberAgentId: null,
            RxReferenceNumber: null,
            Status: 8,
            Formulary: false,
            EligibilityId: 0,
            Type: 1,
            NonDoseSpotPrescriptionId: null,
            ErrorIgnored: false,
            SupplyId: null,
            CompoundId: null,
            FreeTextType: null,
            ClinicId: 25808,
            SupervisorId: null,
            IsUrgent: false,
            IsRxRenewal: true,
            RxRenewalNote: null,
            FirstPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            SecondPrescriptionDiagnosis: {
              PrimaryDiagnosis: {
                DiagnosisId: 0,
                DiagnosisCode: null,
                DiagnosisDescription: null,
              },
              SecondaryDiagnosis: null,
            },
            PatientMedicationId: 63467727,
            MedicationStatus: 1,
            Comment: null,
            DateInactive: null,
            Encounter: null,
            DoseForm: "tablet",
            Route: "oral",
            Strength: "45 mg-200 mg",
            GenericProductName:
              "ledipasvir-sofosbuvir 45 mg-200 mg oral tablet",
            GenericDrugName: "ledipasvir-sofosbuvir",
            LexiGenProductId: 35946,
            LexiDrugSynId: 325473,
            LexiSynonymTypeId: 60,
            LexiGenDrugId: "d08296",
            RxCUI: "2203897",
            OTC: false,
            NDC: "61958180301",
            Schedule: "0",
            DisplayName: "Harvoni 45 mg-200 mg tablet",
            MonographPath: "HTMLICONS/d08296a1.htm",
            DrugClassification: null,
            StateSchedules: [],
            Brand: true,
            CompoundIngredients: null,
          },
          Clinician: {
            ClinicianId: 194644,
            Prefix: null,
            FirstName: "Test",
            MiddleName: null,
            LastName: "User1",
            Suffix: null,
            DateOfBirth: "1927-09-07T00:00:00",
            Email: null,
            Address1: "980 Washington St",
            Address2: "#330",
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "8888476814",
            PrimaryPhoneType: 7,
            PrimaryFax: "8888476814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            DEANumbers: [
              {
                DEANumber: "",
                State: "All(default)",
                ClinicId: null,
              },
            ],
            DEANumber: "",
            NADEANumbers: [],
            MedicalLicenseNumbers: [],
            NPINumber: "1839267899",
            Roles: [1],
            PDMPRoleType: null,
            SpecialtyTypeId: null,
            Confirmed: true,
            Active: true,
            AccountLocked: false,
            EpcsRequested: true,
            ClinicInfo: [
              {
                ClinicId: 25808,
                HasNewRx: true,
                HasRefills: true,
                HasRxChange: true,
                HasCancel: true,
                HasRxFill: true,
                HasEpcs: false,
              },
            ],
          },
          Clinic: {
            ClinicId: 25808,
            ClinicName: "Beluga Health",
            ClinicNameLongForm: "TravelMD",
            Address1: "980 Washington St",
            Address2: "#330",
            Active: true,
            City: "Dedham",
            State: "Massachusetts",
            ZipCode: "02026",
            PrimaryPhone: "(888) 847-6814",
            PrimaryPhoneType: 5,
            PrimaryFax: "(888) 847-6814",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
          },
          Pharmacy: {
            PharmacyId: 14973,
            StoreName: "Shollenberger Pharmacy",
            Address1: "2002 S. McDowell Blvd Ext",
            Address2: "",
            City: "Petaluma",
            State: "CA",
            ZipCode: "94954",
            PrimaryPhone: "7079845571",
            PrimaryPhoneType: 5,
            PrimaryFax: "7079884744",
            PhoneAdditional1: null,
            PhoneAdditionalType1: 0,
            PhoneAdditional2: null,
            PhoneAdditionalType2: 0,
            PhoneAdditional3: null,
            PhoneAdditionalType3: 0,
            PharmacySpecialties: [
              "Long-Term Care Pharmacy",
              "Retail",
              "Specialty Pharmacy",
              "EPCS",
            ],
            ServiceLevel: 2079,
            Latitude: 38.229492,
            Longitude: -122.595968,
          },
          RequestedDate: "2023-09-29T15:13:44.967",
        },
      ],
      Result: {
        ResultCode: "OK",
        ResultDescription: "",
      },
    },
  },
};

export default detailsResponse;

import { useEffect, useState } from "react";
import { routerMap } from "../utils/router";

const { getPharmacies } = routerMap;

/**
 * Hook to get pharmacy names
 * @returns {{ pharmacyNames: { [id: string]: string }, isLoading: boolean }}
 */
const usePharmacyNames = () => {
  const [pharmacyNames, setPharmacyNames] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPharmacyNames = async () => {
      setIsLoading(true);
      const token = localStorage.getItem("jwt");
      const pharmacies = await getPharmacies(token);
      if (pharmacies) {
        const pharamcyIdNameMap = {};
        pharmacies.forEach((pharmacy) => {
          pharamcyIdNameMap[pharmacy.pharmacyId] = pharmacy.name;
        });
        setPharmacyNames(pharamcyIdNameMap);
      }
      setIsLoading(false);
    };

    fetchPharmacyNames();
  }, []);

  return { pharmacyNames, isLoading };
};

export default usePharmacyNames;

export const timeAt = (time) => {
  const then = new Date(time);
  let hours = then.getHours();
  let ampm = "am";
  if (hours >= 12) {
    if (hours === 24 || hours === 12) {
      hours = 12;
    } else {
      hours = hours % 12;
    }
    ampm = "pm";
  }
  let minutes = then.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  const month = then.getMonth() + 1;
  const date = then.getDate();
  const year = then.getFullYear();
  const middleDot = "\u00B7";
  const prettyThen = `${month}/${date}/${year} ${middleDot} ${hours}:${minutes}${ampm}`;
  return prettyThen;
};

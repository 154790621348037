import React, { useState } from "react";
import { API } from "aws-amplify";

import smallSearch from "../Assets/Icon/search.png";
import xIcon from "../Assets/Icon/x@2x.png";

import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

import "./MyAccount.css";

const SearchBar = (props) => {
  const { patients, user, setCurrentPatient, admin } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [foundPatientsState, setFoundPatientsState] = useState(patients);
  const [show, setShow] = useState(false);

  const token = localStorage.getItem("jwt");

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleHide = () => {
    setShow(false);
  };

  const getPatientSearchResult = async (e) => {
    if (e.keyCode === 13) {
      try {
        const response = await API.post("brains", "/patient/search", {
          credentials: "include",
          body: {
            searchTerm,
            admin,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status !== 200) {
          console.error(response);
        }
        // sort by patient with most recent visit updateTimestamp
        setFoundPatientsState(response.data);
        setShow(true);
      } catch (err) {
        return err;
      }
    }
  };

  const getMasterIdSearchResult = async (e) => {
    if (e.keyCode === 13) {
      try {
        const response = await API.post("brains", "/patient/searchMasterId", {
          credentials: "include",
          body: {
            searchTerm,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status !== 200) {
          console.error(response);
        }
        // sort by patient with most recent visit updateTimestamp
        setFoundPatientsState(response.data);
        setShow(true);
      } catch (err) {
        return err;
      }
    }
  };

  const goToPatient = (e) => {
    const foundPatient = foundPatientsState.find(
      (patient) => patient._id === e.currentTarget.id
    );
    if (foundPatient) {
      setCurrentPatient(foundPatient);
    }
  };

  const patientVisitsCompanies = (patient) => {
    return [...new Set(patient.visits.map((visit) => visit.company))].join(",");
  };

  const patientList = foundPatientsState.map((patient) => {
    return (
      <div
        key={patient._id}
        id={patient._id}
        onClick={goToPatient}
        className="listCard searchCard"
      >
        <div>
          {patient.firstName} {patient.lastName}
        </div>
        <div>{patient.state}</div>
        <div>{patientVisitsCompanies(patient)}</div>
      </div>
    );
  });

  return (
    <div>
      <InputGroup className="searchInput">
        <InputGroup.Text>
          <img src={smallSearch} alt="Search Icon" />
        </InputGroup.Text>
        <FormControl
          name="searchTerm"
          placeholder="Search"
          onChange={handleChange}
          onKeyUp={getPatientSearchResult}
        />
      </InputGroup>

      <InputGroup className="searchInput">
        <InputGroup.Text>
          <img src={smallSearch} alt="Search Icon" />
        </InputGroup.Text>
        <FormControl
          name="searchTerm"
          placeholder="Search MasterId"
          onChange={handleChange}
          onKeyUp={getMasterIdSearchResult}
        />
      </InputGroup>

      <Modal show={show} onHide={handleHide} dialogClassName="modalDims">
        <div className="modalHeader">
          <div className="modalTitle">
            <span>Found Patients from search term "{searchTerm}"</span>
            <button onClick={handleHide} className="modalCloseButton">
              <img src={xIcon} className="xIcon" alt="close" />
            </button>
          </div>
        </div>
        <div className="listTitleRow listLabels">
          <div>PATIENT NAME</div>
          <div>PATIENT STATE</div>
          <div>VISIT COMPANIES</div>
        </div>
        {patientList}
      </Modal>
    </div>
  );
};

export default SearchBar;

import React, { useEffect, useState } from "react";
// import DOMPurify from 'dompurify';

import { routerMap } from "../utils/router";

import "../Visit/Visit.css";

const FilesToReviewVisit = (props) => {
  const { patient, updatePatient, visit, user, setCurrentVisit, admin } = props;
  const [activeMainTab, setActiveMainTab] = useState("formData");
  const [csvRecords, setCsvRecords] = useState([]);

  const token = localStorage.getItem("jwt");

  const { getCsvRecords, getOutputFile } = routerMap;

  const Tab = (props) => {
    const { title, onTabClick, activeTab, label } = props;
    const tabClass = `tabStyle ${label === activeTab ? "activeTab" : ""}`;

    const handleClick = () => {
      onTabClick(label);
    };
    // const stickyTab =
    //   <div className='stickyWrapper'>
    //     <button
    //       className={tabClass}
    //       onClick={handleClick}
    //       title={title}
    //       value={label}
    //     >
    //       {title}

    //     </button>
    //     <div className="unreadCSmessages" />
    //   </div>;
    const regTab = (
      <button
        className={tabClass}
        onClick={handleClick}
        title={title}
        value={label}
      >
        {title}
      </button>
    );
    // const result = title === 'Orders' && orders.length > 0 && orders.find(order => order.status === 'admin')
    //   ?
    //     stickyTab
    //   :
    //     regTab;
    const result = regTab;
    return result;
  };

  const Tabs = (props) => {
    const { activeMainTab, onSelect, children } = props;

    const tabArray = children.map((child) => {
      if (child) {
        return (
          <Tab
            key={child.props.title}
            title={child.props.title}
            onTabClick={onSelect}
            activeTab={activeMainTab}
            label={child.props.label}
          />
        );
      } else {
        return null;
      }
    });

    const contentArray = children.map((child) => {
      if (child && child.props.label === activeMainTab) {
        return child.props.children;
      } else {
        return null;
      }
    });

    return (
      <>
        <div className="tabList">{tabArray}</div>
        <div className="tabContentsContainer">{contentArray}</div>
      </>
    );
  };

  const changeTab = async (tab) => {
    setActiveMainTab(tab);
  };

  const handleReviewedClick = async () => {
    await getOutputFile(
      token,
      visit._id,
      patient._id,
      setCurrentVisit,
      updatePatient
    );
  };

  const headerRow = () => {
    const data = csvRecords.flat()[0];
    const mappedKeys = {
      consentsSigned: "Consents signed?",
      patientId: "Patient ID",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      dateOfBirth: "Date of Birth",
      sex: "Sex",
      state: "State",
      country: "Country",
      externalTestWithinLastYear: "Test taken within last year",
      condition: "Condition",
      icdCode: "ICD Code(s)",
    };
    if (data) {
      const mappedHeaders = data.split(",").map((cell, idx) => {
        const stripped = cell.replace(/\W/g, "");
        return (
          <th key={`${cell}${idx}`}>
            <h5>{mappedKeys[stripped]}</h5>
          </th>
        );
      });
      return <tr>{mappedHeaders}</tr>;
    }
    return null;
  };

  const formObjRows = () => {
    const data = csvRecords;
    if (data) {
      const mappedDataRows = data.map((rowString, idx) => {
        const splitArray = rowString
          .replace("false", '"false"')
          .replace("true", '"true"')
          .split(/[\W\D],[\W\D]/g);
        const arrayed = splitArray.map((column) => {
          return <td>{column}</td>;
        });

        if (idx === 0) {
          return <tr key={idx}></tr>;
        } else {
          return <tr key={idx}>{arrayed}</tr>;
        }
      });
      return mappedDataRows;
    }
    return null;
  };

  useEffect(() => {
    const asyncGetRecords = async () => {
      await getCsvRecords(token, visit._id, setCsvRecords);
    };
    asyncGetRecords();
  }, []);

  return (
    <div className="visitContent">
      <div className="filesTabContainer">
        <Tabs
          activeMainTab={activeMainTab}
          transition={false}
          id="visitTabs"
          onSelect={changeTab}
          className="tabs"
        >
          <div
            label="formData"
            title="Patient Data List"
            tabClassName="tabStyle"
          >
            <div className="formObjContainer tabContentContainer">
              <table>
                <thead className="sticky">{headerRow()}</thead>
                <tbody>{formObjRows()}</tbody>
              </table>
            </div>
          </div>
          {user.admin && admin && (
            <div label="admin" title="Admin" tabClassName="tabStyle">
              <div className="tabContentContainer">
                <h3>Manually change visit status</h3>
                {/* <ul className="ulNoStyle">
                  {adminStatuses}
                </ul> */}
              </div>
            </div>
          )}
        </Tabs>
      </div>
      <div className="csAndResolveContainer">
        <button
          onClick={handleReviewedClick}
          disabled={visit.status === "resolved"}
        >
          Reviewed
        </button>
      </div>
    </div>
  );
};

export default FilesToReviewVisit;

import React, { useState } from "react";
import PropTypes from "prop-types";

import FavoriteForm from "./FavoriteForm";
import ModalButton from "../../Components/ModalButton";
import "../../MyAccount/MyAccount.css";

const FavoriteModalFormButton = (props) => {
  const {
    btnTitle,
    btnClassname,
    defaultFormData,
    onFormSubmit,
    onFormReject,
    onSuccess,
    favorites,
    visitTypes,
    companies,
    mode,
    formCompareTo,
  } = props;
  const modalTitle = props.modalTitle || btnTitle;

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (formData) => {
    const { supplyId, ...updatedFormData } = formData;
    const dataToSubmit = formData.supplyId > 0 ? formData : updatedFormData;

    const res = await onFormSubmit(dataToSubmit, setErrorMessage);
    if (res) {
      onSuccess();
      setErrorMessage("");
      setShowModal(false);
    }
  };

  const handleReject = async (formData) => {
    const res = await onFormReject(formData, setErrorMessage);
    if (res) {
      onSuccess();
      setErrorMessage("");
      setShowModal(false);
    }
  };

  return (
    <ModalButton
      btnLabel={btnTitle}
      btnClassname={btnClassname}
      modalTitle={modalTitle}
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <FavoriteForm
        mode={mode}
        favorites={favorites}
        defaultFormData={defaultFormData}
        compareTo={formCompareTo}
        visitTypes={visitTypes}
        companies={companies}
        onSubmit={handleSubmit}
        onReject={handleReject}
        errorMessage={errorMessage}
      />
    </ModalButton>
  );
};

FavoriteModalFormButton.propTypes = {
  btnTitle: PropTypes.string.isRequired,
  btnClassname: PropTypes.string,
  defaultFormData: PropTypes.object,
  onFormSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  favorites: PropTypes.array.isRequired,
  visitTypes: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  mode: PropTypes.string,
  formCompareTo: PropTypes.object,
  modalTitle: PropTypes.string,
};

export default FavoriteModalFormButton;

import React, { useEffect } from 'react';
import './SuccessNotification.css';

const SuccessNotification = ({ showNotificationsModal, setShowNotificationsModal }) => {
  useEffect(() => {
    console.log(showNotificationsModal)
    if (showNotificationsModal.state) {
      setTimeout(() => {
        setShowNotificationsModal({state: false, position: { top: 0, left: 0 }});
      }, 1000);
    }
  }, [showNotificationsModal]);

  return (
    <>
      {showNotificationsModal.state && (
        <div 
          className="success-notification" 
          style={{ top: `${showNotificationsModal.position.top - 92}px`, left: `${showNotificationsModal.position.left + 60}px` }}
        >
          <span>Copied to clipboard</span>
        </div>
      )}
    </>
  );
};

export default SuccessNotification;

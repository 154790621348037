import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import classnames from "classnames";
import PrescriptionSearch from "./prescription/PrescriptionSearch";
import { PrescriptionFilterTypesMap } from "./prescription/utils/prescriptionTypeUtils";
import "./FormInputs.css";
import { routerMap } from "../utils/router";

const FormInputs = (props) => {
  const { config, formData, compareTo = {}, onChange } = props;
  const [currentMedicine, setCurrentMedicine] = useState(null);
  const addMedicine = (med) => {
    const supplyId = formData.type === "supply" ? med.med.SupplyId : null;

    const newFormData = {
      ...formData,
      medication: med.value,
      supplyId: supplyId,
      strength: "",
    };
    setCurrentMedicine(med);
    onChange(newFormData);
  };

  const handleNumberOnBlur = (e) => {
    const { value, name } = e.target;

    if (name === "supplyId" && Number(value) <= 0) {
      const { supplyId, ...updatedFormData } = formData;
      let newFormData = formData.supplyId > 0 ? formData : updatedFormData;
      onChange(newFormData);
    }
  };
  const handleChange = (e) => {
    const { value, name, type } = e.target;

    const newFormData =
      name === "type"
        ? { ...formData, medication: "", strength: "", dispenseUnit: "" }
        : { ...formData };

    if (name === "type") {
      setCurrentMedicine(null);
      if (name !== "supplyId") {
        delete newFormData["supplyId"];
      }
    }

    const configInput = config.flat().find((input) => input.name === name);

    let newValue;
    switch (type) {
      case "number":
        newValue = Number(value) ?? 0;
        break;
      case "checkbox": {
        const previousValue = formData[name];
        if (value === "All") {
          newValue = [...configInput.options];
        } else if (value === "None") {
          newValue = [];
        } else {
          const formDataIndex = previousValue && previousValue.indexOf(value);
          if (previousValue && previousValue.includes(value)) {
            previousValue.splice(formDataIndex, 1);
            newValue = previousValue;
          } else {
            newValue = previousValue ? [...previousValue, value] : [value];
          }
        }
        break;
      }
      default:
        newValue = value;
    }

    newFormData[name] = newValue;

    onChange(newFormData);
  };

  const getMedicationDetails = async (strength) => {
    const token = localStorage.getItem("jwt");
    try {
      const res = await routerMap.getMedicationDetails(
        token,
        formData.medication,
        strength
      );

      const dispenseUnit = res ? res?.DispenseUnitName.toLowerCase() : null;

      return onChange({ ...formData, dispenseUnit: dispenseUnit });
    } catch (err) {}
  };

  useEffect(() => {
    //autofill Dispense Unit after medication strength is set
    if (formData.type === "med") getMedicationDetails(formData.strength);
  }, [formData.strength]);

  const formInput = (input, hideList) => {
    if (Object.values(hideList).some((value) => value === true)) {
      return <></>;
    }

    const displaySearch =
      input.type === "search" && formData.type !== "compound";

    const strengthSelect =
      currentMedicine && input.name === "strength" && formData.type === "med";

    if (displaySearch) {
      const selectionType =
        formData.type === "med"
          ? PrescriptionFilterTypesMap.medicine.key
          : PrescriptionFilterTypesMap.supply.key;
      return (
        <div
          className={`searchMedHolder ${formData.medication ? "selected" : ""}`}
        >
          <PrescriptionSearch
            prescriptionType={selectionType}
            currentMedicine={currentMedicine || { label: formData.medication }}
            onMedicineSelected={addMedicine}
            selectedMedDisplay={true}
          />
        </div>
      );
    } else if (strengthSelect) {
      const { name } = input;
      return (
        <Form.Select
          name={name}
          value={formData[name]}
          onChange={handleChange}
          className={"formInput py-0"}
        >
          <option value="" />
          {currentMedicine?.med?.Strength?.map((option) => (
            <option key={option.NDC} value={option.Strength}>
              {option.Strength}
            </option>
          ))}
        </Form.Select>
      );
    } else {
      switch (input.type) {
        case "select": {
          const { name, options } = input;
          return (
            <Form.Select
              name={name}
              value={formData[name]}
              onChange={handleChange}
              className={"formInput py-0"}
            >
              <option value="" />
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label ?? option.value}
                </option>
              ))}
            </Form.Select>
          );
        }

        case "creatableSelect": {
          const { name, text, placeholder, options, locked } = input;
          return (
            <>
              <input
                name={name}
                list={name}
                placeholder={placeholder || text}
                onChange={handleChange}
                type="text"
                className={"formInput py-0"}
                value={formData[name] || ""}
                disabled={locked}
              />
              <datalist id={name}>
                {options.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label ?? value}
                  </option>
                ))}
              </datalist>
            </>
          );
        }

        case "checkbox": {
          const { name, options } = input;
          const checkedCompanies = options.sort().map((companyName) => {
            const isChecked = formData.company.includes(companyName);
            return (
              <div className="checkGroup" key={companyName}>
                <input
                  type="checkbox"
                  value={companyName}
                  name={name}
                  onChange={handleChange}
                  checked={isChecked}
                  className="checkBox"
                />
                <span>{companyName}</span>
              </div>
            );
          });
          return (
            <div className="scrolling checkedStates">
              <div className="checkGroup">
                <input
                  type="checkbox"
                  value="All"
                  name={name}
                  onChange={handleChange}
                  checked={formData.company.length === options.length}
                  className="checkBox"
                />
                <span>All</span>
              </div>
              <div className="checkGroup">
                <input
                  type="checkbox"
                  value="None"
                  name={name}
                  onChange={handleChange}
                  checked={formData.company.length === 0}
                  className="checkBox"
                />
                <span>None</span>
              </div>

              {checkedCompanies}
            </div>
          );
        }
        default: {
          const { name, text, required, placeholder, type, locked } = input;

          let value;
          if (type === "number") {
            if (
              !required &&
              !formData[name] &&
              typeof formData[name] !== "number"
            ) {
              value = "";
            } else {
              value = Number(formData[name] || "");
            }
          } else {
            value = formData[name] || "";
          }
          return (
            <input
              name={name}
              placeholder={placeholder || text}
              onChange={handleChange}
              onBlur={handleNumberOnBlur}
              type={type || "text"}
              className="formInput"
              value={value}
              min={0}
              disabled={locked}
              step="any"
            />
          );
        }
      }
    }
  };

  const formRow = (row, index) => (
    <div className="formRow" key={index}>
      {row.map((input, index) => {
        const { name, text, required } = input;
        const haveToCompare =
          compareTo[name] !== undefined && compareTo[name] !== "";
        const inputEqualPending =
          haveToCompare &&
          !!formData[name]?.toString() &&
          compareTo[name].toString() === formData[name].toString();
        const compareToClass = classnames(
          "formInput-compareTo",
          inputEqualPending
            ? "formInput-compareEqual"
            : "formInput-compareNotEqual"
        );

        const hideList = {
          med: input.type === "search" && !formData.type,
          strength:
            input.name === "strength" &&
            (!formData.type ||
              (!formData.medication && formData.type === "med")),
          supplyId: input.name === "supplyId" && formData.type !== "supply",
        };

        return (
          <div key={index}>
            {Object.values(hideList).every((value) => value === false) && (
              <div className="formField">{`${
                input.type === "search" && formData.type === "supply"
                  ? "Supply"
                  : text
              } ${required ? "*" : ""}`}</div>
            )}
            {formInput(input, hideList)}
            {haveToCompare && (
              <div className="formField">
                Update:
                <span className={compareToClass}>
                  {compareTo[name].toString()}
                </span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );

  return config.map(formRow);
};

export default FormInputs;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import FavoriteModalFormButton from "../components/FavoriteModalFormButton";
import FavoriteTable from "../components/FavoriteTable";
import {
  sortFavorites,
  tableKeyTitles,
  generateRandomClientName,
} from "./utils";
import { routerMap } from "../../utils/router";
import ModalButton from "../../Components/ModalButton";

const FavoriteCreate = (props) => {
  const { favorites, visitTypes, companies, onSuccess } = props;
  const { createFavorite } = routerMap;

  const defaultFormData = {
    clientName: generateRandomClientName(),
  };

  const handleFormSubmit = async (formData, setErrorMessage) => {
    const token = localStorage.getItem("jwt");
    const res = await createFavorite(token, setErrorMessage, formData);
    if (res && res.favorite) {
      return res;
    }
    return false;
  };

  return (
    <FavoriteModalFormButton
      btnTitle="Create"
      modalTitle="Create Favorite"
      defaultFormData={defaultFormData}
      onFormSubmit={handleFormSubmit}
      onSuccess={onSuccess}
      favorites={favorites}
      visitTypes={visitTypes}
      companies={companies}
      mode="create"
    />
  );
};
FavoriteCreate.propTypes = {
  favorites: PropTypes.array.isRequired,
  visitTypes: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

const FavoriteDuplicate = (props) => {
  const { favorite, favorites, visitTypes, companies, onSuccess } = props;
  const { createFavorite } = routerMap;

  const handleFormSubmit = async (formData, setErrorMessage) => {
    const token = localStorage.getItem("jwt");
    const res = await createFavorite(token, setErrorMessage, formData);
    if (res && res.favorite) {
      return res;
    }
    return false;
  };

  const defaultFormData = {
    ...favorite,
    clientName: generateRandomClientName(),
  };

  return (
    <FavoriteModalFormButton
      btnTitle="Duplicate"
      btnClassname="favorite-duplicate"
      modalTitle="Duplicate Favorite"
      defaultFormData={defaultFormData}
      onFormSubmit={handleFormSubmit}
      onSuccess={onSuccess}
      favorites={favorites}
      visitTypes={visitTypes}
      companies={companies}
      mode="create"
    />
  );
};
FavoriteDuplicate.propTypes = {
  favorite: PropTypes.object.isRequired,
  favorites: PropTypes.array.isRequired,
  visitTypes: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

const FavoriteUpdate = (props) => {
  const { favorite, favorites, visitTypes, companies, onSuccess } = props;
  const { updateFavorite } = routerMap;

  const handleFormSubmit = async (formData, setErrorMessage) => {
    const token = localStorage.getItem("jwt");
    const res = await updateFavorite(token, setErrorMessage, formData);
    if (res && res.favorite) {
      return res;
    }
    return false;
  };

  return (
    <FavoriteModalFormButton
      btnTitle="Edit"
      modalTitle="Update Details"
      defaultFormData={favorite}
      onFormSubmit={handleFormSubmit}
      onSuccess={onSuccess}
      favorites={favorites}
      visitTypes={visitTypes}
      companies={companies}
      mode="edit"
    />
  );
};
FavoriteUpdate.propTypes = {
  favorite: PropTypes.object.isRequired,
  favorites: PropTypes.array.isRequired,
  visitTypes: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

const FavoriteDelete = (props) => {
  const { favorite, onSuccess } = props;

  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleHide = () => {
    setShow(false);
  };

  const handleSubmit = async () => {
    const { deleteFavorite } = routerMap;
    const token = localStorage.getItem("jwt");
    const isDeleted = await deleteFavorite(token, setErrorMessage, favorite);
    if (isDeleted) {
      onSuccess();
    }
  };

  return (
    <ModalButton
      btnLabel="Delete"
      btnClassname="favorite-delete"
      modalTitle="Delete Favorite"
      showModal={show}
      setShowModal={setShow}
    >
      <div className="favorite-delete-modal">
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <span>
          Are you sure you want to delete favorite "{favorite.favoriteName}" ?
        </span>
        <div className="favorite-delete-btns">
          <button
            className="createFavoriteButton favorite-btn"
            // className="createUserButton favorite-btn"
            onClick={handleHide}
          >
            No
          </button>
          <button
            className="createFavoriteButton favorite-btn"
            // className="createUserButton favorite-btn"
            onClick={handleSubmit}
          >
            Yes
          </button>
        </div>
      </div>
    </ModalButton>
  );
};

const FavoritesTableTab = (props) => {
  const {
    user,
    favorites = [],
    visitTypes = [],
    companies = [],
    onSuccess,
    adminIsLoading,
  } = props;

  const favoriteList = sortFavorites(favorites);

  const titleOverrides = {
    createUpdate:
      user.systemAdmin &&
      (() => (
        <FavoriteCreate
          favorites={favorites}
          visitTypes={visitTypes}
          companies={companies}
          onSuccess={onSuccess}
        />
      )),
  };

  const rowOverrides = {
    createUpdate:
      user.systemAdmin &&
      ((value, element) => (
        <div className="favorite-buttons">
          <FavoriteDuplicate
            favorites={favorites}
            favorite={element}
            visitTypes={visitTypes}
            companies={companies}
            onSuccess={onSuccess}
          />
          <FavoriteUpdate
            favorites={favorites}
            favorite={element}
            visitTypes={visitTypes}
            companies={companies}
            onSuccess={onSuccess}
          />
          <FavoriteDelete
            favorites={favorites}
            favorite={element}
            visitTypes={visitTypes}
            companies={companies}
            onSuccess={onSuccess}
          />
        </div>
      )),
    allowSubstitutions: (value) => (
      <Form.Check disabled defaultChecked={value} />
    ),
  };

  return (
    <div className="favorites-page">
      {adminIsLoading ? (
        <div>Loading...</div>
      ) : (
        <FavoriteTable
          list={favoriteList}
          getRowKey={(element) => element._id}
          tableKeyTitles={tableKeyTitles}
          titleOverrides={titleOverrides}
          rowOverrides={rowOverrides}
        />
      )}
    </div>
  );
};
FavoritesTableTab.propTypes = {
  user: PropTypes.object.isRequired,
  favorites: PropTypes.array.isRequired,
  visitTypes: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default FavoritesTableTab;

import React from "react";

import NewCompanyForm from "./NewCompanyForm";

import xIcon from "../Assets/Icon/x@2x.png";

import Modal from "react-bootstrap/Modal";

import "../MyAccount/MyAccount.css";

const CreateCompanyModal = (props) => {
  const title = props.editMode ? "Edit company" : "Create a new company";
  return (
    <Modal
      size="lg"
      show={props.show}
      onHide={props.handleClose}
      dialogClassName="modalDims"
    >
      <div className="modalHeader">
        <div className="modalTitle">
          <span>{title}</span>
          <button onClick={props.handleClose} className="modalCloseButton">
            <img src={xIcon} className="xIcon" alt="close" />
          </button>
        </div>
      </div>
      <NewCompanyForm
        closeModal={props.handleClose}
        curEditingCompany={props.curEditingCompany}
        companies={props.companies}
        setCompanies={props.setCompanies}
        editMode={props.editMode}
      />
    </Modal>
  );
};

export default CreateCompanyModal;

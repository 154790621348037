export const getAllMedsData = {
  status: 200,
  data: {
    patient: {
      pharmacy: {
        id: "14973",
      },
      _id: "6555a4dc86b1dd0030ff761c",
      firstName: "Tran Test",
      lastName: "Tu",
      dob: "11/01/1997",
      sex: "Female",
      email: "tran+test+dosespot@belugahealth.com",
      phone: "+19168968123",
      address: "27-B Heald St",
      city: "Pepperell",
      state: "MA",
      zip: "01463",
      chat: [
        {
          messages: [
            {
              senderId: "101010",
              senderName: "Robo Doc",
              senderType: "doctor",
              content:
                "Hi Tran Test,\nThank you for scheduling your consultation regarding TestVisit on 11/15 via undefined.\nIf this consultation was created in error, please reply stop.\nWelcome to your visit with Beluga Health. You will receive a text message from the doctor within 24 hours.\nIn the meantime, Tran Test, do you have any questions for the doctor?\nRESPONSE REQUIRED to proceed with your visit.",
              _id: "6555a4de86b1dd0030ff7622",
              createdAt: "2023-11-16T05:13:02.228Z",
              updatedAt: "2023-11-16T05:13:02.228Z",
            },
          ],
          company: "dosespotTesting",
          visitType: "testVisit",
          _id: "6555a4de86b1dd0030ff7621",
        },
      ],
      selfReportedMeds: "None",
      allergies: "None",
      medicalConditions: "None",
      visits: ["6555a4dc86b1dd0030ff761e"],
      stripeIDs: [],
      medicationsPrescribed: [
        {
          name: "Amoxapine 50 mg tablet",
          numberDispensed: "31",
          numberRefills: "12",
          datePrescribed: "2023-11-18T06:58:30.777Z",
          prescriber: "Unknown Doctor",
          _id: "65593cbbc91df93cb9066db2",
        },
        {
          name: "Amoxapine 25 mg tablet",
          numberDispensed: "5",
          numberRefills: "1",
          datePrescribed: "2023-11-16T12:19:56.697Z",
          prescriber: "Unknown Doctor",
          _id: "65593cbbc91df93cb9066db3",
        },
      ],
      otherMedicationsPrescribed: [],
      authnetIDs: [],
      __v: 4,
      dosespotID: "21435165",
    },
    replacedMedList: [
      {
        PrescriptionId: 59348597,
        WrittenDate: "2023-11-17T23:58:30.777",
        Directions: "Take it everyday",
        Quantity: "31",
        DispenseUnitId: 26,
        DispenseUnitDescription: "Tablet(s)",
        Refills: "12",
        DaysSupply: 33,
        PharmacyId: 14973,
        PharmacyNotes: "N/A",
        NoSubstitutions: false,
        EffectiveDate: null,
        LastFillDate: null,
        PrescriberId: "Unknown Doctor",
        PrescriberAgentId: null,
        RxReferenceNumber: null,
        Status: 2,
        Formulary: false,
        EligibilityId: 0,
        Type: 1,
        NonDoseSpotPrescriptionId: null,
        ErrorIgnored: false,
        SupplyId: null,
        CompoundId: null,
        FreeTextType: null,
        ClinicId: 25808,
        SupervisorId: null,
        IsUrgent: false,
        IsRxRenewal: false,
        RxRenewalNote: null,
        FirstPrescriptionDiagnosis: null,
        SecondPrescriptionDiagnosis: null,
        PatientMedicationId: 63514150,
        MedicationStatus: 1,
        Comment: null,
        DateInactive: null,
        Encounter: null,
        DoseForm: "tablet",
        Route: "oral",
        Strength: "50 mg",
        GenericProductName: "amoxapine 50 mg oral tablet",
        GenericDrugName: "amoxapine",
        LexiGenProductId: 1226,
        LexiDrugSynId: 17712,
        LexiSynonymTypeId: 59,
        LexiGenDrugId: "d00874",
        RxCUI: "197366",
        OTC: false,
        NDC: "00591571401",
        Schedule: "0",
        DisplayName: "Amoxapine 50 mg tablet",
        MonographPath: "HTMLICONS/d00874a1.htm",
        DrugClassification: "psychotherapeutic agents",
        StateSchedules: null,
        Brand: false,
        CompoundIngredients: null,
        name: "Amoxapine 50 mg tablet",
        datePrescribed: "2023-11-17T23:58:30.777",
        prescriber: "Unknown Doctor",
        numberDispensed: "31",
        numberRefills: "12",
      },
      {
        PrescriptionId: 59346568,
        WrittenDate: "2023-11-16T05:19:56.697",
        Directions: "hello direction",
        Quantity: "5",
        DispenseUnitId: 26,
        DispenseUnitDescription: "Tablet(s)",
        Refills: "1",
        DaysSupply: 2,
        PharmacyId: 14973,
        PharmacyNotes: "N/A",
        NoSubstitutions: true,
        EffectiveDate: null,
        LastFillDate: null,
        PrescriberId: "Unknown Doctor",
        PrescriberAgentId: null,
        RxReferenceNumber: null,
        Status: 1,
        Formulary: false,
        EligibilityId: 0,
        Type: 1,
        NonDoseSpotPrescriptionId: null,
        ErrorIgnored: false,
        SupplyId: null,
        CompoundId: null,
        FreeTextType: null,
        ClinicId: 25808,
        SupervisorId: null,
        IsUrgent: false,
        IsRxRenewal: false,
        RxRenewalNote: null,
        FirstPrescriptionDiagnosis: null,
        SecondPrescriptionDiagnosis: null,
        PatientMedicationId: 63511846,
        MedicationStatus: 1,
        Comment: null,
        DateInactive: null,
        Encounter: null,
        DoseForm: "tablet",
        Route: "oral",
        Strength: "25 mg",
        GenericProductName: "amoxapine 25 mg oral tablet",
        GenericDrugName: "amoxapine",
        LexiGenProductId: 1223,
        LexiDrugSynId: 17711,
        LexiSynonymTypeId: 59,
        LexiGenDrugId: "d00874",
        RxCUI: "197365",
        OTC: false,
        NDC: "00591571301",
        Schedule: "0",
        DisplayName: "Amoxapine 25 mg tablet",
        MonographPath: "HTMLICONS/d00874a1.htm",
        DrugClassification: "psychotherapeutic agents",
        StateSchedules: null,
        Brand: false,
        CompoundIngredients: null,
        name: "Amoxapine 25 mg tablet",
        datePrescribed: "2023-11-16T05:19:56.697",
        prescriber: "Unknown Doctor",
        numberDispensed: "5",
        numberRefills: "1",
      },
    ],
    pharmacyIdsData: {
      14973: {
        PharmacyId: 14973,
        StoreName: "Shollenberger Pharmacy",
        Address1: "2002 S. McDowell Blvd Ext",
        Address2: "",
        City: "Petaluma",
        State: "CA",
        ZipCode: "94954",
        PrimaryPhone: "7079845571",
        PrimaryPhoneType: 5,
        PrimaryFax: "7079884744",
        PhoneAdditional1: null,
        PhoneAdditionalType1: 0,
        PhoneAdditional2: null,
        PhoneAdditionalType2: 0,
        PhoneAdditional3: null,
        PhoneAdditionalType3: 0,
        PharmacySpecialties: [
          "Long-Term Care Pharmacy",
          "Retail",
          "Specialty Pharmacy",
          "EPCS",
        ],
        ServiceLevel: 2079,
        Latitude: 38.229492,
        Longitude: -122.595968,
      },
    },
  },
};

import React from "react";
import { Form } from "react-bootstrap";
import FavoriteTable from "../components/FavoriteTable";
import {
  generateRandomClientName,
  sortFavorites,
  tableKeyTitles,
} from "./utils";
import { routerMap } from "../../utils/router";
import FavoriteModalFormButton from "../components/FavoriteModalFormButton";
import PropTypes from "prop-types";

const PendingNewModalFormButton = (props) => {
  const { pending, favorites, visitTypes, companies, onSuccess, handleReject } =
    props;
  const { createFavorite } = routerMap;

  const defaultFormData = { ...pending };
  defaultFormData.clientName = pending.clientName || generateRandomClientName();

  const handleFormSubmit = async (formData, setErrorMessage) => {
    const token = localStorage.getItem("jwt");
    const res = await createFavorite(token, setErrorMessage, formData, true);
    if (res && res.favorite) {
      return res;
    }
    return false;
  };

  return (
    <FavoriteModalFormButton
      btnTitle="Edit"
      modalTitle="Create from pending"
      defaultFormData={defaultFormData}
      onFormSubmit={handleFormSubmit}
      onFormReject={handleReject}
      onSuccess={onSuccess}
      favorites={favorites}
      visitTypes={visitTypes}
      companies={companies}
      mode="create"
    />
  );
};
PendingNewModalFormButton.propTypes = {
  pending: PropTypes.object.isRequired,
  favorites: PropTypes.array.isRequired,
  visitTypes: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

const PendingNewTableTab = (props) => {
  const {
    user,
    pendingNewList = [],
    favorites = [],
    visitTypes = [],
    companies = [],
    onSuccess,
    handleReject,
    adminIsLoading,
  } = props;

  const list = sortFavorites(pendingNewList);

  const rowOverrides = {
    createUpdate:
      user.systemAdmin &&
      ((value, element) => (
        <PendingNewModalFormButton
          pending={element}
          favorites={favorites}
          visitTypes={visitTypes}
          companies={companies}
          onSuccess={onSuccess}
          handleReject={handleReject}
        />
      )),
    allowSubstitutions: (value) => (
      <Form.Check disabled defaultChecked={value} />
    ),
  };

  return (
    <div className="favorites-page">
      {adminIsLoading ? (
        <div>Loading...</div>
      ) : !!pendingNewList.length ? (
        <FavoriteTable
          list={list}
          getRowKey={(element) => element._id}
          tableKeyTitles={tableKeyTitles}
          rowOverrides={rowOverrides}
        />
      ) : (
        <div className="favorites-page">None pending</div>
      )}
    </div>
  );
};
PendingNewTableTab.propTypes = {
  user: PropTypes.object.isRequired,
  pendingNewList: PropTypes.array.isRequired,
  favorites: PropTypes.array.isRequired,
  visitTypes: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default PendingNewTableTab;

import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
// import BelugaToast from '../Components/BelugaToast';

import { routerMap } from "../utils/router";
import { trackAdminTimeOnly } from "../utils/timeTrackingUtils";
// import { switchFullDS } from "../Components/prescription/utils/favoriteUtils.js";
import { states, stateAbbrevs } from "../DataFiles/states";

import Visit from "../Visit/Visit";
// import NotificationsButton from '../Components/notifications/NotificationsButton';
import FilesToReviewVisit from "../FilesToReviewVisit/FilesToReviewVisit";
// import NotificationsPopup from '../Components/notifications/NotificationsPopup';
import stickyNote from "../stickyNote.png";
import arrowDropdownIcon from "../Assets/Icon/arrowDropdown@2x.png";
import arrowLeft from "../Assets/Icon/arrowLeft@2x.png";
import xIcon from "../Assets/Icon/x@2x.png";
import menuDots from "../Assets/Icon/more@2x.png";

import "./Patient.css";

const Patient = (props) => {
  const { patient, user, admin } = props;

  const allNotes = patient.visits.map((visit) => {
    return visit.note;
  });

  const [updatedPatient, setUpdatedPatient] = useState(patient);
  const [currentVisit, setCurrentVisit] = useState({});
  // const [showNotificationsModal, setShowNotificationsModal] = useState(false)
  const [showChatModal, setShowChatModal] = useState(false);
  const [showPatientModal, setShowPatientModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [internalMessage, setInternalMessage] = useState({
    senderId: user._id,
    senderName: `${user.firstName} ${user.lastName}`,
    senderType: admin ? (user.dosespotID ? "admin" : "nondocAdmin") : "doctor",
    content: "",
  });
  const [patientDemo, setPatientDemo] = useState({});
  const [updateError, setUpdateError] = useState("");
  const [internalError, setInternalError] = useState("");
  const [visitResolutionText, setVisitResolutionText] = useState("");
  // const [rejectText, setRejectText] = useState('');
  const [currentVisitClosingDoc, setCurrentVisitClosingDoc] = useState(null);
  const [isPatientLoading, setIsPatientLoading] = useState(false);
  const [isLocalTechSupport, setIsLocalTechSupport] = useState(false);

  const token = localStorage.getItem("jwt");

  const middleDot = "\u00B7";

  const {
    updateVisit,
    updatePatientDemo,
    switchVisit,
    getVisit,
    updateDocTime,
    logEvent,
  } = routerMap;

  const isHourly =
    user.timeTrackingVisitTypes?.includes(currentVisit.visitType) &&
    user.timeTrackingCompanies?.includes(currentVisit.company);

  const handleUpdateVisit = async (visitId, data) => {
    const updatedRes = await updateVisit(
      token,
      user,
      patient._id,
      visitId,
      data,
      setCurrentVisit,
      setUpdatedPatient
    );

    return updatedRes;
  };

  const patientAge = () => {
    const dob = new Date(updatedPatient.dob);
    const ageDifMs = Date.now() - dob.getTime();
    const ageDate = new Date(ageDifMs);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return `${
      dob.getMonth() + 1
    }/${dob.getDate()}/${dob.getFullYear()} (${age}y)`;
  };

  const prettyDateAndTime = (date) => {
    const dated = new Date(date);
    const hours = dated.getHours().toString().padStart(2, "0");
    const minutes = dated.getMinutes().toString().padStart(2, "0");
    return `${
      dated.getMonth() + 1
    }-${dated.getDate()}-${dated.getFullYear()} ${hours}:${minutes}`;
  };

  const openChatModal = async (e) => {
    e.preventDefault();
    if (isHourly) {
      await updateDocTime(token);
    }
    setShowChatModal(true);
  };

  const handleCloseChat = () => {
    setShowChatModal(false);
    setInternalError("");
  };

  const handleMessageChange = (e) => {
    setInternalMessage({
      ...internalMessage,
      content: e.target.value,
    });
  };

  const handleSendInternalChat = async (recipientType) => {
    if (internalMessage.content === "") {
      setInternalError("Please type a message before sending!");
      return;
    }

    await trackAdminTimeOnly(user, admin, token);

    const messageWithDate = { ...internalMessage, createdAt: new Date() };
    let data = {
      visitUpdate: {
        status: admin ? "active" : "admin",
        updateTimestamp: new Date(),
        isTechSupport: admin ? false : isLocalTechSupport,
        isClinicianAdmin: admin ? false : currentVisit.isClinicianAdmin,
        isSuperAdmin: admin ? false : currentVisit.isSuperAdmin,
        internalChat:
          !currentVisit.internalChat ||
          !currentVisit.internalChat.chat ||
          currentVisit.internalChat.chat.length === 0
            ? {
                chat: [messageWithDate],
              }
            : {
                chat: [...currentVisit.internalChat.chat, messageWithDate],
              },
      },
    };
    if (currentVisit.resolvedTimestamp) {
      if (!admin) {
        data = {
          visitUpdate: {
            ...data.visitUpdate,
            currentDoc: null,
          },
        };
      } else {
        data = {
          visitUpdate: {
            ...data.visitUpdate,
            currentDoc: currentVisit.closingDoc,
          },
        };
      }
    }
    setInternalError("");
    setSubmitting(true);
    if (isHourly) {
      await updateDocTime(token);
    }
    await logEvent(token, "internalMessage", {
      userId: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      visitType: currentVisit.visitType,
      company: currentVisit.company,
      masterId: currentVisit.masterId,
    });
    await handleUpdateVisit(currentVisit._id, data);
    setSubmitting(false);
    setInternalMessage({
      ...internalMessage,
      content: "",
    });
    setShowChatModal(false);
  };

  const handleUpdatePatientDemo = async () => {
    await updatePatientDemo(
      token,
      patient._id,
      patientDemo,
      setUpdateError,
      setUpdatedPatient,
      setShowPatientModal,
      setPatientDemo
    );
  };

  const handleVisitClick = async (e) => {
    if (currentVisit._id?.toString() !== e.currentTarget.id) {
      const data = {
        visitUpdate: {
          currentDoc: user._id,
        },
      };
      let newVisit, closingDoc;
      if (Object.keys(currentVisit).length) {
        const newCurrentVisit = await switchVisit(
          token,
          currentVisit._id,
          e.currentTarget.id,
          admin
        );
        newVisit = newCurrentVisit.futureVisit;
        closingDoc = newCurrentVisit.closingDoc;
      } else {
        const response = await getVisit(token, e.currentTarget.id);
        newVisit = response.visit;
        closingDoc = response.closingDoc;
      }
      if (newVisit && newVisit.status === "active" && !admin) {
        if (!newVisit.currentDoc) {
          newVisit = await handleUpdateVisit(newVisit._id, data);
        }
      }

      setVisitResolutionText("");
      setCurrentVisit(newVisit);
      setCurrentVisitClosingDoc(closingDoc);
    }
  };

  const openPatientModal = () => {
    setShowPatientModal(true);
  };

  const handleClosePatientModal = () => {
    setShowPatientModal(false);
    setPatientDemo({});
    setUpdateError("");
  };

  const handlePatientChange = (e) => {
    if (!e.target.value) {
      const ogDemo = patientDemo;
      delete ogDemo[e.target.name];
      setUpdateError("");
      setPatientDemo(ogDemo);
    } else {
      if (
        e.target.value &&
        e.target.name === "sex" &&
        !["Male", "Female", "Other"].includes(e.target.value)
      ) {
        setUpdateError("Please enter valid value for patient sex");
      } else if (
        e.target.value &&
        e.target.name === "dob" &&
        !/^[0,1]{1}[0-9]{1}\/[0-3]{1}[0-9]{1}\/[0-9]{4}$/.test(e.target.value)
      ) {
        setUpdateError(
          "Please enter valid value for patient date of birth (must be formatted like MM/DD/YYYY)"
        );
      } else {
        setUpdateError("");
      }
      setPatientDemo({
        ...patientDemo,
        [e.target.name]: e.target.value,
      });
    }
  };

  const timestamp = (visit, timeType) => {
    const updateDate = new Date(visit.updateTimestamp);
    if (timeType === "resolved") {
      const date = new Date(visit.resolvedTimestamp);
      return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
    } else if (timeType === "canceled") {
      const date = new Date(visit.cancelTimestamp);
      return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
    } else {
      return `${
        updateDate.getMonth() + 1
      }-${updateDate.getDate()}-${updateDate.getFullYear()}`;
    }
  };

  const statusStyle = {
    active: "activeVisit",
    pending: "pendingVisit",
    resolved: "resolvedVisit",
    admin: "adminVisit",
  };

  const visitTypeCaps = (visitType) => {
    return visitType[0].toUpperCase() + visitType.slice(1);
  };

  const chatBubbleClass = (message) => {
    let className = `chatBubblePatient internalChat${message.senderType} tightMessage`;
    if (
      (admin && message.senderType === "admin") ||
      (!admin && message.senderType !== "admin")
    ) {
      className += " messageRight";
    } else if (
      (admin && message.senderType !== "admin") ||
      (!admin && message.senderType === "admin")
    ) {
      className += " messageLeft";
    }
    return className;
  };

  const setClinicianAdmin = async () => {
    const data = {
      visitUpdate: {
        isClinicianAdmin: !currentVisit.isClinicianAdmin,
        status: "admin",
      },
    };
    await logEvent(token, "adminMarkClinical", {
      userId: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      visitId: currentVisit._id,
      visitType: currentVisit.visitType,
      masterId: currentVisit.masterId,
    });
    await handleUpdateVisit(currentVisit._id, data);
  };

  const setSuperAdmin = async () => {
    const data = {
      visitUpdate: {
        isSuperAdmin: !currentVisit.isSuperAdmin,
        status: "admin",
      },
    };
    await logEvent(token, "adminMarkSuper", {
      userId: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      visitId: currentVisit._id,
      visitType: currentVisit.visitType,
      masterId: currentVisit.masterId,
    });
    await handleUpdateVisit(currentVisit._id, data);
  };

  const setTechSupport = async () => {
    const data = {
      visitUpdate: {
        isTechSupport: !currentVisit.isTechSupport,
        status: "admin",
      },
    };
    await handleUpdateVisit(currentVisit._id, data);
  };

  const setLocalTechSupport = () => {
    setIsLocalTechSupport(!isLocalTechSupport);
  };

  const checkForUnread = (visit) => {
    if (
      visit.internalChat &&
      visit.internalChat.chat &&
      visit.internalChat.chat.length > 0
    ) {
      const chat = visit.internalChat.chat;
      const lastChat = chat[chat.length - 1];
      // if (lastChat) {
      //   switch(lastChat.senderType) {
      //     case('admin'):
      //       if (admin && user.dosespotID) {
      //         return false;
      //       } else if (admin) {
      //         return true;
      //       } else {
      //         return true;
      //       }
      //     case('nondocAdmin'):
      //       if (admin && user.dosespotID) {
      //         return true;
      //       } else if (admin) {
      //         return false;
      //       } else {
      //         return true;
      //       }
      //     case('doctor'):
      //       break;
      //     default:
      //       return false
      //   }
      // }
      if (
        admin &&
        lastChat &&
        lastChat.senderType !== "admin" &&
        lastChat.senderType !== "nondocAdmin"
      ) {
        return true;
      } else if (
        !admin &&
        lastChat &&
        (lastChat.senderType === "admin" ||
          lastChat.senderType === "nondocAdmin") &&
        (visit.currentDoc === user._id || !currentVisit.currentDoc)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const dosespotButtonStyle = patientDemo.dosespotID
    ? "dosespotDeleteButtonClicked"
    : "dosespotDeleteButton";

  const visitInternalChat =
    currentVisit &&
    currentVisit.internalChat &&
    currentVisit.internalChat.chat &&
    currentVisit.internalChat.chat.map((message) => {
      const prettyDate = prettyDateAndTime(message.createdAt);
      return (
        <li key={message._id} className={chatBubbleClass(message)}>
          <p>{message.content}</p>
          <small>
            Sent by {message.senderName},{" "}
            {message.senderType === "admin" ||
            message.senderType === "nondocAdmin"
              ? "Admin"
              : "Clinician"}
          </small>
          <small>Sent on {prettyDate}</small>
        </li>
      );
    });

  const visitList = (visits) => {
    const mapVisit = (visit) => {
      const capitalStatus =
        visit.status[0].toUpperCase() + visit.status.slice(1);
      const isCurrent =
        visit._id === currentVisit._id ? "currentVisitCard" : "";
      const isRead = checkForUnread(visit);
      const visitDate = new Date(visit.updateTimestamp);
      const prettyDate = `${
        visitDate.getMonth() + 1
      }-${visitDate.getDate()}-${visitDate.getFullYear()}`;
      const visitCreationDate = new Date(visit.createTimestamp);
      const prettyCreationDate = `${
        visitCreationDate.getMonth() + 1
      }-${visitCreationDate.getDate()}-${visitCreationDate.getFullYear()}`;
      const sticky =
        visit.internalChat.chat && visit.internalChat.chat.length > 0 ? (
          <div className="stickyWrapper" onClick={openChatModal}>
            <img src={stickyNote} className="stickyNote" alt="stickyNote" />
            {isRead && <div className="unreadMessages" />}
          </div>
        ) : null;
      return (
        <div
          key={visit._id}
          id={visit._id}
          className={`visitCard ${isCurrent}`}
          onClick={handleVisitClick}
        >
          <div>
            {sticky}
            <div>{visitTypeCaps(visit.visitType)}</div>
            <div
              className={`${statusStyle[`${visit.status}`]}Text visitStatus`}
            >
              <div className="oval"></div>
              {capitalStatus}
            </div>
            {currentVisit && currentVisit._id !== visit._id ? (
              <b>Go to this visit</b>
            ) : (
              <b>Current Visit</b>
            )}

            <div className="smallText">
              <em>Created on: {prettyCreationDate}</em>
            </div>
            <div className="smallText">
              <em>Updated on: {prettyDate}</em>
            </div>
            {visit.resolvedTimestamp && (
              <div className="smallText">
                <em>Resolved on: {timestamp(visit, "resolved")}</em>{" "}
                <span>
                  {visit.isRefer === true ||
                  !Object.keys(visit).includes("isRefer") ? (
                    <span>(Referred)</span>
                  ) : (
                    <span>(Prescribed)</span>
                  )}
                </span>
              </div>
            )}
            {visit.cancelTimestamp && (
              <div className="smallText">
                <em>Cancelled on: {timestamp(visit, "canceled")}</em>
              </div>
            )}
          </div>
          <img src={arrowLeft} alt="arrowRight" />
        </div>
      );
    };

    const filterSortMapVisits = (visits) => {
      return visits
        .filter((visit) => {
          const isReturn = admin
            ? true
            : user.acceptableVisitTypes.includes(visit.visitType);
          return isReturn;
        })
        .sort((a, b) => {
          return new Date(b.createTimestamp) - new Date(a.createTimestamp);
        })
        .map((visit) => mapVisit(visit));
    };

    return <>{filterSortMapVisits(visits)}</>;
  };

  const prescriptionList = updatedPatient.medicationsPrescribed.length ? (
    updatedPatient.medicationsPrescribed.map((med, i) => {
      const medDate = new Date(med.datePrescribed);
      const prettyDate = `${
        medDate.getMonth() + 1
      }-${medDate.getDate()}-${medDate.getFullYear()}`;
      return (
        <Dropdown.Item key={i} className="prescriptionCard">
          <div className="rxTitle">{med.name}</div>
          <div className="smallText">Refills: {med.numberRefills}</div>
          <div className="smallText">Pill Number: {med.numberDispensed}</div>
          <div className="smallText">Written on: {prettyDate}</div>
          <div className="smallText">Prescribed by: {med.prescriber}</div>
          <div className="smallText">Sent to: {med.pharmacyName}</div>
        </Dropdown.Item>
      );
    })
  ) : (
    <Dropdown.Item className="prescriptionCard">None</Dropdown.Item>
  );
  const statusText = () => {
    switch (currentVisit.status) {
      case "active":
        return (
          <span className="visitStatus activeVisitText">
            <div className="oval" />
            Active Visit
          </span>
        );
      case "pending":
        return (
          <span className="visitStatus pendingVisitText">
            <div className="oval" />
            Pending patient action
          </span>
        );
      case "resolved":
        return (
          <span className="visitStatus resolvedVisitText">
            <div className="oval" />
            Resolved Visit
          </span>
        );
      case "admin":
        return (
          <span className="visitStatus adminVisitText">
            <div className="oval" />
            Pending admin action
          </span>
        );
      default:
        return null;
    }
  };

  // const sidebarClass = !Object.keys(currentVisit).length ? "patientSidebar" : "patientSidebar patientSidebarHover";

  const stateListLocal = states.map((state) => {
    return (
      <option value={stateAbbrevs[states.indexOf(state)]} key={state}>
        {state}
      </option>
    );
  });

  useEffect(() => {
    if (checkForUnread(currentVisit)) {
      setShowChatModal(true);
    }
    setIsLocalTechSupport(currentVisit.isTechSupport);
  }, [currentVisit]);

  useEffect(() => {
    setUpdatedPatient(patient);
    setVisitResolutionText("");
    setCurrentVisit({});
  }, [patient]);

  return (
    <div className="patientContainer">
      {/* <div className={sidebarClass}> */}
      <div className="patientSidebar">
        <div className="patientDemoCard">
          {admin && <h4>Admin view</h4>}
          <div>
            <div className="patientName">
              {updatedPatient.firstName} {updatedPatient.middleName}{" "}
              {updatedPatient.lastName}
            </div>
            <div className="patientDetails">
              {updatedPatient.sex} {middleDot} {patientAge()} {middleDot}{" "}
              {updatedPatient.state}
              <img
                src={menuDots}
                className="menuDots"
                onClick={openPatientModal}
                alt="menuDots"
              />
            </div>
            <div className="patientDetails">
              Phone:{" "}
              {updatedPatient.phone[0] === "+"
                ? updatedPatient.phone.slice(2)
                : updatedPatient.phone}
            </div>
            {updatedPatient.visits.some(
              (visit) => visit.company === "jase"
            ) && (
              <div className="patientDetails">
                Communication Preference: {updatedPatient.commPreference}
              </div>
            )}
          </div>
          <Dropdown drop="right">
            <Dropdown.Toggle className="rxButton" id="rxButton">
              Patient Prescriptions
              <img src={arrowDropdownIcon} alt="arrowDown" />
            </Dropdown.Toggle>
            <Dropdown.Menu>{prescriptionList}</Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="visitListTitle">
          All Visits ({updatedPatient.visits.length})
        </div>
        <div className="visitCardContainer">
          {visitList(updatedPatient.visits)}
        </div>
      </div>
      {Object.keys(currentVisit).length ? (
        <div className="visitContainer">
          <div className="visitTitle">
            {" "}
            {currentVisit.company} {visitTypeCaps(currentVisit.visitType)}{" "}
          </div>
          <div className="visitHeader">
            <div>
              {statusText()}
              <div className="visitStatus">
                Master Id: {currentVisit.masterId}
              </div>
              {admin && !!currentVisit.resolvedTimestamp && (
                <div className="visitStatus">
                  Closing Doctor:{" "}
                  {currentVisitClosingDoc || "No closing doctor"}
                </div>
              )}
            </div>
            {currentVisit.currentDoc &&
              currentVisit.currentDoc !== user._id && (
                <div className="diffDocText">
                  This visit is currently assigned to a different clinician,
                  please select another active visit
                </div>
              )}
            <div className="supportAndAdminSet">
              {/* <NotificationsButton onClick={() => { setShowNotificationsModal(true) }}/> */}
              {currentVisit.internalChat &&
                currentVisit.internalChat.chat &&
                currentVisit.internalChat.chat.length === 0 && (
                  <button onClick={openChatModal} className="chatButton">
                    Admin/Doctor Chat
                  </button>
                )}
              {admin && (
                <>
                  <div className="checkboxLine">
                    <input
                      type="checkbox"
                      onClick={setTechSupport}
                      id="docTechSupportCheck"
                      checked={currentVisit.isTechSupport}
                    />
                    <label
                      htmlFor="docTechSupportCheck"
                      className="docAdminCheckLabel"
                    >
                      Tech Support
                    </label>
                  </div>
                  <div className="checkboxLine">
                    <input
                      type="checkbox"
                      onClick={setClinicianAdmin}
                      id="docAdminCheck"
                      checked={!!currentVisit.isClinicianAdmin}
                    />
                    <label
                      htmlFor="docAdminCheck"
                      className="docAdminCheckLabel"
                    >
                      Clinical admin
                    </label>
                  </div>

                  {!!currentVisit.isClinicianAdmin && (
                    <div className="checkboxLine">
                      <input
                        type="checkbox"
                        onClick={setSuperAdmin}
                        id="docSuperCheck"
                        checked={!!currentVisit.isSuperAdmin}
                      />
                      <label
                        htmlFor="docSuperCheck"
                        className="docAdminCheckLabel"
                      >
                        Super admin
                      </label>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {!currentVisit.currentDoc ||
          currentVisit.currentDoc === user._id ||
          admin ? (
            currentVisit.visitType === "filesToReview" ? (
              <FilesToReviewVisit
                patient={updatedPatient}
                updatePatient={setUpdatedPatient}
                user={user}
                visit={currentVisit}
                updateVisit={handleUpdateVisit}
                setCurrentVisit={setCurrentVisit}
                admin={admin}
              />
            ) : (
              <Visit
                patient={updatedPatient}
                user={user}
                visit={currentVisit}
                allNotes={allNotes}
                updateVisit={handleUpdateVisit}
                updatePatient={setUpdatedPatient}
                setCurrentVisit={setCurrentVisit}
                admin={admin}
                visitResolutionText={visitResolutionText}
                setVisitResolutionText={setVisitResolutionText}
                visitInternalChat={visitInternalChat}
                isPatientLoading={isPatientLoading}
                setIsPatientLoading={setIsPatientLoading}
                isHourly={isHourly}
              />
            )
          ) : (
            <div className="visitContainer">
              <h3>
                This visit is currently assigned to a different clinician,
                please select another active visit
              </h3>
            </div>
          )}
        </div>
      ) : (
        <div className="visitContainer">
          <h3>Please select a visit</h3>
        </div>
      )}

      <Modal
        show={showPatientModal}
        onHide={handleClosePatientModal}
        dialogClassName="patientDemoModal"
      >
        <div className="modalHeader">
          <div className="modalTitle">
            <span>Patient Demographics</span>
            <button
              onClick={handleClosePatientModal}
              className="modalCloseButton"
            >
              <img src={xIcon} className="xIcon" alt="close" />
            </button>
          </div>
        </div>
        <div className="modalBody">
          <div className="patientFormStyle">
            {updateError && <div className="internalError">{updateError}</div>}
            <div className="formRow">
              <div>
                <div className="formField">First Name</div>
                <input
                  name="firstName"
                  placeholder="First Name"
                  onChange={handlePatientChange}
                  className="formInput"
                />
              </div>
              <div>
                <div className="formField">Last Name</div>
                <input
                  name="lastName"
                  placeholder="Last Name"
                  onChange={handlePatientChange}
                  className="formInput"
                />
              </div>
            </div>
            <div className="formRow">
              <div>
                <div className="formField">
                  Date of Birth (FORMAT: MM/DD/YYYY)
                </div>
                <input
                  name="dob"
                  placeholder="Enter patient's date of birth"
                  onChange={handlePatientChange}
                  className="formInput"
                />
              </div>
              <div>
                <div className="formField">
                  Sex (ONLY "Male", "Female", "Other")
                </div>
                <input
                  name="sex"
                  placeholder="Update patient's sex"
                  onChange={handlePatientChange}
                  className="formInput"
                />
              </div>
            </div>
            {admin && (
              <>
                <div className="formRow">
                  <div>
                    <div className="formField">Phone Number</div>
                    <input
                      name="phone"
                      placeholder="Enter patient's phone number"
                      onChange={handlePatientChange}
                      className="formInput"
                    />
                  </div>
                  <div>
                    <div className="formField">Email</div>
                    <input
                      name="email"
                      placeholder="Enter patient's email"
                      onChange={handlePatientChange}
                      className="formInput"
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div>
                    <div className="formField">Address</div>
                    <input
                      name="address"
                      placeholder="Enter patient's street address"
                      onChange={handlePatientChange}
                      className="formInput"
                    />
                  </div>
                  <div>
                    <div className="formField">City</div>
                    <input
                      name="city"
                      placeholder="Enter patient's city"
                      onChange={handlePatientChange}
                      className="formInput"
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div>
                    <div className="formField">State</div>
                    <select
                      name="state"
                      onChange={handlePatientChange}
                      defaultValue={updatedPatient.state}
                    >
                      {stateListLocal}
                    </select>
                  </div>
                  <div>
                    <div className="formField">Zip Code</div>
                    <input
                      name="zip"
                      placeholder="Enter patient's zip code"
                      onChange={handlePatientChange}
                      className="formInput"
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div>
                    <div className="formField">Delete Dosespot ID</div>
                    <button
                      onClick={handlePatientChange}
                      className={dosespotButtonStyle}
                      name="dosespotID"
                      value="0"
                    >
                      {patientDemo.dosespotID
                        ? "Dosespot ID will be deleted"
                        : "Delete Patient Dosespot ID"}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="modalFooter">
          <button onClick={handleClosePatientModal} className="patientButton">
            Cancel
          </button>
          <button
            className="patientButton"
            onClick={handleUpdatePatientDemo}
            disabled={!!updateError}
          >
            Update
          </button>
        </div>
      </Modal>

      <Modal
        show={showChatModal}
        onHide={handleCloseChat}
        dialogClassName="internalChatModal"
      >
        <div className="modalHeader">
          <div className="modalTitle">
            <span>Admin/Doctor Chat</span>
            <button onClick={handleCloseChat} className="modalCloseButton">
              <img src={xIcon} className="xIcon" alt="close" />
            </button>
          </div>
        </div>
        <div className="modalBody">
          <div className="scrollable">
            <ul>{submitting ? <li>Sending...</li> : visitInternalChat}</ul>
          </div>
          <span className="internalError">{internalError}</span>
          <div>
            <textarea
              rows={5}
              placeholder="Enter chat message here"
              onChange={handleMessageChange}
              value={internalMessage.content}
            />
            {/* <div className="checkboxLine">
                <input
                  type="checkbox"
                  onClick={setLocalTechSupport}
                  id="docTechSupportCheck"
                  checked={isLocalTechSupport}
                />
                <label
                  htmlFor="docTechSupportCheck"
                  className="docAdminCheckLabel"
                >
                  Tech Support
                </label>
              </div> */}
          </div>
        </div>
        <div className="modalFooter">
          <button onClick={handleCloseChat} className="patientButton">
            Cancel
          </button>
          <button onClick={handleSendInternalChat} className="patientButton">
            OK
          </button>
          {/* {admin && user.dosespotID && <button onClick={handleSendChatToNondocAdmin} className="patientButton">Send to non-clinician admin</button>} */}
          {/* {admin && !user.dosespotID && <button onClick={handleSendInternalChat} className="patientButton">Send to clinician admin</button>} */}
        </div>
      </Modal>
      {/* <NotificationsPopup
        user={user}
        patient={patient}
        currentVisit={currentVisit}
        showModal={showNotificationsModal}
        setModalState={setShowNotificationsModal} /> */}

      <Modal
        show={isPatientLoading}
        backdrop="static"
        dialogClassName="loadingModal"
      >
        <div className="modalBody">
          <h2>System processing...</h2>
        </div>
      </Modal>
      {/* <BelugaToast /> */}
    </div>
  );
};

export default Patient;

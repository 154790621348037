import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import { routerMap } from "../utils/router";

import xIcon from "../Assets/Icon/x@2x.png";
import "../MyAccount/MyAccount.css";

const ActivePendingPatients = (props) => {
  const { patients, setCurrentPatient, visitCompanyFilter } = props;
  const [showActivePendingModal, setShowActivePendingModal] = useState(false);
  const [localPatients, setLocalPatients] = useState(patients);

  const token = localStorage.getItem("jwt");

  const { getPatient } = routerMap;

  const hideActivePendingModal = () => {
    setShowActivePendingModal(false);
  };

  const openActivePendingModal = () => {
    setShowActivePendingModal(true);
  };

  const handlePatientClick = async (e) => {
    await getPatient(token, e.currentTarget.id, setCurrentPatient);
  };

  const filterVisitsActivePending = () => {
    const allPatientsWithActive = localPatients.filter(
      (patient) =>
        patient.visits.filter((visit) => visit.status === "active").length > 0
    );
    const activeVisits = allPatientsWithActive
      .map((patient) => {
        return patient.visits.filter((visit) => visit.status === "active");
      })
      .flat();
    const allPatientsWithPending = localPatients.filter(
      (patient) =>
        patient.visits.filter((visit) => visit.status === "pending").length > 0
    );
    const pendingVisits = allPatientsWithPending
      .map((patient) => {
        return patient.visits.filter((visit) => visit.status === "pending");
      })
      .flat();
    const visitsWithCompany = [...activeVisits, ...pendingVisits].filter(
      (visit) => visit.company === visitCompanyFilter
    );
    if (visitCompanyFilter === "All Companies") {
      return activeVisits.length + pendingVisits.length;
    } else {
      return visitsWithCompany.length;
    }
  };

  const patientVisitsCompanies = (visits) => {
    const companiesSet = [...new Set(visits.map((visit) => visit.company))];
    return companiesSet.map((company) => <div key={company}>{company}</div>);
  };

  const patientList = localPatients
    .map((patient) => {
      const sortedActivePending = patient.visits
        .filter(
          (visit) => visit.status === "active" || visit.status === "pending"
        )
        .sort(
          (a, b) => new Date(b.updateTimestamp) - new Date(a.updateTimestamp)
        );

      return (
        <div
          key={sortedActivePending[0].updateTimestamp}
          id={patient._id}
          onClick={handlePatientClick}
          className="listCard searchCard"
        >
          <div>
            {patient.firstName} {patient.lastName}
          </div>
          <div>{patient.state}</div>
          <div>{patientVisitsCompanies(sortedActivePending)}</div>
        </div>
      );
    })
    .sort((a, b) => new Date(b.key) - new Date(a.key));

  // company filter
  useEffect(() => {
    if (visitCompanyFilter !== "All Companies") {
      const filteredPatients = patients.filter((patient) =>
        patient.visits.find(
          (visit) =>
            visit.company === visitCompanyFilter &&
            (visit.status === "active" || visit.status === "pending")
        )
      );
      setLocalPatients(filteredPatients);
    } else {
      setLocalPatients(patients);
    }
  }, [visitCompanyFilter]);

  useEffect(() => {
    setLocalPatients(patients);
  }, [patients]);

  return (
    <>
      <div onClick={openActivePendingModal} className="dashLink">
        {filterVisitsActivePending()}
      </div>

      <Modal
        show={showActivePendingModal}
        onHide={hideActivePendingModal}
        dialogClassName="modalDims"
      >
        <div className="modalHeader">
          <div className="modalTitle">
            <span>Patients with active/pending visits</span>
            <button
              onClick={hideActivePendingModal}
              className="modalCloseButton"
            >
              <img src={xIcon} className="xIcon" alt="close" />
            </button>
          </div>
        </div>
        <div className="listTitleRow listLabels">
          <div className="nameWrap">PATIENT NAME</div>
          <div>PATIENT STATE</div>
          <div>ACTIVE/PENDING VISIT COMPANIES</div>
        </div>
        {patientList}
      </Modal>
    </>
  );
};

export default ActivePendingPatients;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./CollapsibleContent.css";

const CollapsibleContent = (props) => {
  const {
    id,
    tabStyle,
    contentStyle,
    containerStyle,
    title,
    body,
    openContentState,
  } = props;
  const [openContent, setOpenContent] = useState(openContentState);

  const handleOnClick = () => {
    setOpenContent(!openContent);
  };

  const bodyExtendedClassName = openContent
    ? "contentExtended"
    : "contentCollapsed";

  useEffect(() => {
    setOpenContent(openContentState);
  }, [openContentState]);

  return (
    <div
      className="collapsibleContentContainer"
      key={id}
      style={containerStyle}
    >
      <div
        className="collapsibleContentHeader"
        onClick={handleOnClick}
        style={tabStyle}
      >
        <div className="collapsibleContentTitle">{title}</div>
        <div className="collapsibleContentStateIcon">
          {openContent ? "-" : "+"}
        </div>
      </div>
      <div
        className={`collapsibleContentBody ${bodyExtendedClassName}`}
        style={contentStyle}
      >
        {body}
      </div>
    </div>
  );
};

CollapsibleContent.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  openContentState: PropTypes.bool,
  contentStyle: PropTypes.object,
  containterStyle: PropTypes.object,
  tabStyle: PropTypes.object,
  body: PropTypes.any,
};

export default CollapsibleContent;

import React, { useState, useRef } from "react";

import "../MyAccount/MyAccount.css";
import { routerMap } from "../utils/router";
import Select from "react-select";

/**
 * Component to display option to show platform companies and select one
 */
const PlatformCompanySelect = (props) => {
  const { editMode, formData, handleChange } = props;
  const { getPlatformCompanyNames } = routerMap;
  const companySelectRef = useRef();

  const [platformCompanies, setPlatformCompanies] = useState([]);
  const [loadingPlatformCompanies, setLoadingPlatformCompanies] =
    useState(false);

  const getPlatformCompanies = async () => {
    const token = localStorage.getItem("jwt");
    await getPlatformCompanyNames(token, setPlatformCompanies);
    setLoadingPlatformCompanies(false);
  };

  const handleSelectionChange = (e) => {
    const { value } = e.target;
    let isPlatformSubCompany;
    if (value === "no") {
      isPlatformSubCompany = false;
    } else {
      if (platformCompanies.length === 0) {
        setLoadingPlatformCompanies(true);
        getPlatformCompanies();
      }
      isPlatformSubCompany = true;
    }

    // for company selected, just emit event as is, the parent will handle setting formData
    handleChange(e, {
      isPlatformSubCompany,
    });
  };

  const selectOptions = platformCompanies.map((company) => {
    return { value: company.name, label: company.name };
  });

  const handleSelectOnChange = (selectedOption) => {
    handleSelectionChange({
      target: { name: "platformCompanyName", value: selectedOption.value },
    });
  };

  return (
    <>
      {!editMode && (
        <div className="formRow">
          <div>
            <div className="formField">Platform Sub Company</div>
            <div className="chatMethodCheckboxHolder">
              <div className="checkGroup" key="isPlatformSubCompany-No">
                <input
                  type="radio"
                  value="no"
                  checked={!formData["isPlatformSubCompany"]}
                  name="isPlatformSubCompany"
                  onChange={handleSelectionChange}
                  className="checkBox"
                />
                <span>No</span>
              </div>
              <div className="checkGroup" key="isPlatformSubCompany-Yes">
                <input
                  type="radio"
                  value="yes"
                  checked={formData["isPlatformSubCompany"]}
                  name="isPlatformSubCompany"
                  onChange={handleSelectionChange}
                  className="checkBox"
                />
                <span>Yes</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {formData["isPlatformSubCompany"] && !editMode && (
        <div className="formRow">
          <div style={{ width: "100%" }}>
            <div className="formField">
              Platform Company
              {!editMode && <span className="userFormError"> (Required)</span>}
            </div>
            <div className="platformCompanySelect">
              <Select
                ref={companySelectRef}
                className="platformCompanySelect disabled-select"
                placeholder={
                  loadingPlatformCompanies
                    ? "Loading platform companies..."
                    : "Select a platform company"
                }
                onChange={handleSelectOnChange}
                options={selectOptions}
                isLoading={loadingPlatformCompanies}
                isDisabled={loadingPlatformCompanies}
                isSearchable={true}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PlatformCompanySelect;

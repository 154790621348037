export const successfullSearch = {
  status: 200,
  data: [
    {
      Name: "Amoxapine (oral - tablet)",
      Strength: [
        {
          Strength: "25 mg",
          NDC: "00591571301",
        },
        {
          Strength: "50 mg",
          NDC: "00591571401",
        },
        {
          Strength: "100 mg",
          NDC: "00591571501",
        },
        {
          Strength: "150 mg",
          NDC: "00591571630",
        },
      ],
    },
    {
      Name: "Amoxicillin (oral - capsule)",
      Strength: [
        {
          Strength: "250 mg",
          NDC: "00093310701",
        },
        {
          Strength: "500 mg",
          NDC: "00093310905",
        },
      ],
    },
    {
      Name: "Amoxicillin (oral - tablet)",
      Strength: [
        {
          Strength: "500 mg",
          NDC: "00093226301",
        },
        {
          Strength: "875 mg",
          NDC: "00093226401",
        },
      ],
    },
    {
      Name: "Amoxicillin (oral - tablet, chewable)",
      Strength: [
        {
          Strength: "125 mg",
          NDC: "00093226701",
        },
        {
          Strength: "250 mg",
          NDC: "00093226801",
        },
      ],
    },
    {
      Name: "Amoxicillin Liquid (oral - powder for reconstitution)",
      Strength: [
        {
          Strength: "250 mg/5 mL",
          NDC: "00093415573",
        },
        {
          Strength: "200 mg/5 mL",
          NDC: "00093416073",
        },
        {
          Strength: "400 mg/5 mL",
          NDC: "00093416173",
        },
        {
          Strength: "125 mg/5 mL",
          NDC: "00143988801",
        },
      ],
    },
    {
      Name: "Amoxicillin-Clavulanate (oral - tablet)",
      Strength: [
        {
          Strength: "500 mg-125 mg",
          NDC: "00093227434",
        },
        {
          Strength: "875 mg-125 mg",
          NDC: "00093227534",
        },
        {
          Strength: "250 mg-125 mg",
          NDC: "00781187431",
        },
      ],
    },
    {
      Name: "Amoxicillin-Clavulanate (oral - tablet, chewable)",
      Strength: [
        {
          Strength: "200 mg-28.5 mg",
          NDC: "00093227034",
        },
        {
          Strength: "400 mg-57 mg",
          NDC: "00093227234",
        },
      ],
    },
    {
      Name: "Amoxicillin-Clavulanate (oral - tablet, extended release)",
      Strength: [
        {
          Strength: "1000 mg-62.5 mg",
          NDC: "00781194339",
        },
      ],
    },
    {
      Name: "Amoxicillin-Clavulanate Liquid (oral - powder for reconstitution)",
      Strength: [
        {
          Strength: "200 mg-28.5 mg/5 mL",
          NDC: "00093227773",
        },
        {
          Strength: "400 mg-57 mg/5 mL",
          NDC: "00093227973",
        },
        {
          Strength: "600 mg-42.9 mg/5 mL",
          NDC: "00093867574",
        },
        {
          Strength: "250 mg-62.5 mg/5 mL",
          NDC: "43598020451",
        },
      ],
    },
    {
      Name: "Amoxicillin/Clarithromycin/Lansoprazole (oral - kit)",
      Strength: [
        {
          Strength: "500 mg-500 mg-30 mg",
          NDC: "00781405442",
        },
      ],
    },
  ],
};

export const susccessfulNameAndStrengthLookUp = {
  status: 200,
  data: {
    DispenseUnitId: 26,
    LexiGenProductId: 1226,
    LexiDrugSynId: 17712,
    LexiSynonymTypeId: 59,
    DoseForm: "tablet",
    DispenseUnitName: "Tablet",
    DisplayName: "Amoxapine 50 mg tablet",
  },
};

export const successfulAdd = {
  status: 200,
  data: {
    Id: 59348597,
    Result: {
      ResultCode: "OK",
      ResultDescription: "",
    },
    pharmacyId: "14973",
  },
};

export const successfulSend = {
  status: 200,
  data: [{ Id: 59332931, Result: { ResultCode: "OK", ResultDescription: "" } }],
};

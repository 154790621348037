import React, { useEffect, useState, Fragment } from "react";
import Modal from "react-bootstrap/Modal";

import { states, stateAbbrevs } from "../DataFiles/states";
import xIcon from "../Assets/Icon/x@2x.png";
import { routerMap } from "../utils/router";

import "../MyAccount/MyAccount.css";

const DocCard = (props) => {
  const { doc, companies, token } = props;
  const [updatedDoc, setUpdatedDoc] = useState(doc);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    firstName: doc.firstName,
    lastName: doc.lastName,
    email: doc.email,
    phone: doc.phone,
    admin: doc.admin,
    stateLicenses: doc.stateLicenses,
    acceptableVisitTypes: doc.acceptableVisitTypes,
    companies: doc.companies,
    youthFilter: doc.youthFilter,
    surge: doc.surge,
  });
  const [changePasswordInput, setChangePasswordInput] = useState(false);

  const { updateDoc } = routerMap;

  const handleUpdateDoc = async () => {
    await updateDoc(
      token,
      formData,
      doc._id,
      setUpdatedDoc,
      setChangePasswordInput,
      setShow,
      setMessage
    );
  };

  const handleDocClick = () => {
    setShow(true);
  };

  const handleChange = (e) => {
    const { value, name, type } = e.target;
    if (type === "checkbox") {
      if (name === "stateLicenses") {
        if (value === "None") {
          setFormData({
            ...formData,
            stateLicenses: ["None"],
          });
        } else {
          const removeNone = formData.stateLicenses.filter(
            (type) => type !== "None"
          );
          const ogLicenses = removeNone;
          const formDataIndex = ogLicenses && ogLicenses.indexOf(value);
          let stateLicenses;
          if (ogLicenses && ogLicenses.includes(value)) {
            ogLicenses.splice(formDataIndex, 1);
            stateLicenses = ogLicenses;
          } else {
            stateLicenses = ogLicenses ? [...ogLicenses, value] : [value];
          }
          setFormData({
            ...formData,
            stateLicenses,
          });
        }
      } else if (name === "acceptableVisitTypes") {
        if (value === "None") {
          setFormData({
            ...formData,
            acceptableVisitTypes: ["None"],
          });
        } else {
          const removeNone = formData.acceptableVisitTypes.filter(
            (type) => type !== "None"
          );
          const ogVisitTypes = removeNone;
          const formDataIndex = ogVisitTypes && ogVisitTypes.indexOf(value);
          let acceptableVisitTypes;
          if (ogVisitTypes && ogVisitTypes.includes(value)) {
            ogVisitTypes.splice(formDataIndex, 1);
            acceptableVisitTypes = ogVisitTypes;
          } else {
            acceptableVisitTypes = ogVisitTypes
              ? [...ogVisitTypes, value]
              : [value];
          }
          setFormData({
            ...formData,
            acceptableVisitTypes,
          });
        }
      } else if (name === "companies") {
        const ogCompanies = formData.companies;
        const formDataIndex = ogCompanies && ogCompanies.indexOf(value);
        let docCompanies;
        if (ogCompanies && ogCompanies.includes(value)) {
          ogCompanies.splice(formDataIndex, 1);
          docCompanies = ogCompanies;
        } else {
          docCompanies = ogCompanies ? [...ogCompanies, value] : [value];
        }
        setFormData({
          ...formData,
          companies: docCompanies,
        });
      }
    } else {
      if (!value) {
        const { [name]: slug, ...rest } = formData;
        setFormData(rest);
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }
  };

  const prettyTime = () => {
    const dated = new Date(updatedDoc.lastActiveTimestamp);
    if (dated && dated.getDate()) {
      return `${dated.getMonth() + 1}/${dated.getDate()}`;
    } else {
      return "Never";
    }
  };

  const handleHide = () => {
    setChangePasswordInput(false);
    setShow(false);
  };

  const showChangePasswordInput = (e) => {
    e.preventDefault();
    setChangePasswordInput(true);
  };

  const hideChangePasswordInput = (e) => {
    e.preventDefault();
    setChangePasswordInput(false);
  };

  const statesDisplay = formData.stateLicenses
    .map((stateAbbrev) => states[stateAbbrevs.indexOf(stateAbbrev)])
    .sort()
    .map((stateName, idx) => {
      return <span key={`${stateName}${idx}`}>| {stateName} |</span>;
    });

  const checkedStates = states.map((state) => {
    const isChecked = formData.stateLicenses.includes(
      stateAbbrevs[states.indexOf(state)]
    );
    return (
      <div className="checkGroup" key={state}>
        <input
          type="checkbox"
          value={stateAbbrevs[states.indexOf(state)]}
          name="stateLicenses"
          onChange={handleChange}
          checked={isChecked}
          className="checkBox"
        />
        <span>{state}</span>
      </div>
    );
  });

  const visitTypes = [
    ...new Set(
      companies
        .map((company) => company.visitTypes.map((visitType) => visitType.type))
        .flat()
    ),
  ].sort();

  const checkedVisitTypes = visitTypes.map((visitType) => {
    const isChecked = formData.acceptableVisitTypes.includes(visitType);
    return (
      <div className="checkGroup" key={visitType}>
        <input
          type="checkbox"
          value={visitType}
          name="acceptableVisitTypes"
          onChange={handleChange}
          checked={isChecked}
          className="checkBox"
        />
        <span>{visitType}</span>
      </div>
    );
  });

  const checkedCompanies = companies
    .map((company) => company.name)
    .sort()
    .map((companyName) => {
      const isChecked = formData.companies.includes(companyName);
      return (
        <div className="checkGroup" key={companyName}>
          <input
            type="checkbox"
            value={companyName}
            name="companies"
            onChange={handleChange}
            checked={isChecked}
            className="checkBox"
          />
          <span>{companyName}</span>
        </div>
      );
    });

  useEffect(() => {
    setFormData((formData) => {
      return {
        ...formData,
        firstName: updatedDoc.firstName,
        lastName: updatedDoc.lastName,
        email: updatedDoc.email,
        phone: updatedDoc.phone,
        admin: updatedDoc.admin,
        stateLicenses: updatedDoc.stateLicenses,
        acceptableVisitTypes: updatedDoc.acceptableVisitTypes,
        companies: updatedDoc.companies,
        youthFilter: updatedDoc.youthFilter,
        surge: updatedDoc.surge,
      };
    });
  }, [updatedDoc]);

  const handleRadioChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: JSON.parse(value),
    });
  };

  return (
    <Fragment key={doc._id}>
      <div
        key={doc._id}
        id={doc._id}
        onClick={handleDocClick}
        className="listCard"
      >
        <div>
          {doc.firstName} {doc.lastName}
        </div>
        <div>{doc.resolvedVisits.length}</div>
        <div>{prettyTime()}</div>
      </div>

      <Modal show={show} onHide={handleHide} dialogClassName="modalDims">
        <div className="modalHeader">
          <div className="modalTitle">
            <span>Update Details</span>
            <button onClick={handleHide} className="modalCloseButton">
              <img src={xIcon} className="xIcon" alt="close" />
            </button>
          </div>
        </div>
        <span className="userFormError">{message}</span>
        <div className="formStyle">
          <div className="formRow">
            <div>
              <div className="formField">First Name</div>
              <input
                name="firstName"
                placeholder="First Name"
                defaultValue={doc.firstName}
                onChange={handleChange}
                className="formInput"
              />
            </div>
            <div>
              <div className="formField">Last Name</div>
              <input
                name="lastName"
                placeholder="Last Name"
                defaultValue={doc.lastName}
                onChange={handleChange}
                className="formInput"
              />
            </div>
          </div>
          <div className="formRow">
            <div>
              <div className="formField">Email</div>
              <input
                name="email"
                placeholder="Email"
                defaultValue={doc.email}
                onChange={handleChange}
                className="formInput"
              />
            </div>
            <div>
              <div className="formField">Phone</div>
              <input
                name="phone"
                placeholder="Phone"
                defaultValue={doc.phone}
                onChange={handleChange}
                className="formInput"
              />
            </div>
          </div>
          <div className="formRow">
            <div>
              <div className="formField">Dosespot ID</div>
              <input
                name="dosespotID"
                placeholder="Enter doctor's Dosespot ID"
                defaultValue={doc.dosespotID}
                onChange={handleChange}
                className="formInput"
              />
            </div>
            <div>
              <div className="formField">NPI #</div>
              <input
                name="npi"
                onChange={handleChange}
                placeholder="Enter doctor's NPI #"
                defaultValue={doc.npi}
                className="formInput"
              />
            </div>
          </div>
          <div className="formRow">
            <div>
              <div className="formField">DEA #</div>
              <input
                name="dea"
                placeholder="Enter doctor's DEA #"
                defaultValue={doc.dea}
                onChange={handleChange}
                className="formInput"
              />
            </div>
          </div>
          <div className="formRowLeft">
            <div>
              <div className="formField">Password</div>
              <input
                name="password"
                onChange={handleChange}
                placeholder="*************"
                className="formInput"
                disabled={!changePasswordInput}
              />
            </div>
            {changePasswordInput ? (
              <button
                onClick={hideChangePasswordInput}
                className="passwordButton"
              >
                Cancel
              </button>
            ) : (
              <button
                onClick={showChangePasswordInput}
                className="passwordButton"
              >
                Change Password
              </button>
            )}
          </div>
          <div className="formRowLeft">
            <div>
              <div className="formField">Companies</div>
              <div className="scrolling checkedStates">{checkedCompanies}</div>
            </div>
          </div>
          <div className="formRowLeft">
            <div>
              <div className="formField">Visit Types</div>
              <div className="scrolling checkedStates">
                <div className="checkGroup" key="None">
                  <input
                    type="checkbox"
                    value="None"
                    name="acceptableVisitTypes"
                    onChange={handleChange}
                    className="checkBox"
                    checked={formData.acceptableVisitTypes.includes("None")}
                  />
                  <span>None</span>
                </div>
                {checkedVisitTypes}
              </div>
            </div>
          </div>
          <div className="formRowLeft">
            <div>
              <div className="formField">State/Province Licenses</div>
              <p>Selected: {statesDisplay}</p>
              <div className="scrolling checkedStates">
                <div className="checkGroup" key="None">
                  <input
                    type="checkbox"
                    value="None"
                    name="stateLicenses"
                    onChange={handleChange}
                    className="checkBox"
                    checked={formData.stateLicenses.includes("None")}
                  />
                  <span>None</span>
                </div>
                {checkedStates}
              </div>
            </div>
          </div>
          <div className="formRowLeft">
            <div className="formGroup">
              <div className="formField">Admin?</div>
              <div className="radioGroup">
                <label>
                  <input
                    type="radio"
                    name="admin"
                    value="true"
                    checked={formData.admin}
                    onChange={handleRadioChange}
                  />
                  True
                </label>
                <label>
                  <input
                    type="radio"
                    name="admin"
                    value="false"
                    checked={!formData.admin}
                    onChange={handleRadioChange}
                  />
                  False
                </label>
              </div>
            </div>
            <div className="formGroup">
              <div className="formField">Only show patients over 18?</div>
              <div className="radioGroup">
                <label>
                  <input
                    type="radio"
                    name="youthFilter"
                    value="true"
                    checked={formData.youthFilter}
                    onChange={handleRadioChange}
                  />
                  True
                </label>
                <label>
                  <input
                    type="radio"
                    name="youthFilter"
                    value="false"
                    checked={!formData.youthFilter}
                    onChange={handleRadioChange}
                  />
                  False
                </label>
              </div>
            </div>
          </div>
          <div className="formRowLeft">
            <div className="formGroup">
              <div className="formField">Surge?</div>
              <div className="radioGroup">
                <label>
                  <input
                    type="radio"
                    name="surge"
                    value="true"
                    checked={formData.surge}
                    onChange={handleRadioChange}
                  />
                  True
                </label>
                <label>
                  <input
                    type="radio"
                    name="surge"
                    value="false"
                    checked={!formData.surge}
                    onChange={handleRadioChange}
                  />
                  False
                </label>
              </div>
            </div>
          </div>
          <div className="formRowRight">
            <button onClick={handleHide} className="formButton">
              Cancel
            </button>
            <button className="formButton" onClick={handleUpdateDoc}>
              Update doctor
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default DocCard;

const soapNoteDict = {
  ubacareChronicMedSync: `Subjective:
Chief Complaint: Routine medication reconciliation and side effect check.
History of Present Illness:
Patient reports no new symptoms or concerns since the last visit.
Denies experiencing any side effects from current medications.
Adheres to medication regimen as prescribed.
Current Medications: {medList}
Allergies: {patientAllergies}
Objective:
Patient Report:
No new symptoms or concerns.
No side effects noted from current medications.
General feeling of wellness.
Assessment:
Medication adherence and tolerance is good.
No reported or observed side effects from current medications.
No changes in medical condition requiring adjustment of current medications.
Plan:
Continue current medications as prescribed
Reiterate the importance of medication adherence.
Provide education on recognizing potential side effects and when to seek medical advice.
Signed:
{docName}
NPI: {docNpi}`,
  patientManagement: `The patient presents to virtual interactive telemedicine evaluation conducted via real time synchronous communication.
I reviewed the medical history and test results.
EXAM: I have performed my clinical evaluation through review of the patient's current symptoms, past medical history, co-morbidities, allergies and all other chart information and information communicated by the patient.
Patient meets qualifications for {program_type}.
See relevant chart data. Patient to reach out if any questions.
Authorization for {program_type} provided. F/u as scheduled.
Patient has been educated on the management program.
FOLLOW UP: The patient has been provided information on how to contact me directly to follow up if any medical concerns arise at any time.
The patient is recommended to follow up with myself or the support team if additional concerns arise or the management plan needs to be adjusted.
I am and will remain immediately available through chat, email, phone, and to my clinical and administrative support staff to promptly and thoroughly address any medical or administrative issues that arise.
Patient has been educated that there are limitations to telemedicine care,
and in-person care may be necessary in certain situations (ER if severe symptoms develop or through an in person physician if signs/symptoms develop that warrant additional work up/evaluation/alternative treatments).
To the extent that I have been made aware by the company with which I contract to provide medical services,
the patient has signed relevant attestations prior to being assigned to me as a clinician that state that the patient is aware of any limitations in a virtual health encounter,
and understands that urgent or emergent care must be pursued in person).
The patient is over 18 and able to consent for their own care.
Telemedicine is appropriate for this patient encounter because it can meet the same standard of care for this patient's requested consultation and can improve access to care which remains limited in many parts of the United States.`,
  apneaVideo: `The patient presents to virtual interactive telemedicine evaluation conducted via real time synchronous communication.
I reviewed the medical history and test results.
EXAM: I have performed my clinical evaluation through review of the patient's current symptoms, sleep study results, past medical history, co-morbidities, allergies and all other chart information.
Patient meets qualifications for therapy. No contraindications to therapy. Patient prefers mandibular advance device as first line of therapy. Patient to reach out if any questions. Rx provided. F/u as scheduled.
Patient has been educated on the treatment plan including diagnosis, instructions for use, risks/benefits/side effects/alternatives to therapy.
No contraindications, red flags identified. If any side effects occur they should discontinue use and contact me for further instruction, except in the case of severe side effects in which case they should seek urgent care in an emergency room.
FOLLOW UP: The patient has been provided information on how to contact me directly to follow up if any medical concerns or reactions to treatment arise at any time.
The patient is recommended to follow up with myself or the support team if additional concerns arise or the treatment plan needs to be adjusted.
I am and will remain immediately available through chat, email, phone, and to my clinical and administrative support staff to promptly and thoroughly address any medical or administrative issues that arise.
Patient has been educated that there are limitations to telemedicine care, and in-person care may be necessary in certain situations (ER if severe side effects develop or through an in person physician if signs/symptoms develop that warrant additional work up/evaluation/alternative treatments).
To the extent that I have been made aware by the company with which I contract to provide medical services, the patient has signed relevant attestations prior to being assigned to me as a clinician that state that the patient is aware of any limitations in a virtual health encounter,
and understands that urgent or emergent care must be pursued in person). The patient is over 18 and able to consent for their own care.
Telemedicine is appropriate for this patient encounter because it can meet the same standard of care for this patient's chief complaint and can improve access to care which remains limited in many parts of the United States.`,
};

const defaultNote = `Patient has been educated on the treatment plan including instructions for use, risks/benefits/side effects.
No contraindications, red flags, or dangerous polypharmacy interactions identified.
If any side effects occur they should discontinue use and contact me for further instruction, except in the case of severe side effects in which case they should seek urgent care in an emergency room.
FOLLOW UP: The patient has been provided information on how to contact our medical team to follow up if any medical concerns or reactions to treatment arise at any time.
The patient is recommended to follow up with myself or the support team if additional concerns arise or the treatment plan needs to be adjusted.
Patient has been educated that there are limitations to telemedicine care, and in-person care may be necessary in certain situations (ER if severe side effects develop or through an in-person physician if signs/symptoms develop that warrant additional work up/evaluation/alternative treatments).
To the extent that I have been made aware by the company with which I contract to provide medical services,
the patient has signed relevant attestations prior to being assigned to me as a clinician that state that the patient is aware of any limitations in a virtual health encounter and understands that urgent or emergent care must be pursued in person.
I have confirmed the patient's identity via review of a legally recognized form of ID or through a second party identity verification service.
Please see doctor/patient messaging thread for any relevant dialogue which, in addition to providing supplemental medical information as needed, secures and meets the legally-defined doctor-patient relationship.
Telemedicine is appropriate for this patient encounter because it can meet the same standard of care for this patient's chief complaint and can improve access to care which remains limited in many parts of the United States.`;

export const injectedNote = (visitType, doc, patient) => {
  const { medicationsPrescribed, allergies, programTypes } = patient;
  if (visitType === "patientManagement") {
    return soapNoteDict[visitType].replaceAll("{program_type}", programTypes);
  }
  const medList = medicationsPrescribed
    .map((med) => {
      return `${med.name}`;
    })
    .join(";\n");
  const switchNote = soapNoteDict[visitType] || defaultNote;
  const newNote = switchNote
    .replace("{docName}", `${doc.firstName} ${doc.lastName}`)
    .replace("{docLastName}", doc.lastName)
    .replace("{docNpi}", doc.npi)
    .replace("{patientAllergies}", allergies)
    .replace("{medList}", medList);
  return newNote;
};

import React, { useEffect, useState } from "react";
import { routerMap } from "../utils/router";
import useVisitTypes from "../hooks/useVisitTypes";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import PropTypes from "prop-types";

import "./Favorites.css";
import FavoritesTableTab from "./tabs/FavoritesTableTab";
import PendingNewTableTab from "./tabs/PendingNewTableTab";
import PendingUpdateTableTab from "./tabs/PendingUpdateTableTab";
// import PendingErrorTableTab from "./tabs/PendingErrorTableTab";

const Favorites = (props) => {
  const { admin, user, companies } = props;
  const [favorites, setFavorites] = useState([]);
  const [pendingFavorites, setPendingFavorites] = useState([]);
  const [adminIsLoading, setAdminIsLoading] = useState(false);
  const token = localStorage.getItem("jwt");
  const { visitTypes } = useVisitTypes(user, admin);

  const { getFavorites, getPendingFavorites, deletePendingFavorite } =
    routerMap;

  const handleFetch = async () => {
    setAdminIsLoading(true);
    await getFavorites(token, setFavorites);
    await getPendingFavorites(token, setPendingFavorites);
    setAdminIsLoading(false);
  };

  const handleFormReject = async (formData, setErrorMessage) => {
    const token = localStorage.getItem("jwt");
    const res = await deletePendingFavorite(token, setErrorMessage, formData);
    if (res) {
      return res;
    }
    return false;
  };

  useEffect(() => {
    void handleFetch();
  }, [admin]);

  const simpleFavs = favorites.map((fav) => {
    return {
      baskName: fav.baskName,
      clientName: fav.clientName,
    };
  });
  // const switchCompare = (company) => {
  //   switch (true) {
  //     // case /^bask/.test(company):
  //     //   return "baskName";
  //     default:
  //       return "clientName";
  //   }
  // };
  const pendingNewList = pendingFavorites.filter((pendingFav) => {
    // const field = switchCompare(pendingFav.requestingCompany);
    return (
      !simpleFavs.some((fav) => fav.clientName === pendingFav.clientName) &&
      // !simpleFavs.some((fav) => fav[field] === pendingFav[field]) &&
      !pendingFav.webhookError
    );
  });
  const pendingUpdateList = pendingFavorites.filter((pendingFav) => {
    // const field = switchCompare(pendingFav.requestingCompany);
    return (
      simpleFavs.find((fav) => fav.clientName === pendingFav.clientName) &&
      // simpleFavs.find((fav) => fav[field] === pendingFav[field]) &&
      !pendingFav.webhookError
    );
  });

  // const pendingErrorList = pendingFavorites.filter(
  //   (favorite) => favorite.webhookError
  // );

  const newBadge = pendingNewList.length;
  const updateBadge = pendingUpdateList.length;
  // const errorBadge = pendingErrorList.length;

  return (
    <div className="favorites-page">
      <Tabs defaultActiveKey="favorites" id="favorites-tab" justify>
        <Tab eventKey="favorites" title="Favorites">
          <FavoritesTableTab
            user={user}
            favorites={favorites}
            visitTypes={visitTypes}
            companies={companies}
            onSuccess={handleFetch}
            adminIsLoading={adminIsLoading}
          />
        </Tab>
        <Tab
          eventKey="pendingNew"
          title={
            <span>
              Pending New{" "}
              {!!newBadge && <span className="badge">{newBadge}</span>}
            </span>
          }
        >
          <PendingNewTableTab
            user={user}
            pendingNewList={pendingNewList}
            favorites={favorites}
            visitTypes={visitTypes}
            companies={companies}
            onSuccess={handleFetch}
            handleReject={handleFormReject}
            adminIsLoading={adminIsLoading}
          />
        </Tab>
        <Tab
          eventKey="pendingUpdate"
          title={
            <span>
              Pending Update{" "}
              {!!updateBadge && <span className="badge">{updateBadge}</span>}
            </span>
          }
        >
          <PendingUpdateTableTab
            user={user}
            pendingUpdateList={pendingUpdateList}
            favorites={favorites}
            visitTypes={visitTypes}
            companies={companies}
            onSuccess={handleFetch}
            handleReject={handleFormReject}
            adminIsLoading={adminIsLoading}
          />
        </Tab>
        {/* <Tab
          eventKey="pendingError"
          title={
            <span>
              Webhook Error{" "}
              {!!errorBadge && <span className="badge">{errorBadge}</span>}
            </span>
          }
        >
          <PendingErrorTableTab
            user={user}
            errorList={pendingErrorList}
            onSuccess={handleFetch}
            adminIsLoading={adminIsLoading}
          />
        </Tab> */}
      </Tabs>
    </div>
  );
};

Favorites.propTypes = {
  admin: PropTypes.bool,
  user: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
};

export default Favorites;
